import React, {useState, useEffect} from "react"
import {Button, Divider, Drawer, Select, Space, Spin} from "antd";
import {useTranslation} from "react-i18next";
import {UnitOfMeasure} from "../types/UnitOfMeasure";
import {uomService} from "../Services/uomService";
import {PlusOutlined} from "@ant-design/icons";
import UnitOfMeasureForm from "../Pages/UnitOfMeasure/unitOfMeasureForm";

interface UnitOfMeasureSelectProps {
    defaultSelected? : string
    afterSelect? : (v:string)=>void;
}

const UnitOfMeasureSelect=({defaultSelected, afterSelect}: UnitOfMeasureSelectProps)=>{


    const [units, setUnits ]=useState<UnitOfMeasure[]|undefined>()
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedUnit, setSelectedUnit] = useState(defaultSelected);



    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };
    
    

    const {t}=useTranslation();

    useEffect(() => {
        const fetchUnits = async () => {
            setLoading(true);
            try {
                const response = await uomService.getAll();
                setUnits(response);
            } catch (error) {
                console.error('Ошибка при получении производителей:', error);
            } finally {
                setLoading(false);
            }
        };
        setSelectedUnit(defaultSelected)
        fetchUnits();
    }, [defaultSelected]);

    const handleSelect=(value: string)=>{
        setSelectedUnit(value)
        if(afterSelect){
            afterSelect(value)
        }
    }
    
    const handleCreated = (v?: any) =>{
        setOpen(false);
        if(v){
            units?.push(v);
            setSelectedUnit(v.id);
            if(afterSelect){
                afterSelect(v.id)
            }
            console.log("handleCreated: ", v);
        }
        
        
    }


    
    return <Spin spinning={loading}>
        <Select
            allowClear={true}
            showSearch={true}
            style={{width: "100%"}} placeholder={t("Select_a_supplier")}
            value={selectedUnit}
            options={units?.map((unt)=>({label: unt.name, value: unt.id, code: unt.code}))}


            filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())||
                (option?.code ?? '').toLowerCase().includes(input.toLowerCase())
            }



            optionRender={(option) => (<div>
                {/*   <span style={{fontSize: "10px", color: "#999"}}>{option.data.code}</span><br/>*/}
                <span>{option.data.label}</span>
            </div>)}


            
            dropdownRender={(menu) => (
                <>
                    {menu}
                    <Divider style={{ margin: '8px 0' }} />
                    <div style={{ padding: '5px', width: "100%", cursor: "pointer" }} 
                           
                           onClick={showDrawer}> <PlusOutlined /> {t("Add_new_uom")}
                        {/*  <Button type="text" icon={<PlusOutlined />} onClick={showDrawer}>
                            Add item
                        </Button>*/}
                    </div>
                </>
            )}
            
             
            
            


            onChange={handleSelect}

        />

        <Drawer
            destroyOnClose ={true}
            title={<p><b>{t('Add_new_uom')}</b></p>} onClose={onClose} open={open}>
            <UnitOfMeasureForm 
                closeEmbeded={(v)=> {
                    handleCreated(v)
                }}
                embeddedCreate={true} />
        </Drawer>


    </Spin>



}
export default UnitOfMeasureSelect