// pages/ProductEdit.tsx
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import WarehouseForm  from './WarehouseForm';
import { Supplier } from '../../types/Supplier';
import axios from 'axios';
import {message, Spin, Tabs, TabsProps} from 'antd';
import {productService} from "../../Services/productService";
import {
    RightOutlined,
    EditOutlined,
    SwapOutlined,
    TableOutlined,
    CodeSandboxOutlined,
    FileOutlined
} from '@ant-design/icons';
import {supplierService} from "../../Services/supplierService";
//import SuppliersProductList from "./Components/SuppliersProductsList";
import DocumentMovements from "../Movements/DocumentMovements";
import {Warehouse} from "../../types/Warehouse";
import {warehouseService} from "../../Services/WarehouseService";
import ProductsStock from "../Products/Components/ProductStock";
import ProductStockTable from "./ProductStockTable";
import {useTranslation} from "react-i18next";

const SupplierDetail: React.FC = () => {
    const { id } = useParams<{ id: string }>(); // Получаем ID товара из URL, если он есть
    const history = useHistory();
    const [warehouse, setWarehouse] = useState<Warehouse | null>(null);
    const [loading, setLoading] = useState(false);

    const {t}=useTranslation();

    useEffect(() => {
        if (id) {
            setLoading(true);

            const data =  warehouseService.getById(id)
                .then((response) => {
                    setWarehouse(response);
                })
                .catch(() => {
                    message.error('Failed to load product');
                })
                .finally(() => {
                    setLoading(false);
                });

        }
    }, [id]);


    const onChange = (key: string) => {
        //console.log(key);
    };


    const operations = <>Extra Action</>;
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: t('Edit_warehouse_data'),
            icon: <EditOutlined />,
            children: <WarehouseForm id={id} />,
        },
        /*
        {
            key: '2',
            label: 'Products supplied',
            icon: <CodeSandboxOutlined />,
            children: <SuppliersProductList supplierId={id} />,
        },
        
         */
        {
            key: '3',
            label: t('Stock_level'),
            icon: <FileOutlined />,
            children:  <ProductStockTable warehouseId={id} />
            //<ProductForm  initialValues={product || undefined} productGroups={productGroups} onSave={handleSave}/>,
        },
    ];



    if (loading) {
        return <Spin />;
    }

    return (
        <div>
            <h1>
                <span
                    style={{color: "#999", cursor: "pointer", borderBottom: "1px dashed #999"}} onClick={()=> history.push("/stocks/")}
                >
                    {t("Warehouses")}
                </span>
                <span style={{color: "#999"}}>
                    &nbsp;<RightOutlined size={16} style={{fontSize:"18px"}} /> &nbsp;
                </span>
                {id ? warehouse?.name : t('Create_warehouse')}
            </h1>
            {
                id?
                    <>
                        <Tabs /*tabBarExtraContent={operations}*/ defaultActiveKey="1" items={items} onChange={onChange} />
                    </>

                    : <WarehouseForm/>
            }



        </div>
    );
};

export default SupplierDetail;
