// src/services/productGroupService.ts
import axios from 'axios';
import { Event } from '../types/Event';

import {DATA_URL} from './auth-service'
import authHeader from "./auth-header";

const API_URL = DATA_URL+'events';

export const eventService = {
    // Получение всех событий
    async getAll(): Promise<Event[]> {
        const response = await axios.get<Event[]>(API_URL, {headers: authHeader()});
        return response.data;
    },

    // Получение события по ID
    async getById(id: string): Promise<Event> {
        const response = await axios.get<Event>(`${API_URL}/${id}`, {headers: authHeader()});
        return response.data;
    },

    // Создание нового события
    async create(productGroup: Event): Promise<Event> {
        const response = await axios.post<Event>(API_URL, productGroup, {headers: authHeader()});
        return response.data;
    },

    // Обновление существующего события не предусмотрено API
    //async update(id: string, productGroup: ProductGroup): Promise<void> {
    //    await axios.put(`${API_URL}/${id}`, productGroup, {headers: authHeader()});
    //},

    // Удаление события
    async delete(id?: string): Promise<void> {
        await axios.delete(`${API_URL}/${id}`, {headers: authHeader()});
    }
};
