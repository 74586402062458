import React, { useEffect, useState } from 'react';
import {Form, Input, Button, notification, Affix, Space, Divider, ConfigProvider} from 'antd';
import { supplierService } from '../../../Services/supplierService'; // Импортируйте ваш сервис для работы с API
import { useParams, useHistory } from 'react-router-dom';
import {RightOutlined} from "@ant-design/icons";
import {Product} from "../../../types/Product";
import {ProductGroup} from "../../../types/ProductGroup";
import {useTranslation} from "react-i18next";
import AddTodo from "../../../CommonComponents/ToDo/AddTodo";
import {TodoObjectType} from "../../../types/ToDo"; // Для получения параметров из URL и навигации


interface SupplierFormProps {
    id?: string;
}

const SupplierForm: React.FC<SupplierFormProps> = ({id}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
   // const { id } = useParams<{ id?: string }>(); // Получение ID поставщика из URL, если он есть
    const history = useHistory(); // Для навигации
    
    const {t}=useTranslation();

    // Загрузка данных поставщика для редактирования, если есть ID
    useEffect(() => {
        if (id) {
            setLoading(true);
            supplierService.getById(id)
                .then((supplier) => {
                    form.setFieldsValue(supplier);
                })
                .catch(() => {
                    notification.error({ message: 'Ошибка загрузки данных поставщика' });
                })
                .finally(() => setLoading(false));
        }
    }, [id, form]);

    // Обработка отправки формы
    const handleSubmit = async (values: any) => {
        
        console.log("values to PUT", values)
        setLoading(true);
        try {
            if (id) {
                // Обновление существующего поставщика
                await supplierService.update(id, values);
                notification.success({ message: 'The supplier has been successfully updated!' });
            } else {
                // Создание нового поставщика
                await supplierService.create(values);
                notification.success({ message: 'The supplier has been successfully created!' });
               
            }
            history.push('/settings/suppliers'); // Навигация обратно на список поставщиков
        } 
        //catch (error:any) {
       //     console.log(error)
       //     notification.error({ message: 'Ошибка сохранения поставщика', description: error.message });
        //} 
        finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <ConfigProvider
                theme={{
                    components: {
                        Form: {
                            itemMarginBottom: 10
                        },
                    },
                }}
            >
            <Form
                form={form}
                layout="horizontal"
                labelCol={{ flex: '180px' }}
                labelWrap={true}

                onFinish={handleSubmit}
                initialValues={{ name: '', supplierCode: '', address: '', phoneNumber: '', email: '', contactPerson: '' }}
            >
                <Divider orientation={"left"} orientationMargin="0">Main info</Divider>

                <Form.Item hidden={true} name="id">
                    <Input />
                </Form.Item>
                <Form.Item
                    label={t("Naziv")}
                    name="name"
                    rules={[{ required: true, message: 'Введите имя поставщика' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={t("Supplier_Code")}
                    name="supplierCode"
                    rules={[{ required: true, message: 'Введите код поставщика' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={t("Naslov")}
                    name="address"
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={t("Telefon")}
                    name="phoneNumber"
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={t("E_pošta")}
                    name="email"
                    rules={[{ type: 'email', message: 'Enter a valid e-mail address' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={t("Kontaktna_oseba")}
                    name="contactPerson"
                >
                    <Input />
                </Form.Item>

                {/* 
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        {id ? 'Update' : 'Create'}
                    </Button>
                </Form.Item>
                */}

                <Divider orientation={"left"} orientationMargin="0" style={{marginBottom: "60px"}}> </Divider>


                <Affix offsetBottom={0}>
                    <div className="affix-shadow-wrapper">
                        <Form.Item  style={{float: "right"}}>
                            <Space>
                                {id ?

                                    <AddTodo
                                        buttonText={"Add/check to-do"}
                                        objectId={id}
                                        objectType={TodoObjectType.Supplier}
                                        showList={true}
                                        afterAdd={(v)=>{
                                            console.log()
                                            //setRefreshTodoList(!refreshTodoList)
                                        }} />
                                    :
                                    ""
                                }

                                <Button type="primary" htmlType="submit" loading={loading}  className={"OrangeButton"}>
                                    {id ? t('Update_supplier') : t('Create_supplier')}
                                </Button>

                                <Button onClick={()=>history.push("/settings/suppliers")}>{t("Cancel")}</Button>
                            </Space>
                        </Form.Item>

                    </div>
                </Affix>
                
                
            </Form>
            </ConfigProvider>
        </div>
    );
};

export default SupplierForm;
