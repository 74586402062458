// src/services/productGroupService.ts
import axios from 'axios';
import { ProductGroup } from '../types/ProductGroup';

import {DATA_URL} from './auth-service'
import authHeader from "./auth-header";
import {ToDo, TodoObjectType} from "../types/ToDo";

const API_URL = DATA_URL+'todos';

export const todoService = {
    // Получение всех задач
    async getAll(): Promise<ToDo[]> {
        const response = await axios.get<ToDo[]>(API_URL, {headers: authHeader()});
        return response.data;
    },

    // Получение всех задач
    async getForObject(id?: string, objectType?: TodoObjectType): Promise<ToDo[]> {
        const response = await axios.get<ToDo[]>(API_URL+'/getForObject',
            {
                params: {
                    id,
                    objectType,
                },
                headers: authHeader()});
        return response.data;
    },


    // Получение задачи по ID
    async getById(id: string): Promise<ToDo> {
        const response = await axios.get<ToDo>(`${API_URL}/${id}`, {headers: authHeader()});
        return response.data;
    },

    // Создание новой задачи
    async create(productGroup: Event): Promise<ToDo> {
        const response = await axios.post<ToDo>(API_URL, productGroup, {headers: authHeader()});
        return response.data;
    },

    // Обновление существующей задачи - меняется только isFinished
    async update(id?: string, todo?: ToDo): Promise<void> {
        console.log("isFinished: ", todo )
        await axios.put(`${API_URL}/${id}`, todo, {headers: authHeader()});
    },

    // Удаление задачи
    async delete(id?: string): Promise<void> {
        await axios.delete(`${API_URL}/${id}`, {headers: authHeader()});
    }
};
