import {Checkbox, Col, List, Row, Spin} from "antd";
import React, {useEffect, useState} from "react";
import {todoService} from "../../Services/todoService";
import {ToDo, TodoObjectType} from "../../types/ToDo";
import DisplayTodo from "./DisplayTodo";

import * as AuthService from "../../Services/auth-service";
import {useHistory} from "react-router-dom";



interface TodosListtProps {
    objectId? : string;
    objectType? : TodoObjectType
    //afterAdd? : (v:string)=>void;
    refresh? : boolean;
}

const TodosList=({objectType, objectId, refresh}: TodosListtProps)=> {

    const [loading, setLoading] = useState<boolean>(true);

    const history=useHistory();
    const [todos, setTodos ]=useState<ToDo[]|undefined>()

    useEffect(() => {
        const fetchMovements = async () => {
            setLoading(true);
            try {

                const response = objectId ?
                    await todoService.getForObject(objectId, objectType)
                    :
                    await todoService.getAll();
                setTodos(response);
                console.log(response)
            } catch (error: any) {
                console.error('Ошибка при получении задач:', error);
                if(error.response?.status === 401){
                    AuthService.logout();
                    history.push("login")
                    window.location.reload();
                }

            } finally {
                setLoading(false);
            }
        };
        fetchMovements();
    }, [refresh]);
    
    
    
    return<Spin spinning={loading}>
    <List
        bordered={false}
        dataSource={todos?.map((todo:ToDo)=>({
            key: todo.id,
            label : todo.title,
            isFinished: todo.isFinished,
            data: todo
        }))}
        renderItem={(item) => <DisplayTodo todo={item.data} hidelink={!!objectId} />
            
            /*<List.Item>
            <Row style={{width: "100%"}}>
                <Col span={22}>
                    {item.label}
                </Col>
                <Col span={2}>
                    <Checkbox checked={item.isFinished}/>
                </Col>
            </Row>
        </List.Item>
        
             */
    }
    />
    </Spin>
}
export default TodosList;