import React, {useEffect, useState} from 'react';
import {
    Affix,
    Button,
    Col,
    ConfigProvider,
    DatePicker,
    Divider, Dropdown,
    Form,
    Input,
    InputNumber, MenuProps,
    notification,
    Row,
    Select,
    Space
} from 'antd';
import axios from 'axios';
import {InventoryDocument, InventoryDocumentType} from "../../types/InventoryDocument";
import {RightOutlined} from "@ant-design/icons";
import {useParams, useHistory} from "react-router-dom";
import IncomingDocPosition from "./IncomingDocPosition";
import WarehouseSelect from "../../CommonComponents/WarehouseSelect";
import {InventoryMovementService} from "../../Services/inventoryMovementService";
import {inventoryDocumentService} from "../../Services/inventoryDocumentService";
import SupplierSelect from "../../CommonComponents/SupplierSelect";
import {supplierService} from "../../Services/supplierService";
import dayjs from 'dayjs'
import {Product} from "../../types/Product";
import {Warehouse} from "../../types/Warehouse";
import {Project} from "../../types/Project";
import {InventoryMovementType} from "../../types/InventoryMovement";
import {useTranslation} from "react-i18next";
import AddTodo from "../../CommonComponents/ToDo/AddTodo";
import {TodoObjectType} from "../../types/ToDo";



const { Option } = Select;



interface position {
    rowkey? : string,
    movement?: any
}



const CreateDocumentPage: React.FC = () => {
    const [form] = Form.useForm();

    
    const history = useHistory();

    const { id } = useParams<{ id?: string }>(); // Получение ID поставщика из URL, если он есть
    const { docType } = useParams<{ docType?: string }>(); // Получение ID поставщика из URL, если он есть
    console.log("docType: ", docType)


    const {t}=useTranslation();


    const [isLoading, setIsLoading] = useState(false)
    const [positions, setPositions] = useState<position[]>([])
    const [rkey, setRkey] = useState(1)
    
    const [_docType, set_DocType] = useState(docType)


    const [selectedFromWarehouseId, setSelectedFromWarehouseId] = useState<string|undefined>()
    const [selectedToWarehouseId, setSelectedToWarehouseId] = useState<string|undefined>()

    const [selectedSupplierId, setSelectedSupplierId] = useState<string|undefined>()


    useEffect(() => {
        if (id) {
            setIsLoading(true);
            inventoryDocumentService.getById(id)
                .then((document) => {
                    console.log("document: ", document)
                    
                    const stt=[document].map(doc=>({
                        ...doc,
                        documentDate: doc.documentDate ? dayjs(doc.documentDate ) : undefined,
                        invoiceDate:  doc.invoiceDate ? dayjs(doc.invoiceDate ) : undefined,
                        orderDate:  doc.orderDate ? dayjs(doc.orderDate ) : undefined,
                        paymentDate:  doc.paymentDate ? dayjs(doc.paymentDate ) : undefined,
                        documentType: doc.documentType

                    }))[0];
                    
                    set_DocType(document.documentType.toString())
                    
                    var docch=document;
                    docch.documentDate=dayjs(document.documentDate).toISOString()
                    
                    form.setFieldsValue(stt);
                    setSelectedSupplierId(document.supplierId);
                    setSelectedFromWarehouseId(document.sourceWarehouseId );
                    setSelectedToWarehouseId(document.destinationWarehouseId)
                    if(document.items){
                        const ppsdf:position[]=document.items.map((item, idx)=>({
                            rowkey: idx.toString(),
                            movement: {
                                movementType: item.movementType,
                                productId: item.productId,
                                positionInDocument: item.positionInDocument ? item.positionInDocument : idx+1,
                                price: item.price,
                                quantity: item.quantity,
                                sourceWarehouseId: item.sourceWarehouseId ? item.sourceWarehouseId : document.sourceWarehouseId,
                                destinationWarehouseId: item.destinationWarehouseId ? item.destinationWarehouseId : document.destinationWarehouseId
                            }
                        }))
                        setPositions(ppsdf);
                        if(ppsdf.length>0){
                            setRkey(ppsdf.length+1)
                        }
                    }
                })
                .catch(() => {
                    notification.error({ message: 'Ошибка загрузки данных документа' });
                })
                .finally(() => setIsLoading(false));
        }
    }, [id, form]);
    
    
    
    
    const handleFromWarehouseSelect=(id: string)=>{
        setSelectedFromWarehouseId(id)
    }


    const handleWarehouseSelect=(id: string)=>{
        setSelectedToWarehouseId(id)
    }

    const handleSupplierSelect=(id: string)=>{
        setSelectedSupplierId(id)
    }

    
    const addItem = () => {
        setPositions([...positions, {
            rowkey: (rkey).toString(),
            
            movement: {
                positionInDocument: rkey,
                movementType: docType? parseInt(docType) : 0,
            } 
        }]);
        setRkey(rkey+1)
    };
    
    const deleteItem=(index: number)=>{
        console.log("positions, index: ", positions, index)
        
//        setPositions(positions.filter((_e:any, i) => _e.rowkey !== index.toString()));
        
        setPositions(prevPositions=>prevPositions
            .filter((_e:any, i) => _e.rowkey !== index.toString())
            .map((prevPos: position)=> //new arr
        ({ //new obj
            ...prevPos,
            rowkey: parseInt(prevPos.rowkey ? prevPos.rowkey : "0")>index ? 
                (parseInt(prevPos.rowkey ? prevPos.rowkey : "0")-1).toString() 
                : prevPos.rowkey,
            movement: {
                id:prevPos.movement.id,
                productId: prevPos.movement.productId,
                price: prevPos.movement.price,
                quantity:prevPos.movement.quantity,
                movementType:prevPos.movement.movementType,
                sourceWarehouseId:selectedFromWarehouseId,
                destinationWarehouseId:prevPos.movement.destinationWarehouseId,
                projectId:prevPos.movement.projectId,
                issueDate:prevPos.movement.issueDate,
                plannedReturnDate:prevPos.movement.plannedReturnDate,
                actualReturnDate:prevPos.movement.actualReturnDate,
                positionInDocument: prevPos.movement.positionInDocument >index? prevPos.movement.positionInDocument-1 : prevPos.movement.positionInDocument
            }
        })));
        
        

        
        setRkey(rkey-1)
        //setIsLoading(!isLoading)
    }
    
    const handlePositionChange=(rowkey?: string, mov?: any)=>{
        // @ts-ignore
        const index =  positions.indexOf(positions.find(p=>p.rowkey===rowkey), 0);
        
        console.log("positions: ", positions)
        console.log("rowkey: ", rowkey)
        console.log("index: ", index)
        console.log("mov: ", mov.mov)
        
        if(positions[index]){
            positions[index].movement={
                id:mov.id ,
                productId: mov.productId,
                price: mov.price,
                quantity:mov.quantity,
                movementType:mov.movementType,
                sourceWarehouseId:selectedFromWarehouseId,
                destinationWarehouseId:mov.destinationWarehouseId,
                projectId:mov.projectId,
                issueDate:mov.issueDate,
                plannedReturnDate:mov.plannedReturnDate,
                actualReturnDate:mov.actualReturnDate,
                positionInDocument:mov.positionInDocument
                
            };
            console.log("sdfsdfsfsdfsdfsff:",positions[index])
            setPositions(positions);
        }
    }
    
    
    
    const handleDocChanges = (values: any) => {
        
        console.log({
            //id: undefined,
            documentNumber: form.getFieldValue("documentNumber"),
            documentDate: form.getFieldValue("documentDate"),
            documentType: InventoryDocumentType.Prevzemnica,
            sourceWarehouseId: selectedFromWarehouseId,
            destinationWarehouseId: selectedToWarehouseId,
            supplierId: selectedSupplierId,
            orderNumber: form.getFieldValue("orderNumber"),
            orderDate: form.getFieldValue("orderDate"),
            invoiceNumber: form.getFieldValue("invoiceNumber"),
            invoiceDate: form.getFieldValue("invoiceDate"),
            paymentInvoiceNumber:  form.getFieldValue("paymentInvoiceNumber"),
            paymentDate: form.getFieldValue("paymentDate"),
            invoiceAmount: form.getFieldValue("invoiceAmount"),
            tax22Amount:form.getFieldValue("tax22Amount"),
            tax9_5Amount: form.getFieldValue("tax9_5Amount"),
            description: form.getFieldValue("description"),



        })
        

        
        
      
    }
    

    const handleSubmit = async (values: any) => {
        
        console.log("submit values: ", values)
        console.log("submit formvalues: ", form.getFieldValue("documentDate"))
        

        try {
            
            const theDoc = {
                id: id,
                documentNumber: form.getFieldValue("documentNumber"),
                documentDate: form.getFieldValue("documentDate") ? new Date(form.getFieldValue("documentDate")).toISOString() : new Date().toISOString(),
                documentType:  docType? parseInt(docType) : 0,//InventoryDocumentType.Prevzemnica,
                sourceWarehouseId: selectedFromWarehouseId,
                destinationWarehouseId: selectedToWarehouseId,
                supplierId: selectedSupplierId,
                orderNumber: form.getFieldValue("orderNumber"),
                orderDate: form.getFieldValue("orderDate")?.toISOString(),
                invoiceNumber: form.getFieldValue("invoiceNumber"),
                invoiceDate: form.getFieldValue("invoiceDate")?.toISOString(),
                paymentInvoiceNumber:  form.getFieldValue("paymentInvoiceNumber"),
                paymentDate: form.getFieldValue("paymentDate")?.toISOString(),
                invoiceAmount: form.getFieldValue("invoiceAmount"),
                tax22Amount:form.getFieldValue("tax22Amount"),
                tax9_5Amount: form.getFieldValue("tax9_5Amount"),
                description: form.getFieldValue("description"),
                items: positions.map((pos)=>({
                    id: pos.movement.id,
                    productId: pos.movement.productId,
                    price: pos.movement.price,
                    quantity: pos.movement.quantity,
                    movementType: docType? parseInt(docType) : 0,  //pos.movement.movementType, //InventoryMovementType.Purchase,
                    sourceWarehouseId:  selectedFromWarehouseId,
                    destinationWarehouseId: pos.movement.destinationWarehouseId?
                        pos.movement.destinationWarehouseId : selectedToWarehouseId,
                    isReturn: false,
                    movementDate: form.getFieldValue("documentDate") ? new Date(form.getFieldValue("documentDate")).toISOString() : new Date().toISOString(),
                    positionInDocument : pos.movement.positionInDocument,
                    decription: pos.movement.description
                }))
            }
            
            if(id){
                const response = await inventoryDocumentService.update(
                    id,
                   theDoc
                );
                notification.success({ message: 'Document updated successfully!' });
                history.push("/stockmovement/")
                //form.resetFields();
            }
            else{
                
                const response = await inventoryDocumentService.create(
                    theDoc
                );
                console.log("created docu: ", response)
                notification.success({ message: 'Document created successfully!' });
                history.push("/stockmovement/")
                //form.resetFields();
                
                
            }
            
            


        } catch (error) {
            console.error('Ошибка при получении перемещений:', error);
            notification.error({ message: 'Error rceating document' });

        } finally {

        }
    };

    
    ///delete
    const deleteItems: MenuProps['items'] = [
        { key: "0",  label: <span>{t("Delete")}</span> }];
    
    const handleDeleteOption: MenuProps['onClick'] = async (e) => {
        if(e.key==="0"){
            try {
                const response = await inventoryDocumentService.delete(id)
                notification.success({ message: 'Document deleted successfully!' });
                history.push("/stockmovement/")
            }
            catch (e) {
                notification.error({ message: 'Error deleting document' });
            }
        }
    }
    
    
    
    
    
    return (
        <div >

            <h1>
                <span style={{color: "#999", cursor: "pointer", borderBottom: "1px dashed #999"}} onClick={()=> history.push("/stockmovement")}>
                    {t("Stock_movements")}
                </span>
                <span style={{color: "#999"}}>
                    &nbsp;<RightOutlined size={16} style={{fontSize:"18px"}} /> &nbsp;
                </span>
                <span style={{color: "#000"}}>
                {id? t("Edit_document") : t("New_document")}:
                </span>
                { _docType==="0" || !_docType ? 
                    <span > {t("Purchase")}</span> 
                    :
                        _docType==="1" ? 
                            <span style={{color: "#e25e1e"}}> {t("Transfer")}</span>
                            :
                            _docType==="2" ? 
                                <span style={{color: "#000"}}> {t("Sale")}</span>
                                :
                                _docType==="3" ?
                                    <span style={{color: "#999"}}> {t("Write_off")}</span>
                                    :""

                }
            </h1>
            
            
            <ConfigProvider
                theme={{
                    components: {
                        Form: {
                            itemMarginBottom: 10
                        },
                    },
                }}
            >
            
            
            <Form
                form={form}
                layout="horizontal"
                labelCol={{ flex: '180px' }}
                labelWrap={true}
                onChange={handleDocChanges}
                //onFinish={handleSubmit}
                initialValues={{
                    documentType: InventoryDocumentType.Prevzemnica,
                }}
            >
                <Row gutter={18}>
                    <Col span={12}>
                        <Row gutter={12}>
                            <Col span={12}>
                                <Form.Item
                                    label={t("Document_Number")}
                                    name="documentNumber"
                                >
                                    <Input prefix={
                                        _docType==="0" || !_docType ?
                                            
                                        <span style={{ color: "#1449af" }}>PZ</span>
                                            :
                                            <span style={{ color: "#e25e1e" }}>MP</span>
                                            
                                    
                                    }/>
                                </Form.Item>

                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={t("Document_Date")}
                                    name="documentDate"
                                   // rules={[{ required: true, message: 'Выберите дату документа' }]}
                                >
                                    <DatePicker placeholder={""} format="DD.MM.YYYY" />
                                </Form.Item>

                            </Col>
                        </Row>


                        {_docType==="1" || docType==="2"  || docType==="3" ?
                            <Form.Item
                                label={t("Warehouse_from")}
                                name="sourceWarehouseId"
                            >
                                <WarehouseSelect afterSelect={(v: string) => handleFromWarehouseSelect(v)}  defaultSelected={selectedFromWarehouseId} />
                            </Form.Item>
                            :""
                            
                            
                        }
                        {_docType==="0" || docType==="1"  ?
                        <Form.Item
                            label={t("Warehouse_to")}
                            name="destinationWarehouseId"
                        >
                            <WarehouseSelect afterSelect={(v: string) => handleWarehouseSelect(v)}  defaultSelected={selectedToWarehouseId} />
                        </Form.Item> : ""}
                        {_docType==="0" || !_docType? 
                            <Form.Item
                                label={t("Supplier")}
                                name="supplierId"
                            >
                                <SupplierSelect afterSelect={(v: string)=>handleSupplierSelect(v)} defaultSelected={selectedSupplierId} />
                            </Form.Item>
                            :""
                        }
                        
 
                    </Col>
                    {_docType === "0" || !_docType ?
                        <Col span={12}>
                            <Row gutter={12}>
                                <Col span={12}>
                                    <Form.Item
                                        label={t("Order_Number")}
                                        name="orderNumber"
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={t("Order_Date")}
                                        name="orderDate"
                                    >
                                        <DatePicker  placeholder={""} format="DD.MM.YYYY"/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={12}>
                                <Col span={12}>
                                    <Form.Item
                                        label={t("Invoice_Number")}
                                        name="invoiceNumber"
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={t("Invoice_Date")}
                                        name="invoiceDate"
                                    >
                                        <DatePicker  placeholder={""} format="DD.MM.YYYY"/>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={12}>
                                <Col span={12}>
                                    <Form.Item
                                        label={t("Payment_Number")}
                                        name="paymentInvoiceNumber"
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={t("Payment_Date")}
                                        name="paymentDate"
                                    >
                                        <DatePicker  placeholder={""} format="DD.MM.YYYY"/>
                                    </Form.Item>
                                </Col>
                            </Row>

                        </Col>
                        :
                        <Col span={12}></Col>
                    }
                </Row>

                {_docType === "0" || !_docType ?
                <Row>
                    <Col span={6}>
                        <Form.Item
                            label={t("Invoice_Amount")}
                            name="invoiceAmount"
                           // rules={[{ required: true, message: 'Введите сумму счета' }]}
                        >
                            <InputNumber min={0} step={0.01} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={t("Tax_22_Amount")}
                            name="tax22Amount"
                        >
                            <InputNumber min={0} step={0.01} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={t("Tax_95_Amount")}
                            name="tax9_5Amount"
                        >
                            <InputNumber min={0} step={0.01} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={t("Description")}
                            name="description"
                        >
                            <Input.TextArea rows={1} />
                        </Form.Item>

                    </Col>
                </Row>
                    :""}

                <Divider />
 
            </Form>

                <Row>
                    <Col span={24} style={{height: "35vh", overflow: "scroll"  }}>
                        
                            {positions.map((pos:any, index)=>(
                                <>
                                    <IncomingDocPosition
                                        rowkey={pos.rowkey}
                                        movement={pos.movement}
                                        quantity={pos.quantity? pos.quantity : 9999}
                                        deletePos={(i:number)=>deleteItem(i)}
                                        rerender={isLoading}
                                        afterChange={(rk, m)=>handlePositionChange(rk,m)} />
                                    <Divider style={{borderColor: "#fff", margin: "5px 0px"}} />
                                </>
                            ))}
                            

                    </Col>
                </Row>
                
            </ConfigProvider>

            <Affix offsetBottom={0}>
                <div className="affix-shadow-wrapper">
                    <Button  type="primary" onClick={()=>{addItem(); console.log(positions)}}>
                        {t("Add_position")}
                    </Button>
                    {/* 
                    <Button onClick={(e)=>{console.log("positions: ", positions)}}>Check</Button>
*/}
                    <Form.Item style={{float: "right"}}>
                        <Space>

                            {id ? 
                            
                            <AddTodo
                                buttonText={"Add/check to-do"}
                                objectId={id}
                                objectType={TodoObjectType.InventoryDocument}
                                showList={true}
                                afterAdd={(v)=>{
                                    console.log()
                                    //setRefreshTodoList(!refreshTodoList)
                                }} />
                                :
                                ""
                            }

                            <Button className={"OrangeButton"} type="primary" htmlType="submit" onClick={handleSubmit}>
                                {id? t("Update_document") : t("Create_document")}
                            </Button>

                            <Button onClick={()=>history.push("/stockmovement")}>{t("Cancel")}</Button>
                            <Dropdown menu={{'items': deleteItems, onClick: handleDeleteOption }}  >
                                <Button >{t("Delete")}</Button>
                            </Dropdown>

                        </Space>
                    </Form.Item>

                </div>
            </Affix>
            
        </div>
    );
};

export default CreateDocumentPage;
