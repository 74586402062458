// src/services/productService.ts
import axios from 'axios';
import { Product } from '../types/Product';
import {DATA_URL} from './auth-service'
import authHeader from "./auth-header";

const API_URL = DATA_URL+'product';

export const productService = {
    // Получение всех продуктов
    async getAll(): Promise<Product[]> {
        const response = await axios.get<Product[]>(API_URL, {headers: authHeader()});
        return response.data;
    },

///api/Product/get-for-supplier
    async getForSupplier(id: string): Promise<Product[]> {
        try {
            const response = await axios.get(`${API_URL}/get-for-supplier?supplierId=${id}`, {headers: authHeader()});
            return response.data;
        } catch (error) {
            console.error('Error fetching products for supplier', error);
            throw error;
        }
    },

    async getProductsWithStockInfo(): Promise<Product[]> {
        try {
            const response = await axios.get(`${API_URL}/with-stock-info`, {headers: authHeader()});
            return response.data;
        } catch (error) {
            console.error('Error fetching products with stock info', error);
            throw error;
        }
    },
    

    // Получение продукта по ID
    async getById(id: string): Promise<Product> {
        const response = await axios.get<Product>(`${API_URL}/${id}`, {headers: authHeader()});
        return response.data;
    },

    // Создание нового продукта
    async create(product: Product): Promise<Product> {
        const response = await axios.post<Product>(API_URL, product, {headers: authHeader()});
        return response.data;
    },

    // Обновление существующего продукта
    async update(id: string, product: Product): Promise<void> {
        await axios.put(`${API_URL}/${id}`, product, {headers: authHeader()});
    },

    // Удаление продукта
    async delete(id: string): Promise<void> {
        await axios.delete(`${API_URL}/${id}`, {headers: authHeader()});
    },
    
 
    
};
