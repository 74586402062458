// pages/ProductEdit.tsx
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import UserSettings  from './UserSettings';
import { Supplier } from '../../types/Supplier';
import axios from 'axios';
import {message, Spin, Tabs, TabsProps} from 'antd';
import {productService} from "../../Services/productService";
import {
    RightOutlined,
    EditOutlined,
    SwapOutlined,
    TableOutlined,
    CodeSandboxOutlined,
    FileOutlined, UserOutlined, BankOutlined, SlidersOutlined, ToolOutlined
} from '@ant-design/icons';
import {supplierService} from "../../Services/supplierService";
import {useTranslation} from "react-i18next";

const SettingsPage: React.FC = () => {
    const { id } = useParams<{ id: string }>(); // Получаем ID товара из URL, если он есть
    const history = useHistory();
    const [supplier, setSupplier] = useState<Supplier | null>(null);
    const [loading, setLoading] = useState(false);

    const {t}=useTranslation();


    useEffect(() => {
        if (id) {
            setLoading(true);

            const data =  supplierService.getById(id)
                .then((response) => {
                    setSupplier(response);
                })
                .catch(() => {
                    message.error('Failed to load product');
                })
                .finally(() => {
                    setLoading(false);
                });

        }
    }, [id]);


    const onChange = (key: string) => {
        //console.log(key);
    };


    const operations = <>Extra Action</>;
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: t("Nastavitve_uporabnika"),
            icon: <UserOutlined/>,
            children: <UserSettings  />,
        },
        {
            key: '2',
            label: t("Nastavitve_podjetja"),
            icon: <BankOutlined />,
            //children: <SuppliersProductList supplierId={id} />,
        },
        /* 
        {
            key: '3',
            label: 'Application settings',
            icon: <ToolOutlined />// <SlidersOutlined />,
            //children:  <DocumentMovements supplierId={id} showInDrawer={true}></DocumentMovements>
            //<ProductForm  initialValues={product || undefined} productGroups={productGroups} onSave={handleSave}/>,
        },
        */
    ];



    if (loading) {
        return <Spin />;
    }

    return (
        <div>
            <h1>{t("Nastavitve")}

            </h1>
            <Tabs /*tabBarExtraContent={operations}*/ defaultActiveKey="1" items={items} onChange={onChange} />


        </div>
    );
};

export default SettingsPage;
