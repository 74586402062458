import React, {useEffect, useState} from 'react';
import './App.css';
import {ConfigProvider, Layout, Menu, Space, theme, Divider} from 'antd';
import {ArrowRightOutlined, TableOutlined, SettingOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons';
import {useHistory, withRouter} from "react-router-dom";

import {Routing} from "./Pages/app-router";
import HeadMenu from "./CommonComponents/HeaderMenu";
import * as AuthService from "../src/Services/auth-service";








function App() {

    const {
        token: { colorBgContainer, borderRadiusLG,   }, //#0083DD     #3693e1
    } = theme.useToken();


    const { Header, Content } = Layout;
    const history = useHistory();

    const user = AuthService.getCurrentUser();

    useEffect(()=>{
        if(!user){
            history.push("/login")
        }
    },[user]);



    ///Auth or not?
    

    function ContentDiv() {
        return <div>
            <Routing/>
        </div>;
    }

    // @ts-ignore
    return (
    <div className="App">

        <ConfigProvider
            theme={
            {
                components: {
                    Layout: {
                        headerBg: "#09214f",
                        bodyBg: "#fff"
                        /* here is your component tokens */
                    },
                    Menu:{
                        darkItemBg: "#09214f",
                        darkItemSelectedBg : "#1449af"
                    },
                    Table:{
                        headerBg: "transparent",
                        headerColor: "#777"
                    },
                },
            }}
        >
            {user ?
                <Layout>
                    <Header style={{display: 'flex', alignItems: 'center', position: 'sticky', top: 0, zIndex: 1}}>
                        <div className="demo-logo">
                            <div style={{marginTop: "-15px", color: "#e25e1e", fontWeight: "600", fontSize: "20px"}}>
                                <ArrowRightOutlined
                                style={{
                                    top: "20px",
                                    position: "absolute",
                                    left: "50px",
                                    fontSize: "16px",
                                    
                                    
                                }}
                                
                                />
                                KvikStock
                            </div>

                        </div>

                        <HeadMenu/>


                    </Header>
                    <Content style={{padding: '0 48px', minHeight: "90vh", textAlign: "left"}}>
                        <div
                            style={{
                                background: colorBgContainer,
                                minHeight: 280,
                                padding: 24,
                                borderRadius: borderRadiusLG,
                            }}
                        >
                            <ContentDiv/>
                        </div>
                    </Content>

                </Layout>
                :
                <Content style={{ /*margin: '24px 16px 0',*/ overflow: 'initial'}}>

                    <div
                        style={{
                            //background: colorBgContainer,
                            minHeight: 280,
                            //padding: 24,
                            //borderRadius: borderRadiusLG,
                        }}
                    >
                        <ContentDiv/>
                    </div>
                </Content>
            }
        </ConfigProvider>
        
    </div>
  );
}

export default withRouter(App);
