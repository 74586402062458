// src/services/uomService.ts
import axios from 'axios';
import { UnitOfMeasure } from '../types/UnitOfMeasure';
import {DATA_URL} from './auth-service'
import authHeader from "./auth-header";

const API_URL = DATA_URL+'unitofmeasure';

export const uomService = {
    // Получение всех едизмов
    async getAll(): Promise<UnitOfMeasure[]> {
        const response = await axios.get<UnitOfMeasure[]>(API_URL, {headers: authHeader()});
        return response.data;
    },



    // Получение едизма по ID
    async getById(id: string): Promise<UnitOfMeasure> {
        const response = await axios.get<UnitOfMeasure>(`${API_URL}/${id}`, {headers: authHeader()});
        return response.data;
    },

    // Создание нового едизма
    async create(unit: UnitOfMeasure): Promise<UnitOfMeasure> {
        const response = await axios.post<UnitOfMeasure>(API_URL, unit, {headers: authHeader()});
        return response.data;
    },

    // Обновление существующего едизма
    async update(id: string, unit: UnitOfMeasure): Promise<void> {
        await axios.put(`${API_URL}/${id}`, unit, {headers: authHeader()});
    },

    // Удаление едизма
    async delete(id?: string): Promise<void> {
        await axios.delete(`${API_URL}/${id}`, {headers: authHeader()});
    },



};
