import React, {useState, useEffect} from "react"
import {productService} from "../../Services/productService";
import {Product} from "../../types/Product";
import {Button, Drawer, Form, Input, notification, Select, Space, Spin} from "antd";
import {useTranslation} from "react-i18next";
import {ToDo, TodoObjectType} from "../../types/ToDo";
import {todoService} from "../../Services/todoService";
import {productGroupService} from "../../Services/productGroupService";
import TodosList from "./TodosList";
import {ThunderboltOutlined} from "@ant-design/icons";

interface ProductSelectProps {
    objectId? : string;
    objectType? : TodoObjectType
    afterAdd? : (v:any)=>void;
    buttonText? : string,
    showList? : boolean
}

const AddTodo=({objectType, objectId, afterAdd, buttonText, showList}: ProductSelectProps)=>{


    const [todos, setTodos ]=useState<ToDo[]|undefined>()
    const [loading, setLoading] = useState<boolean>(true);
   
    const [openDrawer, setopenDrawer] = useState(false)


    const [formV] = Form.useForm();
    
    useEffect(()=>{
        formV.setFieldValue("objectId", objectId)
        
    },[])



    const {t}=useTranslation();

    useEffect(() => {
        const fetchMovements = async () => {
            setLoading(true);
            try {
                
                const response = objectId ?
                    await todoService.getForObject(objectId, objectType) 
                    :
                    await todoService.getAll();
                setTodos(response);
            } catch (error) {
                console.error('Ошибка при получении задач:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchMovements();
    }, []);

    /*
    const handleSelect=(value: string)=>{
        setSelectedValue(value)
        if(afterSelect){
            afterSelect(value)
        }
    }
    
     */

    
    const onFinish= async (values: any)=>{
        await todoService.create(values);
        notification.success({ message: 'The todo has been successfully created!' });
        setopenDrawer(false);
        if(afterAdd){
            afterAdd(values);
        }

    }

    const { TextArea } = Input;
    return <div style={{display: "inline-block"}}>
        {buttonText==="icon" ?
            <div onClick={()=>{setopenDrawer(true)}}>
                <ThunderboltOutlined style={{fontSize: "24px", color: "#265a96"}}/><br/>
        {t("New_todo")}
            </div>
            
            
            
             
        :
            <Button onClick={()=>{setopenDrawer(true)}}>
        {buttonText? buttonText : "To do"}
            </Button>
        }


        <Drawer title={t("To_do")} placement="right" 
                destroyOnClose={true}
                onClose={()=>setopenDrawer(false)}
                open={openDrawer}>
            {
                <div>
                <Form
                    preserve={false}
                    form={formV}
                    name="formV"
                    onFinish={onFinish}
                    autoComplete="off"
                    //layout="horizontal"
                    //labelCol={{ span: 10 }}
                    //wrapperCol={{ span: 12 }}
                    style={{ maxWidth: "600" }}
                    initialValues={{["objectId"]: objectId, ["objectType"]: objectType}}

                >
                    <Form.Item hidden={true} 
                               name="objectId">
                        <Input value={objectId} />
                    </Form.Item>
                    <Form.Item hidden={true}
                               name="objectType">
                        <Input value={objectType} />
                    </Form.Item>
                    

                    <Form.Item
                        name="title"
                        label={""}
                        //rules={[{ required: true }, { type: 'string' }]}
                    >
                        <TextArea rows={4} />
                    </Form.Item>
                    <Form.Item>
                        <Space>
                            <Button className={"OrangeButton"} htmlType="submit">
                                {t("Ustvarite_novo_todo")}
                            </Button>
                            <Button className={"emptyButton"} onClick={()=>{setopenDrawer(false)}} >
                                {t("Ne_shranite_sprememb")}
                            </Button>

                        </Space>
                    </Form.Item>


                </Form>
                    <div>{showList? <div><TodosList objectId={objectId} objectType={objectType} />  </div> :""}</div>

                </div>
                
                /* 
            <CreateContractor key={0} afterCreate={setnewId}  />
            */}
        </Drawer>


    </div>
    
    
    
    /*
    <Spin spinning={loading}>
        <Select
            allowClear={true}
            showSearch={true}
            style={{width: "100%"}} placeholder={t("Select_a_product")}
            value={selectedValue}
            options={products?.map((prod)=>({label: prod.name, value: prod.id, code: prod.code}))}


            filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())||
                (option?.code ?? '').toLowerCase().includes(input.toLowerCase())
            }



            optionRender={(option) => (<div>
                <span style={{fontSize: "10px", color: "#999"}}>{option.data.code}</span><br/>
                <span>{option.data.label}</span>
            </div>)}


            onChange={handleSelect}

        />
        
    
    </Spin>
     
     */



}
export default AddTodo