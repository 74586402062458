import React, { useEffect, useState } from 'react';
import {Form, Input, Button, notification, Affix, Space, Divider, ConfigProvider, Dropdown, MenuProps} from 'antd';
import { productGroupService } from '../../Services/productGroupService'; // сервис для работы с API
import { useParams, useHistory } from 'react-router-dom';
import {RightOutlined} from "@ant-design/icons";
import {ProductGroup} from "../../types/ProductGroup";
import {useTranslation} from "react-i18next";
import AddTodo from "../../CommonComponents/ToDo/AddTodo";
import {TodoObjectType} from "../../types/ToDo";
import {Classification} from "../../types/Classification";
import {classificationService} from "../../Services/classificationService";
import {inventoryDocumentService} from "../../Services/inventoryDocumentService"; // Для получения параметров из URL и навигации


interface ClassificationFormProps {
    //id?: string;
}

const ClassificationForm: React.FC<ClassificationFormProps> = ({}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
     const { id } = useParams<{ id?: string }>(); // Получение ID поставщика из URL, если он есть
    const history = useHistory(); // Для навигации
    
    const [classification, setClassification] = useState<Classification>()
    
    const {t}=useTranslation();


    console.log("data:")
    
    // Загрузка данных поставщика для редактирования, если есть ID
    useEffect(() => {
        if (id) {
            setLoading(true);
            classificationService.getById(id)
                .then((data) => {
                    console.log("data:", data)
                    setClassification(data);
                    form.setFieldsValue(data);
                })
                .catch(() => {
                    notification.error({ message: 'Ошибка загрузки данных' });
                })
                .finally(() => setLoading(false));
        }
    }, [id, form]);

    // Обработка отправки формы
    const handleSubmit = async (values: any) => {

        const cls = {
            id: values.id? values.id : "",
            name: values.name,
            code: values.code,
            description: values.description?  values.description : "",
        }
        console.log("values to PUT", values)
        setLoading(true);
        try {
            if (id) {
                // Обновление существующей классификации
                await classificationService.update(id, cls);
                notification.success({ message: 'The classification has been successfully updated!' });
            } else {
                // Создание новой классификации
                console.log("values CLS", cls)

                await classificationService.create(values);
                notification.success({ message: 'The classification has been successfully created!' });

            }
            history.push('/classifications/'); // Навигация обратно на список поставщиков
        }
            //catch (error:any) {
            //     console.log(error)
            //     notification.error({ message: 'Ошибка сохранения поставщика', description: error.message });
            //} 
        finally {
            setLoading(false);
        }
    };


    const deleteItems: MenuProps['items'] = [
        { key: "0",  label: <span>{t("Delete")}</span> }];


    const handleDeleteOption: MenuProps['onClick'] = async (e) => {
        if(e.key==="0"){
            try {
                const response = await classificationService.delete(id)
                notification.success({ message: 'Classification deleted successfully!' });
                history.push("/classifications/")
            }
            catch (e) {
                notification.error({ message: 'Error deleting classification' });
            }
        }
    }
    

    return (
        <div>


            <div>
                <h1>
                        <span style={{color: "#999" , cursor: "pointer", borderBottom: "1px dashed #999"}}  
                              
                              onClick={()=> history.push("/classifications/")}>
        
                    {t("Classification")}</span>
                    <span style={{color: "#999"}}>
                    &nbsp;<RightOutlined size={16} style={{fontSize:"18px"}} /> &nbsp;
                </span>
                    {id ? classification?.name : t('Add_new_classification')}



                </h1>
            </div>


                <ConfigProvider
                theme={{
        components: {
            Form: {
                itemMarginBottom: 10
            },
        },
    }}
>
    <Form
        form={form}
    layout="horizontal"
    labelCol={{ flex: '180px' }}
    labelWrap={true}

    onFinish={handleSubmit}
    initialValues={{ name: '', supplierCode: '', address: '', phoneNumber: '', email: '', contactPerson: '' }}
>
    

        <Form.Item
            label={t("Code")}
            name="code"
            rules={[{ required: true, message: 'Введите код ' }]}
        >
            <Input />
        </Form.Item>

        <Form.Item hidden={true} name="id">
        <Input />
        </Form.Item>
        <Form.Item
    label={t("Naziv")}
    name="name"
    rules={[{ required: true, message: 'Введите имя ' }]}
        >
        <Input />
        </Form.Item>


        <Form.Item
    label={t("Description")}
    name="description"
        >
        <Input />
        </Form.Item>

    <Divider orientation={"left"} orientationMargin="0" style={{marginBottom: "60px"}}> </Divider>


    <Affix offsetBottom={0}>
    <div className="affix-shadow-wrapper">
    <Form.Item  style={{float: "right"}}>
    <Space>

        {/* 

        {id ?

            <AddTodo
                buttonText={"Add/check to-do"}
                objectId={id}
                objectType={TodoObjectType.ProductGroup}
                showList={true}
                afterAdd={(v)=>{
                    console.log()
                    //setRefreshTodoList(!refreshTodoList)
                }} />
            :
            ""
        }
        */}

        <Button type="primary" htmlType="submit" loading={loading}  className={"OrangeButton"}>
        {id ? t('Update_classification') : t('Create_classification')}
        </Button>

        <Button onClick={()=>history.push("/classifications")}>{t("Cancel")}</Button>

        {/* 
        <Dropdown menu={{'items': deleteItems, onClick: handleDeleteOption }}  >
            <Button >{t("Delete")}</Button>
        </Dropdown>
        */}
        
        
    </Space>
    </Form.Item>

    </div>
    </Affix>


    </Form>
    </ConfigProvider>
    </div>
);
};

export default ClassificationForm;
