// src/services/productGroupService.ts
import axios from 'axios';
import { ProductGroup } from '../types/ProductGroup';

import {DATA_URL} from './auth-service'
import authHeader from "./auth-header";
import {Classification} from "../types/Classification";
import {TypeOfMaterial} from "../types/TypeOfMaterial";

const API_URL = DATA_URL+'typeOfMaterial';

export const typeOfMaterialService = {
    // Получение всех групп продуктов
    async getAll(): Promise<TypeOfMaterial[]> {
        const response = await axios.get<TypeOfMaterial[]>(API_URL, {headers: authHeader()});
        return response.data;
    },

    // Получение группы продуктов по ID
    async getById(id: string): Promise<TypeOfMaterial> {
        const response = await axios.get<TypeOfMaterial>(`${API_URL}/${id}`, {headers: authHeader()});
        return response.data;
    },

    // Создание новой группы продуктов
    async create(typeOfMaterial: TypeOfMaterial): Promise<TypeOfMaterial> {
        const response = await axios.post<TypeOfMaterial>(API_URL, typeOfMaterial, {headers: authHeader()});
        return response.data;
    },

    // Обновление существующей группы продуктов
    async update(id: string, typeOfMaterial: TypeOfMaterial): Promise<void> {
        await axios.put(`${API_URL}/${id}`, typeOfMaterial, {headers: authHeader()});
    },

    // Удаление группы продуктов
    async delete(id: string): Promise<void> {
        await axios.delete(`${API_URL}/${id}`, {headers: authHeader()});
    }
};
