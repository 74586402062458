import React, {useState, useEffect} from "react"
import {productService} from "../Services/productService";
import {Product} from "../types/Product";
import {Select, Spin} from "antd";
import {InventoryMovementService} from "../Services/inventoryMovementService";
import {Warehouse, WarehouseType} from "../types/Warehouse";
import {warehouseService} from "../Services/WarehouseService";
import {useTranslation} from "react-i18next";

interface WarehouseSelectProps {
    defaultSelected? : string
    afterSelect? : (v:string)=>void;
    sort? : string;  //'warehouse' - warehouse first, 'project' - projects first
}

const WarehouseSelect=({defaultSelected, afterSelect, sort}: WarehouseSelectProps)=>{


    const [warehouses, setWarehouses ]=useState<Warehouse[]|undefined>()
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedValue, setSelectedValue] = useState(defaultSelected)

    const {t}=useTranslation()


    useEffect(() => {
        const fetchMovements = async () => {
            setLoading(true);
            try {
                const response = await warehouseService.getAll();

                    setWarehouses(response);
                
                //setWarehouses(response);
                //console.log("WarehouseSelect response: ",sort, response)
            } catch (error) {
                console.error('Ошибка при получении складов:', error);
            } finally {
                setLoading(false);
            }
        };
        setSelectedValue(defaultSelected)
        fetchMovements();
    }, [defaultSelected]);

    const handleSelect=(value: string)=>{
        if(afterSelect){
            afterSelect(value)
        }
    }

    return <Spin spinning={loading}>
        <Select
            allowClear={true}
            showSearch={true}
            style={{width: "100%"}} placeholder={t("Select_a_warehouse")}
            value={selectedValue}
            options={warehouses?.map((wareh)=>({label: (wareh.code? wareh.code : "#-")+" "+wareh.name, value: wareh.id, location: wareh.location, warehouseType: wareh.warehouseType}))}


            filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())||
                (option?.location ?? '').toLowerCase().includes(input.toLowerCase())
            }



            optionRender={(option) => (<div>
                <span 
                    style={{color: (Number(option.data.warehouseType) ===1? "#1449af": "#000")}}
                >{option.data.label}</span><br/>
                <span style={{fontSize: "10px", color: "#999"}}>{Number(option.data.warehouseType) ===1? t("Project") : option.data.location}</span>

            </div>)}


            onChange={handleSelect}

        />
    </Spin>



}
export default WarehouseSelect