import i18n from "i18next";
import {initReactI18next} from "react-i18next";

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: {
                UnitOfMeasures: "Unit Of Measures",
                Update_uom: "Update unit",
                Create_uom: "Create unit",
                Add_new_uom: "Add new unit of measure",

                Manufacturers: "Manufacturers",
                Update_manufacturer: "Update manufacturer",
                Create_manufacturer: "Create manufacturer",
                Add_new_manufacturer: "Add new manufacturer",


                Update_material: "Update type of material",
                Create_material: "Create type of material",
                Add_new_material: "Add new type of material",
                Update_classification: "Update classification",
                Create_classification: "Create classification",
                Add_new_classification: "Add new classification",
                Ustvarite_novo_todo: "Create new to-do",
                Ustvarite_nov_dogodek: "Create new event",
                up_to_5_product: "You can add up to 5 product groups",
                To_do: "To do",
                Latest_events: "Latest events",
                Create_new_arrival:"Create new arrival of articles",
                Add_new_movement:"Add new movement",
                Other_actions:"Other actions",
                New_event:"New event",
                New_todo:"New todo",
                Warehouse_from: "Warehouse (from)",
                Edit_document: "Edit document",
                New_document: "New document",
                Document_Number: "Document Number",
                Warehouse_to: "Warehouse (to)",
                Order_Number: "Order Number",
                Invoice_Number: "Invoice Number",
                Payment_Number: "Payment Number",
                Order_Date: "Order Date",
                Invoice_Date: "Invoice Date",
                Payment_Date: "Payment Date",
                Invoice_Amount: "Invoice Amount",
                Document_Date: "Document Date",
                Tax_22_Amount: "Tax 22% Amount",
                Tax_95_Amount: "Tax 9.5% Amount",
                Add_position: "Add position",
                Create_document: "Create document",
                Update_document: "Update document",
                Select_a_supplier: "Select a supplier",
                Document1: "Document",
                amount1: "amount",
                Number1: "Number",
                of_positions1: " of positions",
                Supplier: "Supplier",
                Stock_movements: "Stock movements",
                Movements_view: "Movements view",
                Documents_view: "Documents view",
                Add_new_document: "Add new document",
                New_purchase:"New purchase",
                New_transfer: "New transfer",
                New_sale: "New sale",
                New_writeoff: "New writeoff",
                Stock_level:"Stock level",
                Edit_warehouse_data:"Edit warehouse data",
                Warehouse_Type: "Warehouse Type",
                Location: "Location",
                Create_warehouse: "Create warehouse",
                Update_warehouse: "Update warehouse",
                Add_new_WarehouseProject: "Add new Warehouse/Project",
                To_see_warehouse: "To see details and edit warehouse's properties click on corresponding",
                column_header:" column header",
                and_stock_levels: " and stock levels",
                Project: "Project",
                Select_a_warehouse: "Select a warehouse",
                Purchase:"Purchase",
                Transfer:"Transfer",
                Sale:"Sale",
                Write_off:"Write-off",
                Initial_level:"Initial level",
                Final_level:"Final level",
                Type: "Type",
                Warehouses:"Warehouses",
                Projects:"Projects",
                Warehouse_Name:"Warehouse Name",
                Project_Name:"Project Name",
                Stock_availability:"Stock availability",
                Products_stock_movements:"Product\'s stock movements",
                Edit_product_data:"Edit product data",
                Create_product: "Create product",
                Update_product: "Update product",
                Unit_of_Measure:"Unit of Measure",
                VAT_Group:"VAT Group",
                Other_info:"Other info",
                Standard:"Standard",
                Defect:"Defect",
                Stock:"Stock",
                Input_Method:"Input Method",
                Control:"Control",
                Is_Packaged:"Is Packaged",
                Decimal_Places:"Decimal Places",
                Use_By_Date:"Use-By Date",
                Account:"Account",
                Main_Warehouse:"Main Warehouse",
                Upload_picture:"Upload picture",
                Add_new_supplier: "Add new supplier",
                Create_supplier: "Create supplier",
                Update_supplier: "Update supplier",
                Manufacturer: "Manufacturer",
                Total_Stock: "Total Stock",
                Product_group:"Product group",
                Quality: "Quality",
                Type_Of_Material:"Type of material",
                Origin: "Origin",
                Classification: "Classification",
                Update_group: "Update group",
                Create_group: "Create group",
                Cancel: "Cancel",
                Add_new_product_group: "Add new product group",
                Description: "Description",
                Select_a_product: "Select a product",
                Code: "Code",
                Supplier_Code: "Supplier Code", 
                Documents: "Documents",
                Products_supplied: "Products supplied",
                Edit_supplier_data: "Edit supplier data",
                Credentials: "Credentials",
                Suppliers: "Suppliers",
                Product_groups:"Product groups",
                Stocks: "Stocks",
                Movements: "Movements",
                Update_information: "Update information",
                Contacts: "Contacts",
                Sart_quantity: "Start quantity",
                On_processing: "In Processing",
                May_still_make_changes: "You can still make changes",
                Status: "Status",
                Sent_invoice: "Sent invoice",
                Received_invoice: "Received invoice",
                Error: "Error",
                Osnovni_podatki:'General info',
                Zgodovina_opravljenih_letov : 'History of orders',
                Urejanje_podatkov_o_partnerju : 'Edit contractor\'s data',
                Podatki_dokumenta: "Document data",
                Invoice_is_booked: "The invoice is on the books",
                No_changes_aalowed: "It cannot be changed",
                Add_new_bank_account: "Add new bank account",
                Account_to_which_the_funds_are_to_be_paid: "Account to which the funds are to be paid",
                bank_account: "Bank account",
                before_print: "Please, save the the document before printing",
                IbanError: "Invalid IBAN format",
                BicError: "Invalid BIC format",
                Prenesite_dokument: 'Upload document',
                Prenesite_datoteko_ali_povlecite : 'Upload file or drag',
                in_spusti_na_označeno_območje : 'and drop it in this area',
                No_Bank_froinvoice: "To create an issued invoice, add your bank details or click \"Don't save\"",
                Postcode: "Postal code",
                Search_for_company: "Search for company",
                Done: "Done!",
                January: "January",
                February: "February",
                March: "March",
                April: "April",
                May: "May",
                June: "June",
                July: "July",
                August: "August",
                September: "September",
                October: "October",
                November: "November",
                December: "December",
                No_bank_accs: "You haven't added any accounts yet",
                Bank_accounts: "Bank accounts",
                Edit_bank_account: "Edit your bank account",
                privacy_confirm_help: "Your consent to the terms of processing of personal data is required",
                rules_confirm_help: "Your consent to the terms of use is required",
                Select_the_customer: "Select the customer to whom you want to issue the invoice",
                Specify_the_date:"The date of invoice",
                Until_what_date: "Until what date",
                at_least_one_position: "Add at least one item",
                Davek_za_DDV: "VAT",
                isssued_draft:"draft",
                issued:"issued",
                received_confirmed:"received, confirmed",
                received_rejected:"received, rejected",
                received_draft:"draft",
                paid:"paid",
                partially_paid_overdue:"partially paid, overdue",
                partially_paid:"partially paid",
                unpaid_overdue:"unpaid, overdue",
                unpaid:"unpaid",
                draft:"draft",
                advance_payment:"advance payment",
                Delete_warning: "Is this really what you want?",
                Delete_text: "An item can be deleted if there are no invoices showing the item. Delete an item?",
                Yes: "Yes",
                No: "No",
                goods: "Goods",
                material: "Material",
                semifinishedProduct: "Semifinished product",
                product: "Product",
                services:  "Services",
                advancePayment: "Advance payment",
                advancePaymentServices: "Advance payment for services",
                Welcomed_by: "Welcome to ",
                Good_afternoon:"Good afternoon.",
                What_do_you_want_to_do:"What do you want to do?",
                Issue_an_invoice_to_a_customer:"Issue an invoice to a customer",
                You_can_also_create_a_new_invoice_from_the_Documents_section:"All purchases to the warehouse can be created and edited from the ‘Movements’ section",
                Upload_incoming_invoice:"Transfers from warehouse to warehouse, shipments and write-offs can also be made from the ‘Transfers’ section",
                Upload_invoice_scan:"Upload an incoming invoice in pdf, jpg or png format",
                Upload_a_statement:"Upload a statement",
                Upload_pdf_or_csv:"Upload a csv file or scan in pdf, jpg or png format.",
                Send_us_documents:"Send us documents",
                Contracts_annexes_and_other_documents:"Contracts, annexes, and other documents",
                Tasks:"Tasks",
                No_new_tasks:"No new tasks",
                Interface_language:"Interface language",
                Events_in_June:"Events in June",
                Salary_payment:"Salary payment",
                Small_checklist:"Small checklist",
                Has_anyone_had_a_salary_increase:"Has anyone had a salary increase?",
                Is_the_account_balance_sufficient: "Is the account balance sufficient for disbursements?",
                Statehood_Day:"Statehood Day",
                Its_a_day_off_in_Slovenia:"It's a holiday in Slovenia.",
                Bank_statement:"Bank statement",
                Generate_a_bank_statement_for_June_and_upload_it_for_us:"Generate a bank statement for June and upload it for us",
                Street_and_house_number: "Street and house number",
                City: "City",
                ZipCode: "Zip Code",
                More: "More",
                Podatki_o_partnerju: "Partner details",
                Stranka: "Client",
                Izvajalec: "Contractor",
                Stranka_ali_izvajalec: "Client or contractor",
                Kontaktna_oseba: "Contact person",
                Telefon: "Phone",
                Faks: "Fax",
                Delete: "Delete",
                Product: "Product",
                Service: "Service",
                Ne_shranite_sprememb: "Don't save",
                Nov_izdani_račun: "New issued invoice",
                Nov_prejeti_račun: "New received invoice",
                from: "from",
                Predogled: "Preview",
                Vsa: "All",
                Nova: "New",
                Zaključena : "Completed ",
                Od_datum: "From date",
                Do_datum: "To date",
                Show_archived: "Show archived",
                Išči: "Search",
                Sent_invoices: "Issued invoices",
                Received_invoices: "Received invoices",
                Vrsta_ddokumenta: "Document type",
                Amount: "Amount",
                Nov_partner: "New counterpart",
                title:"Title",
                Unit:"Unit",
                CurrentStock:"Current Stock",
                Cost:"Cost",
                Price:"Price",
                Currency:"Currency",
                Kliknite_za_razvrščanje_padajoče:"Click to sort descending",
                Kliknite_za_razvrščanje_naraščajoče:"Click to sort ascending",
                Prekliči_razvrščanje:"Cancel sort",
                Več: "More",
                Naziv : 'Title',
                Davčna_Številka : 'Tax Id',
                Matična_Številka : 'Company Id',
                Naslov : 'Address',
                Domov : 'Home',
                Dokumenti : 'Documents',
                Stranke :' Partners',
                Nastavitve :'Settings',
                Izdelki: "Products",
                Odjava : 'Exit',
                E_pošta : 'Email',
                Staro_geslo : 'Old password',
                Novo_geslo: 'New password',
                Ime: 'Name',
                Priimek : 'Surname',
                Nastavitve_uporabnika : 'User\'s settings',
                Nastavitve_podjetja : 'Company settings',
                new_product: "New product",
                add_new_product: "Add new product",
                save_and_stay: "Save and continue editing",
                save_and_quit: "Save and go to list",
                Log_in: "Log in",
                Remember_me : "Remember me",
                Forgot_password : "Forgot password?",
                Please_input_your_Username : "Please input your Username (it is email)",
                Please_input_your_Password : "Please input your Password",
                Please_create_your_Password : "Please input your new Password (minimum 8 characters)",
                Please_enter_your_First_Name: "Please enter your First Name",
                Please_enter_your_Last_Name: "Please enter your Last Name",
                Login : 'Login',
                Password : "Password",
                Nov_dokument: "New invoice",
                Vrsta: "Type",
                Račun: "Invoice",
                Predračun: "Bill",
                Številka: "Number",
                Za_naročilo: "",
                Nasprotna_stranka: "Counterpart",
                Datum_dokumenta: "Document date",
                Datum_plačila: "Payment date",
                Shraniti: "Save",
                Tiskanje: "Print",
                Ustvarite_novo_podjetje: "Add new counterpart",
                Naziv_storitva_blaga: "Product or service",
                Količina : "Quantity",
                Davek : "Tax",
                Cena : "Price",
                Dodajte_vrstico: "Add position",
                ID_za_DDV: "VAT ID",
                Hvala_za_zaupanje: "Thank you for your trust",
                Znesek_prosimo_plačajte_na: "Please pay the amount to",
                Ob_plačilu_navedite_referenco: "Please provide a reference when making payment",
                Skupaj : "Total",
                Jezik_dokumenta: "Document language",
                Warehouse: "Warehouse",
                Product_name: "Product name",
                Purchase_price: "Purchase price",
                Movement_date: "Movement date",
                Start_stock: "Start stock",
                Stock_write_off: "Stock write-off",
                Stock_receipt: "Stock receipt",
                Final_stock: "Final stock"
            }
        },
        si: {
            translation: {
                UnitOfMeasures: "Unit Of Measures",
                Update_uom: "Update unit",
                Create_uom: "Create unit",
                Add_new_uom: "Add new unit of measure",

                Manufacturers: "Manufacturers",
                Update_manufacturer: "Update manufacturer",
                Create_manufacturer: "Create manufacturer",
                Add_new_manufacturer: "Add new manufacturer",

                Update_material: "Posodobite vrsto materiala",
                Create_material: "Ustvarite vrsto materiala",
                Add_new_material: "Dodajte novo vrsto materiala",
                Update_classification: "Posodobite klasifikacijo",
                Create_classification: "Ustvarite klasifikacijo",
                Add_new_classification: "Dodajte novo klasifikacijo",
                Ustvarite_nov_dogodek: "Ustvarite nov dogodek",
                Ustvarite_novo_todo: "Ustvarite novo to-do",
                up_to_5_product: "Dodate lahko do 5 skupin izdelkov",
                To_do: "Naloge",
                Latest_events: "Najnovejši dogodki",
                Create_new_arrival:"Ustvarite nov prihod artikla",
                Add_new_movement:"Dodajte novo gibanje",
                Other_actions:"Druga dejanja",
                New_event:"Nov dogodek",
                New_todo:"Nova naloga ",
                Warehouse_from: "Skladišče (od)",
                Edit_document: "Urejanje dokumenta",
                New_document: "Nov dokument",
                Document_Number: "Številka dokumenta",
                Warehouse_to: "Skladišče (na)",
                Order_Number: "Številka naročila",
                Invoice_Number: "Številka računa",
                Payment_Number: "Številka plačila",
                Order_Date: "Datum naročila",
                Invoice_Date: "Datum računa",
                Payment_Date: "Datum plačila",
                Invoice_Amount: "Znesek na računu",
                Document_Date: "Datum dokumenta",
                Tax_22_Amount: "Davek 22%",
                Tax_95_Amount: "Davek 9,5% ",
                Add_position: "Dodajanje pozicije",
                Create_document: "Ustvarite dokument",
                Update_document: "Posodobitev dokumenta",
                Select_a_supplier: "Izberite dobavitelja",
                Document1: "Znesek",
                amount1: "dokumenta",
                Number1: "Količina",
                of_positions1: " pozicij",
                Supplier: "Dobavitelj",
                Stock_movements: "Gibanje zalog",
                Movements_view: "Pogled na gibanja",
                Documents_view: "Pogled dokumentov",
                Add_new_document: "Dodaj nov dokument",
                New_purchase:"Nov nakup",
                New_transfer: "Nov prenos",
                New_sale: "Nova prodaja",
                New_writeoff: "Nov odpis",
                Stock_level:"Stopnja zaloge",
                Edit_warehouse_data:"Podatke o skladišču",
                Warehouse_Type: "Vrsta skladišča",
                Location: "Lokacija",
                Create_warehouse: "Ustvarite skladišče",
                Update_warehouse: "Posodobi skladišče",
                Add_new_WarehouseProject: "Dodaj novo skladišče/projekt",
                To_see_warehouse: "Za ogled podrobnosti in urejanje lastnosti skladišča kliknite na ",
                column_header:" naslov ustreznega stolpca",
                and_stock_levels: " in ravni zalog",
                Project: "Projekt",
                Select_a_warehouse: "Izberite skladišče",
                Purchase:"Nakup",
                Transfer:"Prenos",
                Sale:"Prodaja",
                Write_off:"Odpis",
                Initial_level:"Začetna raven",
                Final_level:"Končna raven",
                Type: "Vrsta",
                Warehouses:"Skladišča",
                Projects:"Projekti",
                Warehouse_Name:"Ime skladišča",
                Project_Name:"Ime projekta",
                Stock_availability:"Razpoložljivost zalog",
                Products_stock_movements:"Gibanje zalog artikla",
                Edit_product_data:"Urejanje podatkov o artiklu",
                Create_product: "Ustvarite izdelek",
                Update_product: "Posodobi izdelek",
                Unit_of_Measure:"Merilna enota",
                VAT_Group:"Skupina DDV",
                Other_info:"Druge informacije",
                Standard:"Standard",
                Defect:"Napaka",
                Stock:"Zaloga",
                Input_Method:"Metoda vnosa",
                Control:"Nadzor",
                Is_Packaged:"Ali je pakirano",
                Decimal_Places:"Decimalna mesta",
                Use_By_Date:"Datum uporabe",
                Account:"Račun",
                Main_Warehouse:"Glavno skladišče",
                Upload_picture:"Prenesite sliko",
                Add_new_supplier: "Dodaj novega dobavitelja",
                Create_supplier: "Ustvarite dobavitelja",
                Update_supplier: "Posodobi dobavitelja",
                Manufacturer: "Proizvajalec",
                Total_Stock: "Skupna zaloga",
                Product_group:"Skupina artiklov",
                Quality: "Kakovost",
                Type_Of_Material:"Vrsta materiala",
                Classification: "Klasifikacija",
                Origin: "Izvor",
                Update_group: "Posodobite skupino",
                Create_group: "Ustvarite skupino",
                Cancel: "Prekliči",
                Add_new_product_group: "Dodaj novo skupino izdelkov",
                Description: "Opis",
                Select_a_product: "Izberite artikel",
                Code: "Šifra",
                Supplier_Code: "Koda dobavitelja",
                Documents: "Dokumenti",
                Products_supplied: "Dobavljeni izdelki",
                Edit_supplier_data: "Urejanje podatkov o dobavitelju",
                Credentials: "Geslo",
                Suppliers: "Dobavitelji",
                Product_groups:"Skupine izdelkov",
                Stocks: "Skladišča",
                Movements: "Gibanje",
                Update_information: "Posodabljanje informacij",
                Contacts: "Kontakti",
                Sart_quantity: "Не забыть указать перевод!!!!",
                On_processing:"V obdelavi",
                May_still_make_changes: "Še vedno lahko opravite prilagoditve",
                Status: "Status",
                Sent_invoice: "Izdani račun",
                Received_invoice: "Prejeti račun",
                Error: "Napaka",
                Osnovni_podatki:'Osnovni podatki',
                Zgodovina_opravljenih_letov : 'Zgodovina naročil',
                Urejanje_podatkov_o_partnerju : 'Urejanje podatkov o partnerju',
                Podatki_dokumenta: "Podatki o dokumentu",
                Invoice_is_booked: "Račun je v poslovnih knjigah",
                No_changes_aalowed: "Ni ga mogoče spremeniti",
                Add_new_bank_account: "Dodajte bančni račun",
                Account_to_which_the_funds_are_to_be_paid: "Račun, na katerega se sredstva knjižijo v dobro",
                bank_account: "Bančni račun",
                before_print: "Pred tiskanjem shranite dokument.",
                IbanError: "Nepravilna oblika IBAN",
                BicError: "Nepravilna oblika BIC",
                Prenesite_dokument: 'Prenesite dokument',
                Prenesite_datoteko_ali_povlecite : 'Prenesite datoteko ali povlecite',
                in_spusti_na_označeno_območje : 'in spusti na označeno območje',
                No_Bank_froinvoice: "Če želite ustvariti izdani račun, dodajte vaši bančne podatke ali kliknite „Ne shranite sprememb“.",
                Postcode: "Poštna številka",
                Search_for_company: "Iskanje podjetja",
                Done: "Zaključeno!",
                January: "Januar",
                February: "Februar",
                March: "Marec",
                April: "April",
                May: "Maj",
                June: "Junij",
                July: "Julij",
                August: "Avgust",
                September: "September",
                October: "Oktober",
                November: "November",
                December: "December",
                No_bank_accs: "Niste dodali še nobenega bančnega računa",
                Bank_accounts: "Bančni računi",
                Edit_bank_account: "Ureditev vašega bančnega računa",
                privacy_confirm_help: "Potrebno je vaše soglasje s obdelavo osebnih podatkov.",
                rules_confirm_help: "Potrebno je vaše soglasje s pogoji uporabe.",
                Select_the_customer: "Določite stranko, ki ji želite izdati račun.",
                Specify_the_date:"Določite datum računa",
                Until_what_date: "Do datuma",
                at_least_one_position: "Dodajte vsaj en artikel",
                Davek_za_DDV: "Davek za DDV",
                isssued_draft:"osnutek",
                issued:"izdan",
                received_confirmed:"prejeto, potrjeno",
                received_rejected:"prejeto, zavrnjeno",
                received_draft:"osnutek",
                paid:"plačan",
                partially_paid_overdue:"delno plačan, zapadli",
                partially_paid:"delno plačan",
                unpaid_overdue:"neplačan, zapadli",
                unpaid:"neplačan",
                draft:"osnutek",
                advance_payment:"predplačilo", 
                Delete_warning: "Ali si to res želite?",
                Delete_text: "Element lahko izbrišete, če zanj ni izstavljenih računov. Izbrisati postavko?",
                Yes: "Da",
                No: "Ne",
                goods: "Blago",
                material: "Material",
                semifinishedProduct: "Polizdelki",
                product: "Izdelek",
                services:  "Storitve",
                advancePayment: "Predplačilo",
                advancePaymentServices: "Predplačilo za storitve",
                Welcomed_by: "Dobrodošli v  ",
                Good_afternoon:"Dober dan.",
                What_do_you_want_to_do:"Kaj želite storiti?",
                Issue_an_invoice_to_a_customer:"Izdati račun stranki",
                You_can_also_create_a_new_invoice_from_the_Documents_section:"Vse nakupe v skladišče lahko ustvarite in urejate v razdelku „Gibanja“.",
                Upload_incoming_invoice:"Prenesite prejeti račun",
                Upload_invoice_scan:"Prenose iz skladišča v skladišče, pošiljke in odpise lahko opravite tudi v razdelku „Gibanja“.",
                Upload_a_statement:"Prenesite izpisk",
                Upload_pdf_or_csv:"Prenesite datoteko csv ali skenogram v formatu pdf, jpg ali png",
                Send_us_documents:"Pošljite nam dokumente",
                Contracts_annexes_and_other_documents:"Pogodbe, priloge in drugi dokumenti",
                Tasks:"Naloge",
                No_new_tasks:"Ni novih nalog",
                Interface_language:"Jezik vmesnika",
                Events_in_June:"Dogodki v juniju",
                Salary_payment:"Izplačilo plač",
                Small_checklist:"Majhen kontrolni seznam",
                Has_anyone_had_a_salary_increase:"Ali se je komu povečala plača?",
                Is_the_account_balance_sufficient: "Ali stanje na računu zadošča za izplačila?",
                Statehood_Day:"Dan državnosti",
                Its_a_day_off_in_Slovenia:"V Sloveniji je dela prost dan.",
                Bank_statement:"Bančni izpisek",
                Generate_a_bank_statement_for_June_and_upload_it_for_us:"Ustvarite bančni izpisek za junij in nam ga naložite",
                Street_and_house_number: "Ulica in hišna številka",
                City: "Mesto",
                ZipCode: "Poštna številka",
                More: "Več",
                Podatki_o_partnerju: "Podatki o partnerju",
                Stranka: "Stranka",
                Izvajalec: "Izvajalec",
                Stranka_ali_izvajalec: "Stranka ali izvajalec",
                Kontaktna_oseba: "Kontaktna oseba",
                Telefon: "Telefon",
                Faks: "Faks",
                Delete: "Brisanje",
                Product: "Izdelek",
                Service: "Storitev",
                Ne_shranite_sprememb: "Ne shranite sprememb",
                Nov_izdani_račun: "Nov izdani račun",
                Nov_prejeti_račun: "Nov prejeti račun",
                from: "od",
                Predogled: "Predogled",
                Vsa: "Vsa",
                Nova: "Nova",
                Zaključena : "Zaključena ",
                Od_datum: "Od datum",
                Do_datum: "Do datum",
                Show_archived: "Prikaži arhivirano",
                Išči: "Išči",
                Sent_invoices: "Izdani računi",
                Received_invoices: "Prejeti računi",
                Vrsta_ddokumenta: "Vrsta ddokumenta",
                Amount: "Znešek",
                Nov_partner: "Nov partner",
                Unit:"Enota",
                CurrentStock:"Trenutna zaloga",
                Cost:"Stroški",
                Price:"Cena",
                Currency:"Valuta",
                Kliknite_za_razvrščanje_padajoče:"Kliknite za razvrščanje padajoče",
                Kliknite_za_razvrščanje_naraščajoče:"Kliknite za razvrščanje naraščajoče",
                Prekliči_razvrščanje:"Prekliči razvrščanje",
                Več: "Več",
                Naziv : 'Naziv',
                Davčna_Številka : 'Davčna Številka',
                Matična_Številka : 'Matična Številka',
                Naslov : 'Naslov',
                Domov : 'Domov',
                Dokumenti : 'Dokumenti',
                Stranke :' Stranke',
                Nastavitve :'Nastavitve',
                Izdelki: "Artikli",
                Odjava : 'Odjava',
                E_pošta : 'E-pošta',
                Staro_geslo : 'Staro geslo',
                Novo_geslo: 'Novo geslo',
                Ime: 'Ime',
                Priimek : 'Priimek',
                Nastavitve_uporabnika : 'Nastavitve uporabnika',
                Nastavitve_podjetja : 'Nastavitve podjetja',
                new_product: "Nov artikel",
                add_new_product: "Dodajanje novega artikla",
                save_and_stay: "Shranite in nadaljujte z urejanjem",
                save_and_quit: "Shranite in pojdite na seznam",
                Log_in: "Prijava",
                Remember_me : "Zapomni si me",
                Forgot_password : "Pozabljeno geslo?",
                Please_input_your_Username : "Vnesite svoje uporabniško ime (to je email)",
                Please_input_your_Password : "Vnesite geslo",
                Please_create_your_Password: "Vnesite novo geslo (najmanj 8 znakov)",
                Please_enter_your_First_Name: "Prosimo, vnesite svoje ime",
                Please_enter_your_Last_Name: "Prosimo, vnesite svoj priimek",
                Login : 'Login',
                Password : "Geslo",
                Nov_dokument: "Nov račun",
                Vrsta: "Vrsta",
                Račun: "Račun",
                Predračun: "Predračun",
                Številka: "Številka",
                Za_naročilo: "",
                Nasprotna_stranka: "Stranka",
                Datum_dokumenta: "Datum računa",
                Datum_plačila: "Datum plačila",
                Shraniti: "Shraniti",
                Tiskanje: "Tiskanje",
                Ustvarite_novo_podjetje: "Ustvarite novo podjetje",
                Naziv_storitva_blaga: "Naziv storitva blaga",
                Količina : "Količina",
                Davek : "Davek",
                Cena : "Cena",
                Dodajte_vrstico: "Dodajte vrstico",
                ID_za_DDV: "ID za DDV",
                Hvala_za_zaupanje: "Hvala zazaupanje",
                Znesek_prosimo_plačajte_na: "Znesek prosimo plačajte na",
                Ob_plačilu_navedite_referenco: "Ob plačilu navedite referenco",
                Skupaj : "Skupaj",
                Jezik_dokumenta: "Jezik dokumenta",
                Warehouse: "Skladišče",
                Product_name: "Ime izdelka",
                Purchase_price: "Nabavna cena", 
                Movement_date: "Datum",
                Start_stock: "Začetna količina",
                Stock_write_off: "Odpis",
                Stock_receipt: "Sprejem",
                Final_stock: "Končno stanje"
            }
        },
        ru: {
            translation: {
                UnitOfMeasures: "Unit Of Measures",
                Update_uom: "Update unit",
                Create_uom: "Create unit",
                Add_new_uom: "Add new unit of measure",

                Manufacturers: "Manufacturers",
                Update_manufacturer: "Update manufacturer",
                Create_manufacturer: "Create manufacturer",
                Add_new_manufacturer: "Add new manufacturer",
               
                Update_material: "Обновить тип материала",
                Create_material: "Создать тип материала",
                Add_new_material: "Добавить новый тип материала",
                Update_classification: "Обновить классификацию",
                Create_classification: "Создать классификацию",
                Add_new_classification: "Добавить новую классификацию",
                Ustvarite_nov_dogodek: "Добавить событие",
                Ustvarite_novo_todo: "Добавить задачу",
                up_to_5_product: "Можно добавить до 5 групп",
                To_do: "Задачи",
                Latest_events: "Последние события",
                Create_new_arrival:"Добавить новое поступлениеs",
                Add_new_movement:"Добавить новое движение",
                Other_actions:"Другие действия",
                New_event:"Новое событие",
                New_todo:"Новое задание",
                Warehouse_from: "Склад (откуда)",
                Edit_document: "Редактирование документа",
                New_document: "Новый документ",
                Document_Number: "Номер документа",
                Warehouse_to: "Склад (куда)",
                Order_Number: "Номер заказа",
                Invoice_Number: "Номер счета-фактуры",
                Payment_Number: "Номер платежа",
                Order_Date: "Дата заказа",
                Invoice_Date: "Дата счета-фактуры",
                Payment_Date: "Дата платежа",
                Invoice_Amount: "Сумма счета-фактуры",
                Document_Date: "Дата документа",
                Tax_22_Amount: "Налог 22%",
                Tax_95_Amount: "Налог 9,5%",
                Add_position: "Добавить позицию",
                Create_document: "Создать документ",
                Update_document: "Обновить документ",
                Select_a_supplier: "Выбрать поставщика",
                Document1: "Сумма",
                amount1: "документа",
                Number1: "количество",
                of_positions1: " позиций",
                Supplier: "Поставщик",
                Stock_movements: "Перемещения запасов",
                Movements_view: "Просмотр перемещений",
                Documents_view: "Просмотр документов",
                Add_new_document: "Добавить новый документ",
                New_purchase:"Новая закупка",
                New_transfer: "Новое перемещение",
                New_sale: "Новая продажа",
                New_writeoff: "Новое списание",
                Stock_level:"Уровень запасов",
                Edit_warehouse_data:"Редактировать данные склада",
                Warehouse_Type: "Тип склада",
                Location: "Местоположение",
                Create_warehouse: "Создать склад",
                Update_warehouse: "Обновить склад",
                Add_new_WarehouseProject: "Добавить новый склад/проект",
                To_see_warehouse: "Для просмотра подробностей и редактирования свойств склада нажмите на ",
                column_header:" заголовок соответствующего столбца",
                and_stock_levels: " и уровень зарасов",
                Project: "Проект",
                Select_a_warehouse: "Выберите склад",
                Purchase:"Покупка",
                Transfer:"Перемещение",
                Sale:"Продажа",
                Write_off:"Списание",
                Type: "Тип",
                Warehouses:"Склады",
                Projects:"Проекты",
                Warehouse_Name:"Название склада",
                Project_Name:"Название проекта",
                Stock_availability:"Остатки на складах",
                Products_stock_movements:"Движение по складам",
                Edit_product_data:"Данные о товаре",
                Create_product: "Создать товар",
                Update_product: "Обновить товар",
                Unit_of_Measure:"Единица измерения",
                VAT_Group:"Группа НДС",
                Other_info:"Прочая информация",
                Standard:"Стандарт",
                Defect:"Дефект",
                Stock:"Склад",
                Input_Method:"Метод ввода",
                Control:"Контроль",
                Is_Packaged:"Упаковка",
                Decimal_Places:"Десятичные знаки",
                Use_By_Date:"Дата использования",
                Account:"Счет",
                Main_Warehouse:"Главный склад",
                Upload_picture:"Загрузить изображение",
                Add_new_supplier: "Добавить нового поставщика",
                Create_supplier: "Создать поставщика",
                Update_supplier: "Обновить поставщика",
                Manufacturer: "Производитель",
                Total_Stock: "Общий запас",
                Product_group:"Товарная группа",
                Quality: "Качество",
                Type_Of_Material:"Тип материала",
                Origin: "Происхождение",
                Classification: "Классификация",
                Update_group: "Обновить группу",
                Create_group: "Создать группу",
                Cancel: "Отменить",
                Add_new_product_group: "Добавить новую товарную группу",
                Description: "Описание",
                Select_a_product: "Выберите товар",
                Code: "Код",
                Supplier_Code: "Код поставшика",
                Documents: "Документы",
                Products_supplied: "Поставляемые товары",
                Edit_supplier_data: "Данные поставщика",
                Credentials: "Доступ",
                Suppliers: "Поставшики",
                Product_groups:"Группы товаров",
                Stocks: "Склады",
                Movements: "Движения",
                Update_information: "Обновить информацию",
                Contacts: "Контакты",
                Sart_quantity: "Начальное количество",
                On_processing:"В обработке",
                May_still_make_changes: "Еще можно внести корректировки",
                Status: "Статус",
                Sent_invoice: "Исходящий счет",
                Received_invoice: "Входящий счет",
                Error: "Ошибка",
                Osnovni_podatki:'Общая информация',
                Zgodovina_opravljenih_letov : 'История заказов',
                Urejanje_podatkov_o_partnerju : 'Редактирование данных о партнере',
                Podatki_dokumenta: "Данные документа",
                Invoice_is_booked: "Счет проведен по бухгалтерии",
                No_changes_aalowed: "Изменять его нельзя",
                Add_new_bank_account: "Добавить рассчетный счет",
                Account_to_which_the_funds_are_to_be_paid: "Счет, на который должны поступить средства",
                bank_account: "Расчетный счет",
                before_print: "Пожалуйста, сохраните документ перед печатью",
                IbanError: "Не корректный формат IBAN",
                BicError: "Не корректный формат BIC",
                Prenesite_dokument: 'Загрузить документ',
                Prenesite_datoteko_ali_povlecite : 'Загрузите файл или перетащите ',
                in_spusti_na_označeno_območje : 'его в эту область',
                No_Bank_froinvoice: "Чтобы создать исходящий счет, добавьте свои банковские реквизиты или нажмите \"Отменить\"",
                Postcode: "Индекс",
                Search_for_company: "Поиск по компаниям",
                Done: "Готово!",
                January: "Январь",
                February: "Февраль",
                March: "Март",
                April: "Апрель",
                May: "Май",
                June: "Июнь",
                July: "Июль",
                August: "Август",
                September: "Сентябрь",
                October: "Октябрь",
                November: "Ноябрь",
                December: "Декабрь",
                No_bank_accs: "Вы еще не добавили ни одного банковского счета",
                Bank_accounts: "Cчета в банках",
                Edit_bank_account: "Ваши банковские реквизиты",
                privacy_confirm_help: "Необходимо Ваше согласие с условиями обработки персональных данных",
                rules_confirm_help: "Необходимо Ваше согласие с условиями использования",
                Select_the_customer: "Укажите клиента, кому хотите выставить счет",
                Specify_the_date:"Укажите дату счета",
                Until_what_date: "До какого числа",
                at_least_one_position: "Добавьте хотя бы одну позицию",
                Davek_za_DDV: "НДС",
                isssued_draft:"черновик",
                issued:"издан",
                received_confirmed:"получен, подтвержден",
                received_rejected:"получен, отклонен",
                received_draft:"черновик",
                paid:"оплачен",
                partially_paid_overdue:"частично оплачен, просрочен",
                partially_paid:"частично оплачен",
                unpaid_overdue:"неоплачен, просрочен",
                unpaid:"неоплаченный",
                draft:"черновик",
                advance_payment:"авансовый платёж",
                Delete_warning: "Это то, что Вы хотите?",
                Delete_text: "Удалить товар можно, если по нему нет счетов. Удалить товар?",
                Yes: "Да",
                No: "Нет",
                goods: "Товар",
                material: "Материал",
                semifinishedProduct: "Полуфабрикат",
                product: "Продукт",
                services:  "Услуга",
                advancePayment: "Авансовый платеж",
                advancePaymentServices: "Авансовый платеж за услуги",
                Welcomed_by: "Вас приветствует ",
                Good_afternoon:"Добрый день.",
                What_do_you_want_to_do:"Что хотите сделать?",
                Issue_an_invoice_to_a_customer:"Выписать счет клиенту",
                You_can_also_create_a_new_invoice_from_the_Documents_section:"Все закупки на склад можно создавать и редактировать из раздела \"Перемещения\"",
                Upload_incoming_invoice:"Загрузить входящий счет ",
                Upload_invoice_scan:"Загрузите скан счета в формате pdf, jpg или png",
                Upload_a_statement:"Загрузить выписку",
                Upload_pdf_or_csv:"Загрузите csv-файл или скан  в формате pdf, jpg или png",
                Send_us_documents:"Отправить нам документы",
                Contracts_annexes_and_other_documents:"Договора, приложения, и другие документы",
                Tasks:"Задачи",
                No_new_tasks:"Новых задач нет",
                Interface_language:"Язык интерфейса",
                Events_in_June:"События в июне",
                Salary_payment:"Выплата зарплаты",
                Small_checklist:"Небольшой чек-лист:",
                Has_anyone_had_a_salary_increase:"A не было ли у кого-то повышения зарлаты?",
                Is_the_account_balance_sufficient: "Достаточен ли баланс на счете для выплат?",
                Statehood_Day:"День государственности",
                Its_a_day_off_in_Slovenia:"В Словении в этот день выходной",
                Bank_statement:"Выписка из банка",
                Generate_a_bank_statement_for_June_and_upload_it_for_us:"Сформируйте банковскую выписку за июнь и загрузите ее для нас",
                Street_and_house_number: "Улица и номер дома",
                City: "Город",
                ZipCode: "Индекс",
                More: "Еще",
                Podatki_o_partnerju: "Даннные о партнере",
                Stranka: "Клиент",
                Izvajalec: "Подрядчик",
                Stranka_ali_izvajalec: "Клиент или подрядчик",
                Kontaktna_oseba: "Контактное лицо",
                Telefon: "Телефон",
                Faks: "Факс",
                Delete: "Удалить",
                Product: "Товар",
                Service: "Услуга",
                Ne_shranite_sprememb: "Отменить",
                Nov_izdani_račun: "Новый исходящий счет",
                Nov_prejeti_račun: "Новый входящий счет",
                from: "от",
                Predogled: "Предпросмотр",
                Vsa: "Все",
                Nova: "Новые",
                Zaključena : "Завершенные",
                Od_datum: "От даты",
                Do_datum: "До даты",
                Show_archived: "Показать архивные",
                Išči: "Найти",
                Sent_invoices: "Исходящие счета",
                Received_invoices: "Входящие счета",
                Vrsta_ddokumenta: "Тип документа",
                Amount: "Сумма",
                Nov_partner: "Новый контрагент",
                Unit:"Ед. изм.",
                CurrentStock:"Текущий остаток",
                Cost:"Себестоимость",
                Price:"Цена",
                Currency:"Валюта",
                Kliknite_za_razvrščanje_padajoče:"Сортировать по возорастанию",
                Kliknite_za_razvrščanje_naraščajoče:"Сортровать по убыванию",
                Prekliči_razvrščanje:"Сбросить сортировку",
                Več: "Детали",
                Naziv : 'Название',
                Davčna_Številka : 'Налоговый номер',
                Matična_Številka : 'Рег. номер',
                Naslov : 'Адрес',
                Domov : 'Главная',
                Dokumenti : 'Документы',
                Stranke :' Партнеры',
                Nastavitve :'Настройки',
                Izdelki: "Товары и услуги",
                Odjava : 'Выход',
                E_pošta : 'Email',
                Staro_geslo : 'Старый пароль',
                Novo_geslo: 'Новый пароль',
                Ime: 'Имя',
                Priimek : 'Фамилия',
                Nastavitve_uporabnika : 'Настройки пользователя',
                Nastavitve_podjetja : 'Настройки компании',
                new_product: "Новый товар",
                add_new_product: "Добавить новый товар",
                save_and_stay: "Сохранить и продолжить редактирование",
                save_and_quit: "Сохранить и вернуться к списку",
                Log_in: "Вход",
                Remember_me : "Запомнить меня",
                Forgot_password : "Забыли пароль?",
                Please_input_your_Username : "Пожалуйста, введите имя пользователя (это email)",
                Please_input_your_Password : "Пожалуйста, введите пароль",
                Please_create_your_Password : "Пожалуйста, придумайте пароль (минимум 8 знаков)",
                Please_enter_your_First_Name: "Пожалуйста, укажите Ваше имя",
                Please_enter_your_Last_Name: "Пожалуйста, укажите Вашу фамилию",
                Login : 'Логин',
                Password : "Пароль",
                Nov_dokument: "Новый счет",
                Vrsta: "Тип",
                Račun: "Cчет",
                Predračun: "Смета",
                Številka: "Номер",
                Za_naročilo: "",
                Nasprotna_stranka: "Контрагент",
                Datum_dokumenta: "Дата документа",
                Datum_plačila: "Дата платежа",
                Shraniti: "Сохранить",
                Tiskanje: "Печать",
                Ustvarite_novo_podjetje: "Новый контрагент",
                Naziv_storitva_blaga: "Товар или усулга",
                Količina : "Кол-во",
                Davek : "НДС",
                Cena : "Цена",
                Dodajte_vrstico: "Добавить позицию",
                Hvala_za_zaupanje: "Благодарим вас за доверие",
                Znesek_prosimo_plačajte_na: "Пожалуйста, перечислите сумму на",
                Ob_plačilu_navedite_referenco: "Пожалуйста, укажите ссылку при оплате",
                Skupaj : "Всего",
                Jezik_dokumenta: "Язык документа",
                Warehouse: "Склад",
                Product_name: "Название товара",
                Purchase_price: "Цена закупки",
                Movement_date: "Дата",
                Start_stock: "Остаток на момент движения",
                Stock_write_off: "Списание",
                Stock_receipt: "Поступление",
                Final_stock: "Конечный остаток"
            }       
        },
        hr: {
            translation: {
                UnitOfMeasures: "Unit Of Measures",
                Update_uom: "Update unit",
                Create_uom: "Create unit",
                Add_new_uom: "Add new unit of measure",

                Manufacturers: "Manufacturers",
                Update_manufacturer: "Update manufacturer",
                Create_manufacturer: "Create manufacturer",
                Add_new_manufacturer: "Add new manufacturer",
              
                Update_material: "Ažuriraj vrstu materiala",
                Create_material: "Stvorite vrstu materijala",
                Add_new_material: "Dodajte novu vrstu materijala",
                Update_classification: "Ažurirajte klasifikaciju",
                Create_classification: "Napravite klasifikaciju",
                Add_new_classification: "Dodajte novu klasifikaciju",
                Ustvarite_nov_dogodek: "Stvorite novi događaj",
                Ustvarite_novo_todo: "Stvoritev novu obavezu",
                up_to_5_product: "Možete dodati do pet grupa proizvoda",
                To_do: "Zadaci",
                Latest_events: "Najnoviji događaji",
                Create_new_arrival: "Kreirajte novi dolazak artikla",
                Add_new_movement: "Dodajte novi pokret",
                Other_actions: "Ostale akcije",
                New_event: "Nov događaj",
                New_todo: "Novi zadatak",
                Warehouse_from: "Skladište (od)",
                Edit_document: "Uređivanje dokumenata",
                New_document: "Novi dokumenat",
                Document_Number: "Broj dokumenta",
                Warehouse_to: "Skladište (na)",
                Order_Number: "Broj narudžbe",
                Invoice_Number: "Broj računa",
                Payment_Number: "Broj plačanja",
                Order_Date: "Datum narudžbe",
                Invoice_Date: "Datum računa",
                Payment_Date: "Datum plačanja",
                Invoice_Amount: "Iznos na računu",
                Document_Date: "Datum dokumenta",
                Tax_22_Amount: "Porez 25%",
                Tax_95_Amount: "Porez 10% ",
                Add_position: "Dodavanje pozicije",
                Create_document: "Izradite dokumenat",
                Update_document: "Ažuriranje dokumenta",
                Select_a_supplier: "Odaberite dobavljača",
                Document1: "Iznos",
                amount1: "dokumenta",
                Number1: "Količina",
                of_positions1: " pozicije",
                Supplier: "Dobavljač",
                Stock_movements: "Pokret zaliha",
                Movements_view: "Pogled na kretanje",
                Documents_view: "Pogled dokumenata",
                Add_new_document: "Dodaj nov dokumenat",
                New_purchase: "Nova kupnja",
                New_transfer: "Nov preuzimanje",
                New_sale: "Nova prodaja",
                New_writeoff: "Novi otpis",
                Stock_level: "Razina zalihe",
                Edit_warehouse_data: "Informacije o skladištu",
                Warehouse_Type: "Tip skladišta",
                Location: "Lokacija",
                Create_warehouse: "Stvori skladište",
                Update_warehouse: "Ažuriraj skladište",
                Add_new_WarehouseProject: "Dodaj novo skladište/projekt",
                To_see_warehouse: "Kliknite za pregled pojedinosti i uređivanje skladišta",
                column_header: " naslov odgovarajućeg stupca",
                and_stock_levels: " i razine zaliha",
                Project: "Projekt",
                Select_a_warehouse: "Odaberite skladište",
                Purchase: "Nabava",
                Transfer: "Prijenos",
                Sale: "Prodaja",
                Write_off: "Otpis",
                Initial_level: "Početna razina",
                Final_level: "Završna razina",
                Type: "Vrsta",
                Warehouses: "Skladišta",
                Projects: "Projekti",
                Warehouse_Name: "Ime skladišta",
                Project_Name: "Ime projekta",
                Stock_availability: "Dostupnost zaliha",
                Products_stock_movements: "Kretanje zaliha artikla",
                Edit_product_data: "Uređivanje podataka u artiklu",
                Create_product: "Stvorite proizvod",
                Update_product: "Ažuriraj izdelek",
                Unit_of_Measure: "Mjerna enota",
                VAT_Group: "Grupa DDV",
                Other_info: "Druge informacije",
                Standard: "Standard",
                Defect: "Greška",
                Stock: "Zalihe",
                Input_Method: "Način unosa",
                Control: "Nadzor",
                Is_Packaged: "Da li je pakirano",
                Decimal_Places: "Decimalna mjsta",
                Use_By_Date: "Datum korištenja",
                Account: "Račun",
                Main_Warehouse: "Glavno skladište",
                Upload_picture: "Preuzmi sliku",
                Add_new_supplier: "Dodavanje novog dobavljača",
                Create_supplier: "Stvorite novog dobavljača",
                Update_supplier: "Ažurirajte dobavljača",
                Manufacturer: "Proizvođač",
                Total_Stock: "Ukupna zaliha",
                Product_group: "Grupa artikla",
                Quality: "Kvaliteta",
                Type_Of_Material: "Vrsta materijala",
                Classification: "Klasifikacija",
                Origin: "Izvor",
                Update_group: "Ažurirajte grupu",
                Create_group: "Stvorite grupu",
                Cancel: "Otkaži",
                Add_new_product_group: "Dodajte novu grupu proizvoda",
                Description: "Opis",
                Select_a_product: "Odaberite artikel",
                Code: "Koda",
                Supplier_Code: "Koda dobavljača",
                Documents: "Dokumenti",
                Products_supplied: "Isporučeni proizvodi",
                Edit_supplier_data: "Uređivanje podataka dobavljača",
                Credentials: "Lozinka",
                Suppliers: "Dobavljači",
                Product_groups: "Grupa proizvoda",
                Stocks: "Skladišta",
                Movements: "Kretanje",
                Update_information: "Ažuriranje informacija",
                Contacts: "Kontakti",
                Sart_quantity: "Не забыть указать перевод!!!!",
                On_processing: "U obradi",
                May_still_make_changes: "Još uvijek možete napraviti podešavanje",
                Status: "Status",
                Sent_invoice: "Izdani račun",
                Received_invoice: "Prejeti račun",
                Error: "Greška",
                Osnovni_podatki: 'Osnovni podaci',
                Zgodovina_opravljenih_letov: 'Istorija narudžbi',
                Urejanje_podatkov_o_partnerju: 'Uređivanje podataka o partneru',
                Podatki_dokumenta: "Podaci o dokumentu",
                Invoice_is_booked: "Račun je u poslovnim knjigama",
                No_changes_aalowed: "Ne može se promijeniti",
                Add_new_bank_account: "Dodajte bankovni račun",
                Account_to_which_the_funds_are_to_be_paid: "Račun, na katerega se sredstva knjižijo v dobro",
                bank_account: "Bankovnin račun",
                before_print: "Spremite dokument prije ispisa",
                IbanError: "Neispravan format IBAN",
                BicError: "Neispravan format BIC",
                Prenesite_dokument: 'Prenos dokumenta',
                Prenesite_datoteko_ali_povlecite: 'Preuzmite datoteko',
                in_spusti_na_označeno_območje: 'in spustite na označeno područje',
                No_Bank_froinvoice: "Če želite ustvariti izdani račun, dodajte vaši bančne podatke ali kliknite „Ne shranite sprememb“.",
                Postcode: "Poštanski broj",
                Search_for_company: "Traženje firme",
                Done: "Završeno!",
                January: "Januar",
                February: "Februar",
                March: "Marec",
                April: "April",
                May: "Maj",
                June: "Junij",
                July: "Julij",
                August: "Avgust",
                September: "September",
                October: "Oktober",
                November: "November",
                December: "December",
                No_bank_accs: "Još niste dodali nijedan bankovni račun",
                Bank_accounts: "Bankovni računi",
                Edit_bank_account: "Upravljanje bankovnim računom",
                privacy_confirm_help: "Potrebanje vaš pristanak za obradu osobnih podataka.",
                rules_confirm_help: "Potrebno je vaš pristanak na uvjete korištenja.",
                Select_the_customer: "Navedite kupca kojem želite izdati račun",
                Specify_the_date: "NAvedite datum računa",
                Until_what_date: "Do datuma",
                at_least_one_position: "Dodajte barem jedan artikel",
                Davek_za_DDV: "Porez za PDV",
                isssued_draft: "nacrt",
                issued: "izdato",
                received_confirmed: "primljeno, potvrđeno",
                received_rejected: "primljeno, odbijeno",
                received_draft: "nacrt",
                paid: "plačeno",
                partially_paid_overdue: "djelomično plačeno, zapadlo",
                partially_paid: "djelomično plaćeno",
                unpaid_overdue: "neplačano, zapadlo",
                unpaid: "neplaćeni",
                draft: "nacrt",
                advance_payment: "avansi",
                Delete_warning: "želite to stvarno",
                Delete_text: "Stavku možete izbrisati ako za nju nema izdanih računa , izbriši stavku?",
                Yes: "Da",
                No: "Ne",
                goods: "Blago",
                material: "Material",
                semifinishedProduct: "Poluproizvodi",
                product: "Proizvod",
                services: "Usluge",
                advancePayment: "Predplačanje",
                advancePaymentServices: "Predplačanje za usluge",
                Welcomed_by: "Dobrodošli u  ",
                Good_afternoon: "Dobar dan.",
                What_do_you_want_to_do: "Šta želite uraditi",
                Issue_an_invoice_to_a_customer: "Izdati račun kupcu",
                You_can_also_create_a_new_invoice_from_the_Documents_section: "Vse nakupe v skladišče lahko ustvarite in urejate v razdelku „Gibanja“.",
                Upload_incoming_invoice: "Prenesite prejeti račun",
                Upload_invoice_scan: "Prenose iz skladišča v skladišče, pošiljke in odpise lahko opravite tudi v razdelku „Gibanja“.",
                Upload_a_statement: "Prenesite izpisk",
                Upload_pdf_or_csv: "Prenesite datoteko csv ali skenogram v formatu pdf, jpg ali png",
                Send_us_documents: "Pošljite nam dokumente",
                Contracts_annexes_and_other_documents: "Pogodbe, priloge in drugi dokumenti",
                Tasks: "Zadatki",
                No_new_tasks: "Nema novih zadataka",
                Interface_language: "Jezik",
                Events_in_June: "Događaji u juniju",
                Salary_payment: "Izplačilo plač",
                Small_checklist: "Majhen kontrolni seznam",
                Has_anyone_had_a_salary_increase: "Ali se je komu povečala plača?",
                Is_the_account_balance_sufficient: "Ali stanje na računu zadošča za izplačila?",
                Statehood_Day: "Dan državnosti",
                Its_a_day_off_in_Slovenia: "V Sloveniji je dela prost dan.",
                Bank_statement: "Bančni izpisek",
                Generate_a_bank_statement_for_June_and_upload_it_for_us: "Ustvarite bančni izpisek za junij in nam ga naložite",
                Street_and_house_number: "Ulica in kučni broj",
                City: "Mesto",
                ZipCode: "Poštanski broj",
                More: "Više",
                Podatki_o_partnerju: "Podatki o partnerju",
                Stranka: "Partner",
                Izvajalec: "Izvođač",
                Stranka_ali_izvajalec: "Stranka ili izvođać",
                Kontaktna_oseba: "Kontaktna osoba",
                Telefon: "Telefon",
                Faks: "Faks",
                Delete: "Brisanje",
                Product: "Proizvod",
                Service: "Usluga",
                Ne_shranite_sprememb: "Ne spremaj promjene",
                Nov_izdani_račun: "Izdani nov račun",
                Nov_prejeti_račun: "Primljen novi račun",
                from: "od",
                Predogled: "Pretpregled",
                Vsa: "Sva",
                Nova: "Nova",
                Zaključena: "Završeno ",
                Od_datum: "Od datum",
                Do_datum: "Do datum",
                Show_archived: "Prikaži arhivirano",
                Išči: "Traži",
                Sent_invoices: "Izdani računi",
                Received_invoices: "Primljeni računi",
                Vrsta_ddokumenta: "Vrsta ddokumenta",
                Amount: "Iznos",
                Nov_partner: "Novi partner",
                Unit: "Jedinica",
                CurrentStock: "Trenutačne zalihe",
                Cost: "Troškovi",
                Price: "Cjena",
                Currency: "Valuta",
                Kliknite_za_razvrščanje_padajoče: "Kliknite za sortiranje silazno",
                Kliknite_za_razvrščanje_naraščajoče: "Kliknite za sortiranje povečavanje",
                Prekliči_razvrščanje: "Poništi sortiranje",
                Več: "Više",
                Naziv: 'Naziv',
                Davčna_Številka: 'Porezni broj',
                Matična_Številka: 'Matični broj',
                Naslov: 'Adresa',
                Domov: 'Kuči',
                Dokumenti: 'Dokumenti',
                Stranke: ' Stranke',
                Nastavitve: 'Postavke',
                Izdelki: "Artikli",
                Odjava: 'Odjava',
                E_pošta: 'E-pošta',
                Staro_geslo: 'Stara lozinka',
                Novo_geslo: 'Nova lozinka',
                Ime: 'Ime',
                Priimek: 'Prezime',
                Nastavitve_uporabnika: 'Korisničke postavke',
                Nastavitve_podjetja: 'Nastavi tvrtku',
                new_product: "Novi artikel",
                add_new_product: "Dodavanje novog artikla",
                save_and_stay: "Spremite i nastavite sa uređivanjem",
                save_and_quit: "Spremi i idi na popis",
                Log_in: "Prijava",
                Remember_me: "Zapamti me",
                Forgot_password: "Zaboravljena lozinka?",
                Please_input_your_Username: "Unesite svoje korisničko ime (ovo je vaš e-mail)",
                Please_input_your_Password: "Unesite lozinku",
                Please_create_your_Password: "Unesite novu lozinku (najmanje 8 znakov)",
                Please_enter_your_First_Name: "Molimo, unesite svoje ime",
                Please_enter_your_Last_Name: "Molimo, unesite svoje prezime",
                Login: 'Login',
                Password: "Lozinka",
                Nov_dokument: "Novi račun",
                Vrsta: "Vrsta",
                Račun: "Račun",
                Predračun: "Predračun",
                Številka: "Broj",
                Za_naročilo: "",
                Nasprotna_stranka: "Stranka",
                Datum_dokumenta: "Datum računa",
                Datum_plačila: "Datum plačanja",
                Shraniti: "Sahranite",
                Tiskanje: "Tiskanje",
                Ustvarite_novo_podjetje: "Stvori novu tvrtku",
                Naziv_storitva_blaga: "Naziv usluge blaga",
                Količina: "Količina",
                Davek: "Porez",
                Cena: "Cjena",
                Dodajte_vrstico: "Dodajte stavku",
                ID_za_DDV: "PDV",
                Hvala_za_zaupanje: "Hvala na povjerenju",
                Znesek_prosimo_plačajte_na: "Molimo uplatite iznos na",
                Ob_plačilu_navedite_referenco: "Prilikom plaćanja navedite referencu",
                Skupaj: "Zajedno",
                Jezik_dokumenta: "Jezik dokumenta",
                Warehouse: "Skladište",
                Product_name: "Ime proizvoda",
                Purchase_price: "Nabavna cijena",
                Movement_date: "Datum",
                Start_stock: "Početna količina",
                Stock_write_off: "Otpis",
                Stock_receipt: "Prijem",
                Final_stock: "Konačno stanje"
            }

        }

    },
    lng: 'en',
    debug: true,
})
export default i18n;