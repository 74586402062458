// src/services/inventoryMovementService.ts

import axios from 'axios';
import { InventoryMovement } from '../types/InventoryMovement';

import {DATA_URL} from './auth-service'
import authHeader from "./auth-header";

const API_URL = DATA_URL+'InventoryMovements';


export const InventoryMovementService ={
    async getAll(): Promise<InventoryMovement[]> {
        const response = await axios.get<InventoryMovement[]>(API_URL, {headers: authHeader()});
        return response.data;
    },

    async getById(id: string): Promise<InventoryMovement> {
        const response = await axios.get<InventoryMovement>(`${API_URL}/${id}`, {headers: authHeader()});
        return response.data;
    },

    async create(inventoryMovement: InventoryMovement): Promise<InventoryMovement> {
        const response = await axios.post<InventoryMovement>(API_URL, inventoryMovement, {headers: authHeader()});
        return response.data;
    },

    async update(id: string, inventoryMovement: InventoryMovement): Promise<InventoryMovement> {
        const response = await axios.put<InventoryMovement>(`${API_URL}/${id}`, inventoryMovement, {headers: authHeader()});
        return response.data;
    },

    async delete(id: string): Promise<void> {
        await axios.delete(`${API_URL}/${id}`, {headers: authHeader()});
    },

    // Другие методы, например, поиск по конкретным критериям или фильтры
    async searchByProject(projectId: string): Promise<InventoryMovement[]> {
        const response = await axios.get<InventoryMovement[]>(`${API_URL}/byproject/${projectId}`, {headers: authHeader()});
        return response.data;
    },

    async getForProduct(productId?: string, startDate?: string, endDate?: string, fromwarehouseId? : string, towarehouseId? : string) {

        //const response = await axios.get(`${API_URL}/forproduct/`, {
        const response = await axios.get(`${API_URL}/forproduct/`, {
        params: {
            productId,
            startDate,
            endDate,
            fromwarehouseId,
            towarehouseId
        },headers: authHeader(),
    });
    return response.data;
    },
    
    
    // new : getCurrentStock
    // old: GetProductStock

    async getProductStock(productId: string, startDate?: string, endDate?: string) {
        const response = await axios.get(`${API_URL}/GetCurrentStock/${productId}`, {
            params: {
                startDate,
                endDate,
            },headers: authHeader()
        });
        return response.data;
    }
    
    
}

