import {Checkbox, Col, List, Row, Spin} from "antd";
import React, {useEffect, useState} from "react";
import {eventService} from "../../Services/eventService";
import {Event} from "../../types/Event";
import DisplayEvent from "./DisplayEvent";




interface EventsListtProps {
    //afterAdd? : (v:string)=>void;
    refresh? : boolean;
}

const EventsList=({ refresh}: EventsListtProps)=> {

    const [loading, setLoading] = useState<boolean>(true);

    const [events, setEvents ]=useState<Event[]|undefined>()

    useEffect(() => {
        const fetchMovements = async () => {
            setLoading(true);
            try {

                const response = await eventService.getAll();
                setEvents(response);
                console.log(response)
            } catch (error) {
                console.error('Ошибка при получении событий:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchMovements();
    }, [refresh]);



    return<Spin spinning={loading}>
        <List
            bordered={false}
            dataSource={events?.map((event:Event)=>({
                key: event.id,
                label : event.title,
                data: event
            }))}
            renderItem={(item) => <DisplayEvent event={item.data}  />
                
            }
        />
    </Spin>
}
export default EventsList;