import React, { useEffect, useState } from 'react';
import {Form, Input, Select, Button, message, Affix, Space, ConfigProvider, Divider, Row, Col} from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import {Warehouse, WarehouseType} from "../../types/Warehouse";
import {warehouseService} from "../../Services/WarehouseService";
import {RightOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import AddTodo from "../../CommonComponents/ToDo/AddTodo";
import {TodoObjectType} from "../../types/ToDo";

const { Option } = Select;

//

interface WarehouseFormProps {
    id?: string;
}
const WarehouseForm: React.FC<WarehouseFormProps> = ({id}) => {
    const [loading, setLoading] = useState(false);
    const [warehouse, setWarehouse] = useState<Warehouse | null>(null);
    //const { id } = useParams<{ id: string }>();
    const history = useHistory();

    const [form] = Form.useForm();

    const {t}=useTranslation();

    useEffect(() => {
        if (id) {
            setLoading(true);
            warehouseService.getById(id)
                .then(response => {
                    console.log("response: ", response)
                    setWarehouse(response);
                    form.setFieldsValue(response);

                })
                .catch(() => {
                    message.error('Ошибка при загрузке данных');
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [id]);

    const onFinish = async (values: Warehouse) => {
        setLoading(true);
        
        const whToUpdate:Warehouse = {
            id:id? id: "",
            name: values.name,
            location: values.location,
            warehouseType: values.warehouseType,
            code: values.code,
            capacity: 0,
            contactPerson: values.contactPerson,
            contactEmail: values.contactEmail,
            contactTel: values.contactTel,
            openHourFrom: values.openHourFrom,
            openHourTo: values.openHourTo,
            gln: values.gln
            
            
        }
        try {
            if (id) {
                await warehouseService.update(id, whToUpdate);
                message.success('The warehouse has been successfully updated');
            } else {
                await warehouseService.create(values);
                message.success('The supplier has been successfully created');
            }
            history.push('/stocks');
        } catch (error) {
            message.error('Error');
            console.log(error)
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>

            
            <ConfigProvider
                theme={{
                    components: {
                        Form: {
                            itemMarginBottom: 10
                        },
                    },
                }}
            >
            <Form
                form={form}
                layout="horizontal"
                labelCol={{ flex: '180px' }}
                labelWrap={true}

                initialValues={warehouse || { warehouseType: WarehouseType.Stock }}
                onFinish={onFinish}
            >

                <Row gutter={16}>
                    <Col span={12}>
                        <Divider orientation={"left"} orientationMargin="0">{t("Osnovni_podatki")}</Divider>


                        <Form.Item
                            label={t("Code")}
                            name="code"
                            rules={[{ required: true, message: 'Please input the code' }]}
                        >
                            <Input placeholder="Please input the code" />
                        </Form.Item>

                        <Form.Item
                            label={t("Naziv")}
                            name="name"
                            rules={[{ required: true, message: 'Please input the title' }]}
                        >
                            <Input placeholder="Please input the title" />
                        </Form.Item>
                        
                        <Form.Item
                            label={t("Warehouse_Type")}
                            name="warehouseType"
                            rules={[{ required: true, message: 'Choose the type' }]}
                        >
                            <Select defaultValue={warehouse?.warehouseType}
                            >
                                <Option value={0}>{t("Warehouse")}</Option>
                                <Option value={1}><span style={{color: "#1449af"}}>{t("Project")}</span></Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label={t("Location")} name="location">
                            <Input placeholder="Location" />
                        </Form.Item>

                        <Form.Item
                            label="GLN"
                            name="gln"
                            ///rules={[{ required: true, message: 'Please input the GLN' }]}
                        >
                            <Input placeholder="GLN" />
                        </Form.Item>



                        <Divider orientation={"left"} orientationMargin="0">{t("Contacts")}</Divider>
                        <Form.Item label={t("Kontaktna_oseba")} name="contactPerson">
                            <Input placeholder={t("Kontaktna_oseba")} />
                        </Form.Item>
                        <Form.Item label={t("Telephone")} name="contactTel">
                            <Input placeholder={t("Telephone")} />
                        </Form.Item>
                        <Form.Item label={t("E_pošta")} name="contactEmail">
                            <Input placeholder={t("E_pošta")} />
                        </Form.Item>

                    </Col>
                </Row>



                <Divider orientation={"left"} orientationMargin="0" style={{marginBottom: "60px"}}> </Divider>

                <Affix offsetBottom={0}>
                    <div className="affix-shadow-wrapper">
                        <Form.Item style={{float: "right"}}>
                            <Space>

                                {id ?

                                    <AddTodo
                                        buttonText={"Add/check to-do"}
                                        objectId={id}
                                        objectType={TodoObjectType.Warehouse}
                                        showList={true}
                                        afterAdd={(v)=>{
                                            console.log()
                                            //setRefreshTodoList(!refreshTodoList)
                                        }} />
                                    :
                                    ""
                                }

                                <Button type="primary" htmlType="submit" loading={loading} className={"OrangeButton"}>
                                    {id ? t('Update_warehouse') : t('Create_warehouse')}
                                </Button>

                                <Button onClick={()=>history.push("/stocks")}>{t("Cancel")}</Button>
                            </Space>
                        </Form.Item>

                    </div>
                </Affix>
                
                
            </Form>
            </ConfigProvider>

        </div>

    );
};

export default WarehouseForm;
