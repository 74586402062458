// components/ProductForm.tsx
import React from 'react';
import {
    Form,
    Input,
    Button,
    Select,
    InputNumber,
    Checkbox,
    Row,
    Col,
    Divider,
    ConfigProvider,
    Space,
    Card,
    Affix, message
} from 'antd';
import type { UploadProps } from 'antd';

import { Product,  } from '../../../types/Product';
import {  ProductGroup } from '../../../types/ProductGroup';
import { useEffect, useState } from 'react';
import axios from 'axios';
import {RightOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import Dragger from "antd/es/upload/Dragger";
import SupplierSelect from "../../../CommonComponents/SupplierSelect";
import {useTranslation} from "react-i18next";
import {Warehouse} from "../../../types/Warehouse";

import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import AddTodo from "../../../CommonComponents/ToDo/AddTodo";
import {TodoObjectType} from "../../../types/ToDo";
import {Classification} from "../../../types/Classification";
import {TypeOfMaterial} from "../../../types/TypeOfMaterial";
import ManufacturerSelect from "../../../CommonComponents/ManufacturerSelect";
import UnitOfMeasureSelect from "../../../CommonComponents/UnitOfMeasureSelect";




const { Option } = Select;

interface ProductFormProps {
    initialValues?: Product;
    productGroups: ProductGroup[];
    warehouses: Warehouse[];
    classifications: Classification[];
    typeOfMaterials: TypeOfMaterial[];
    onSave: (product: Product) => void;
}

const ProductForm: React.FC<ProductFormProps> = ({ initialValues, productGroups,warehouses, classifications, typeOfMaterials, onSave }) => {
    const [prod_form] = Form.useForm();

    const history = useHistory();

    console.log("initialValues:", initialValues)
    
    const {t}=useTranslation();

    const onFinish = (values: any) => {
        console.log("form values:", values)
        onSave(values);
    };

    useEffect(() => {
        if (initialValues) {
            prod_form.setFieldsValue(initialValues);
        }
    }, [initialValues, prod_form]);




    const props: UploadProps = {
        
        name: 'file',
        multiple: false,
        //action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
        onChange(info) {
            
            
            
            
            
            
            
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };
    
    

    const handleUpload = async (selectFile : any) => {
        
        console.log("selectFile: ", selectFile)
        
        const client = new S3Client({
            region: "eu-central-1", //"/** your S3 region */",
            credentials: {
                accessKeyId: "573a82f04729667a05d4b0816b32db56",
                secretAccessKey: "148562c180e465743630df6d74114c8cbff8bcaf73173ea0457e3841e3372332",
            },
        });

        const params = {
            Bucket: "kvikstock-dev",
            Key: "bw0ZooiBdF_AJn-N50RBQFRXSST8-GLK8qbJfqcr",
            Body: selectFile.file /** object body */,
        };

        const command = new PutObjectCommand(params);
        const data = await client.send(command);
        console.log(data);
    };
    
    
    const setManufacturer=(e:any)=>{
        console.log(e);
        prod_form.setFieldValue("manufacturerId", e)
    }

    const handleUnitSelect=(e:any)=>{
        console.log(e);
        prod_form.setFieldValue("unitOfMeasureId", e)
    }
    
    
    
    
    const dodatki=[1,2,3,4,5,6,7,8,9,10,11,12]

    return (
        <ConfigProvider
            theme={{
                components: {Form: {itemMarginBottom: 10},},}}>
        <Form
            form={prod_form}
            layout="horizontal"
            initialValues={initialValues}
            onFinish={onFinish}
            name={"ProdName"}
            labelCol={{ flex: '130px' }}
            labelWrap={true}
        >
            
            
            
            <div style={{paddingLeft: "20px"}}>

                <Form.Item
                    hidden={true}
                    label="Id"
                    name="id"
                    //rules={[{ required: true, message: 'Please enter the product code' }]}
                >
                    <Input  hidden={true} />
                </Form.Item>
                
            
            <Row gutter={24}>
                <Col span={16}>
                    <Divider orientation={"left"} orientationMargin="0">{t("Osnovni_podatki")}</Divider>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={t("Code")}
                                name="code"
                                //rules={[{ required: true, message: 'Please enter the product code' }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="EAN"
                                name="ean"
                               // rules={[{ required: true, message: 'Please enter the EAN' }]}
                            >
                                <Input />
                            </Form.Item>
                            
                                <Form.Item
                                    label={t("Supplier")}
                                    name="supplierId"
                                  //  rules={[{ required: true, message: 'Please enter the supplier' }]}
                                >
                                    <SupplierSelect defaultSelected={initialValues?.supplierId} />
                                    {/* 
                                    <Input placeholder={"Name"} />
                                    */}
                                   
                                    
                                    
                                </Form.Item>
                                
                           
                            <Form.Item
                                label={t("Manufacturer")}
                                name="manufacturerId"
                                //rules={[{ required: true, message: 'Please enter the manufacturer' }]}
                            >
                                <ManufacturerSelect defaultSelected={initialValues?.manufacturerId} afterSelect={(e)=>{setManufacturer(e)}} />

                                {/*   <Input />*/}
                            </Form.Item>
                            <Form.Item
                                label={t("Unit_of_Measure")}
                                name="unitOfMeasureId"
                               // rules={[{ required: true, message: 'Please select the unit of measure' }]}
                            >
                                
                                <UnitOfMeasureSelect defaultSelected={initialValues?.unitOfMeasureId}
                                                     afterSelect={(e)=>{handleUnitSelect(e)}}
                                />
                                {/* 
                                <Select>
                                    <Option value={1}>Piece</Option>
                                    <Option value={2}>Box</Option>
                                </Select>
                                */}
                            </Form.Item>
                            <Form.Item
                                label={t("VAT_Group")}
                                name="vatGroup"
                                //rules={[{ required: true, message: 'Please select the VAT group' }]}
                            >
                                <Select>
                                    <Option value={1}>Standard</Option>
                                    <Option value={2}>Reduced</Option>
                                    {/* Add other VAT groups as needed */}
                                </Select>
                            </Form.Item>




                        </Col>
                        <Col  span={12}>
                            <Form.Item
                                label={t("Naziv")}
                                name="name"
                                rules={[{ required: true, message: 'Please enter the product name' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={t("Description")}
                                name="description"
                            >
                                <Input />
                                {/* <Input.TextArea rows={4} />*/}
                            </Form.Item>
                            <Form.Item
                                label={t("Description")+" 2"} 
                                name="description"
                            >
                                <Input />
                                {/* <Input.TextArea rows={4} />*/}
                            </Form.Item>

                            <Form.Item
                                label={t("Origin")}
                                name="origin"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={t("Type_Of_Material")}
                                name="typeOfMaterial"
                            >
                                <Select>
                                    {typeOfMaterials.map(group => (
                                        <Option key={group.id} value={group.id}>
                                            {group.name}
                                        </Option>
                                    ))}
                                </Select>

                            </Form.Item>
                            <Form.Item
                                label={t("Classification")}
                                name="classification"
                            >
                                <Select>
                                    {classifications.map(group => (
                                        <Option key={group.id} value={group.id}>
                                            {group.name}
                                        </Option>
                                    ))}
                                </Select>

                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col span={8} style={{paddingLeft: "60px"}}>
                    <Divider orientation={"left"} orientationMargin="0">Picture</Divider>

                    <Dragger
                        height={240}
                        //disabled={draggerDisabled}
                        //onChange={onChange}
                        //action={uploadAction} //+'http://localhost:5000/api/Docs/CreateFromUpload?requestId=5d0bf8f2-394b-4063-a022-89272ba44d4a&objectMode=2'

                        customRequest={handleUpload}
                       // headers={authHeader()}
                        style={{minWidth: "300px"}}>
                        <Button className={"orangeButton"} //htmlType="submit"
                        >
                            + {t("Upload_picture")}
                        </Button>{/* 
                        <p className="ant-upload-text" >
                            <br/>{t("Prenesite_datoteko_ali_povlecite")}<br/> 
                            {t("in_spusti_na_označeno_območje")}<br/></p>
                        */}
                        <p className="ant-upload-hint">
                            JPG, JPEG, PNG, PDF
                        </p>
                    </Dragger>


                </Col>
            </Row>
                
                <Row gutter={24}>
                    <Divider
                        style={{marginTop: "60px"}}
                        orientation={"left"} orientationMargin="0">{t("Product_group")}</Divider>

                    <Col span={8}>
                        <Form.Item noStyle={true}
                            label={""}
                            name="productGroupId"
                            rules={[{ required: true, message: 'Please select a product group' }]}
                        >
                            <div style={{marginBottom: "20px"}}>
                                {t("up_to_5_product")}:
                                
                            </div>
                        </Form.Item>
                        
                        <Form.Item
                            label={t("Product_group")+" 1"}
                            name="productGroupId1"
                            rules={[{  message: 'Please select a product group' }]}
                        >
                            <Select>
                                {productGroups.map(group => (
                                    <Option key={group.id} value={group.id}>
                                        {group.name}
                                    </Option>
                                ))} 
                            </Select>
                        </Form.Item>


                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label={t("Product_group")+" 2"}
                            name="productGroupId2"
                            rules={[{  message: 'Please select a product group' }]}
                        >
                            <Select>
                                {productGroups.map(group => (
                                    <Option key={group.id} value={group.id}>
                                        {group.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label={t("Product_group")+" 3"}
                            name="productGroupId3"
                            rules={[{  message: 'Please select a product group' }]}
                        >
                            <Select>
                                {productGroups.map(group => (
                                    <Option key={group.id} value={group.id}>
                                        {group.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>


                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label={t("Product_group")+" 4"}
                            name="productGroupId4"
                            rules={[{  message: 'Please select a product group' }]}
                        >
                            <Select>
                                {productGroups.map(group => (
                                    <Option key={group.id} value={group.id}>
                                        {group.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label={t("Product_group")+" 5"}
                            name="productGroupId5"
                            rules={[{  message: 'Please select a product group' }]}
                        >
                            <Select>
                                {productGroups.map(group => (
                                    <Option key={group.id} value={group.id}>
                                        {group.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>


                    </Col>
                </Row>
                <Row gutter={24}>
                <Divider 
                    style={{marginTop: "60px"}}
                    orientation={"left"} orientationMargin="0">{t("Other_info")}</Divider>
                <Col span={8}>
                    <Form.Item
                        label={t("Standard")}
                        name="standard"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={t("Quality")}
                        name="quality"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={t("Defect")+" %"}
                        name="defectPercentage"
                       // rules={[{ required: true, message: 'Please enter the defect percentage' }]}
                    >
                        <InputNumber min={0} max={100} step={0.01} />
                    </Form.Item>
                    <Form.Item
                        label={t("Stock")}
                        name="stock"
                        //rules={[{ required: true, message: 'Please enter the stock quantity' }]}
                    >
                        <InputNumber min={0} />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label={t("Input_Method")}
                        name="inputMethod"
                    >
                        <Input />
                    </Form.Item>


                    <Form.Item
                        label={t("Control")+" ?"}
                        name="controlOnReceipt"
                        valuePropName="checked"
                    >
                        <Checkbox />
                    </Form.Item>
                    <Form.Item
                        label={t("Is_Packaged")}
                        name="isPackaged"
                        valuePropName="checked"
                    >
                        <Checkbox />
                    </Form.Item>





                    <Form.Item
                        label={t("Decimal_Places")}
                        name="numberOfDecimalPlaces"
                      //  rules={[{ required: true, message: 'Please enter the number of decimal places' }]}
                    >
                        <InputNumber min={0} />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label={t("Use_By_Date")}
                        name="useByDate"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={t("Account")+" 1"}
                        name="account"
                    >
                        <Input />
                    </Form.Item>
                    
                    


                <Form.Item
                        label={t("Account")+" 2"}
                        name="account2"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={t("Main_Warehouse")}
                        name="mainWarehouseId"
                    >

                        <Select>
                            {warehouses.map(group => (
                                <Option key={group.id} value={group.id}>
                                    {group.name}
                                </Option>
                            ))}
                        </Select>


                          
                    </Form.Item>




                </Col>
            </Row>
                
                <Divider />

                {/* 
            <Row gutter={24}>
                <Divider
                    style={{marginTop: "60px"}}
                    orientation={"left"} orientationMargin="0">Dodatno</Divider>
                <Col span={8}>
                    {
                        dodatki.map((i, ind)=>(
                                <Form.Item
                                    label={"Dodatek "+i}
                                    name={"dod"+i}
                                >
                                    <Input />
                                </Form.Item>
                            )
                        )
                    }
                </Col>

            </Row>
            */}
            </div>

            <Affix offsetBottom={0}>
                <div className="affix-shadow-wrapper">
                    <Form.Item style={{float: "right"}}>
                        <Space>
                            {initialValues ?

                                <AddTodo
                                    buttonText={"Add/check to-do"}
                                    objectId={initialValues.id}
                                    objectType={TodoObjectType.Product}
                                    showList={true}
                                    afterAdd={(v)=>{
                                        console.log()
                                        //setRefreshTodoList(!refreshTodoList)
                                    }} />
                                :
                                ""
                            }
                            
                        <Button 
                            onClick={(v)=>{
                                console.log("v: ", v)
                                prod_form.submit();
                                onFinish(prod_form.getFieldsValue())
                            }}
                            type="primary" htmlType="submit" onSubmit={onFinish} className={"OrangeButton"} >
                            {initialValues? t("Update_product"): t("Create_product") } 
                        </Button>

                        <Button onClick={()=>history.push("/products")}>{t("Cancel")}</Button>
                        </Space>
                    </Form.Item>
                   
                </div>
            </Affix>
        </Form>
        </ConfigProvider>
    );
};

export default ProductForm;
