import React, { useEffect, useState } from 'react';
import {Form, Input, Button, notification, Affix, Space, Divider, ConfigProvider} from 'antd';
import { productGroupService } from '../../Services/productGroupService'; // Импортируйте ваш сервис для работы с API
import { useParams, useHistory } from 'react-router-dom';
import {RightOutlined} from "@ant-design/icons";
import {ProductGroup} from "../../types/ProductGroup";
import {useTranslation} from "react-i18next";
import AddTodo from "../../CommonComponents/ToDo/AddTodo";
import {TodoObjectType} from "../../types/ToDo"; // Для получения параметров из URL и навигации


interface ProductGroupFormProps {
    //id?: string;
}

const ProductGroupForm: React.FC<ProductGroupFormProps> = ({}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
     const { id } = useParams<{ id?: string }>(); // Получение ID поставщика из URL, если он есть
    const history = useHistory(); // Для навигации
    
    const [pgroup, setPgroup] = useState<ProductGroup>()
    
    const {t}=useTranslation();


    console.log("data:")
    
    // Загрузка данных поставщика для редактирования, если есть ID
    useEffect(() => {
        if (id) {
            setLoading(true);
            productGroupService.getById(id)
                .then((data) => {
                    console.log("data:", data)
                    setPgroup(data);
                    form.setFieldsValue(data);
                })
                .catch(() => {
                    notification.error({ message: 'Ошибка загрузки данных товарной группы' });
                })
                .finally(() => setLoading(false));
        }
    }, [id, form]);

    // Обработка отправки формы
    const handleSubmit = async (values: any) => {

        console.log("values to PUT", values)
        setLoading(true);
        try {
            if (id) {
                // Обновление существующего поставщика
                await productGroupService.update(id, values);
                notification.success({ message: 'The group has been successfully updated!' });
            } else {
                // Создание нового поставщика
                await productGroupService.create(values);
                notification.success({ message: 'The group has been successfully created!' });

            }
            history.push('/groups/'); // Навигация обратно на список поставщиков
        }
            //catch (error:any) {
            //     console.log(error)
            //     notification.error({ message: 'Ошибка сохранения поставщика', description: error.message });
            //} 
        finally {
            setLoading(false);
        }
    };

    return (
        <div>


            <div>
                <h1>
                        <span style={{color: "#999" , cursor: "pointer", borderBottom: "1px dashed #999"}}  
                              
                              onClick={()=> history.push("/groups/")}>
        
                    {t("Product_groups")}</span>
                    <span style={{color: "#999"}}>
                    &nbsp;<RightOutlined size={16} style={{fontSize:"18px"}} /> &nbsp;
                </span>
                    {id ? pgroup?.name : t('Add_new_product_group')}



                </h1>
            </div>


                <ConfigProvider
                theme={{
        components: {
            Form: {
                itemMarginBottom: 10
            },
        },
    }}
>
    <Form
        form={form}
    layout="horizontal"
    labelCol={{ flex: '180px' }}
    labelWrap={true}

    onFinish={handleSubmit}
    initialValues={{ name: '', supplierCode: '', address: '', phoneNumber: '', email: '', contactPerson: '' }}
>
    

        <Form.Item
            label={t("Code")}
            name="code"
            rules={[{ required: true, message: 'Введите код ' }]}
        >
            <Input />
        </Form.Item>

        <Form.Item hidden={true} name="id">
        <Input />
        </Form.Item>
        <Form.Item
    label={t("Naziv")}
    name="name"
    rules={[{ required: true, message: 'Введите имя ' }]}
        >
        <Input />
        </Form.Item>


        <Form.Item
    label={t("Description")}
    name="description"
        >
        <Input />
        </Form.Item>

    <Divider orientation={"left"} orientationMargin="0" style={{marginBottom: "60px"}}> </Divider>


    <Affix offsetBottom={0}>
    <div className="affix-shadow-wrapper">
    <Form.Item  style={{float: "right"}}>
    <Space>

        {id ?

            <AddTodo
                buttonText={"Add/check to-do"}
                objectId={id}
                objectType={TodoObjectType.ProductGroup}
                showList={true}
                afterAdd={(v)=>{
                    console.log()
                    //setRefreshTodoList(!refreshTodoList)
                }} />
            :
            ""
        }
        
        <Button type="primary" htmlType="submit" loading={loading}  className={"OrangeButton"}>
        {id ? t('Update_group') : t('Create_group')}
        </Button>

        <Button onClick={()=>history.push("/groups")}>{t("Cancel")}</Button>
    </Space>
    </Form.Item>

    </div>
    </Affix>


    </Form>
    </ConfigProvider>
    </div>
);
};

export default ProductGroupForm;
