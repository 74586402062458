import axios from "axios";
import {useHistory} from "react-router-dom";
//import {useState} from "react";
//import {HubConnection, HubConnectionBuilder, LogLevel} from "@microsoft/signalr";
import authHeader from "./auth-header";
import tokenHeader from "./token-header";
import {message} from "antd";




export const DATA_URL = process.env.NODE_ENV === 'production'?  "https://stock.api.kvikapps.com/api/" :  "https://stock.api.dev.kvikapps.com/api/" 
//export const DATA_URL = process.env.NODE_ENV === 'production'?  "https://stock.api.kvikapps.com/api/" :  "http://localhost:3004/api/" 


//export const DATA_URL = process.env.NODE_ENV === 'production'? "https://stock.api.kvikapps.com/api/" : "http://localhost:3004/api/"  ;

//export const NOTIFICATION_URL=process.env.NODE_ENV === 'production'? "https://api.fintim.online/notifications" : "http://localhost:7500/notifications";

/////fintim
////


export const login = (username: string, password: string) => {
    return axios
        .post(DATA_URL + "Authenticate/login", {
            username,
            password,
        })
        .then((response) => {
            console.log("auth")
            if (response.data.token) {
                console.log("auth token")
                localStorage.setItem("user", JSON.stringify(response.data));

               
                
                
                
            }

            return response.data;
        });
};

export const loadUsersOrganisations =() =>{
    
    return axios
        .get(DATA_URL + "organization/", { headers: authHeader()
        })
        .then((org_response) => {
            console.log("loadUsersOrganisations org_response: ", org_response)
            localStorage.removeItem("organizations");
            localStorage.setItem("organizations", JSON.stringify(org_response.data));
        })
}



export const logout = () => {
    
    localStorage.removeItem("user");
    localStorage.removeItem("organizations");
    localStorage.removeItem("current_organization");
    localStorage.removeItem("userlanguage");
    const user = getCurrentUser();
    //console.log("authservice.tsx user: ", user);
    
};

export const getCurrentUser = () => {
    const userStr = localStorage.getItem("user");
    if (userStr) return JSON.parse(userStr);
    return null;
};

export const getUsersOrganisations = () => {
    const userStr = localStorage.getItem("organizations");

    if (typeof userStr== "undefined" ) {
        return null;
    }
    else{
        if(userStr) {
            if(userStr=="undefined"){
            }
            else{
                return JSON.parse(userStr);
            }
        }
    }
    return null;
};






export const getCurrentOrganisation = () => {
    
    const userStr = localStorage.getItem("current_organization");

        //console.log("typeof userStr: ", typeof userStr)
        if (typeof userStr== "undefined" ) {
            return null;
        }
        else{
            if(userStr) {
                if(userStr=="undefined"){
                    console.log("userStr.length: ", userStr.length, userStr)
                    
                }
                else{
                    //console.log("get current org: ", JSON.parse(userStr))
                    return JSON.parse(userStr);
                }

            } 
        }
    

    return null;
};

export const refreshUser = () => {
    return axios
        .get(DATA_URL + "Authenticate/refresh-my-user", {
            headers: authHeader()
        },)
        .then((response) => {
            if (response.data.token) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
};
