import React, { useEffect, useState } from 'react';
import {Table, Typography, Spin, Row, Col} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useParams } from 'react-router-dom';
import { productService } from '../../../Services/productService';
import { ProductStock, WarehouseStock, ProjectStock } from '../../../types/InventoryMovement';
import {Product} from "../../../types/Product";
import {useTranslation} from "react-i18next";

const { Title } = Typography;


interface SuppliersProductListProps {
    supplierId: string;
}

const SuppliersProductList: React.FC<SuppliersProductListProps> = ({supplierId}) => {
    // const { productId } = useParams<{ productId: string }>();
    const [productList, setProductList] = useState<Product[] | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const {t}=useTranslation();
    useEffect(() => {
        if (supplierId) {
            productService.getForSupplier(supplierId)
                .then((data) => {
                    setProductList(data);
                })
                .finally(() => setLoading(false));
        }
    }, [supplierId]);

    const columns: ColumnsType<Product> = [
        {
            title: t('Code'),
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: t('Product'),
            dataIndex:  'name' ,
            key: 'name',
            render:(text, record)=>{
                return <>{text}<br/>
                    <span style={{color: "#999"}}>{record.description}</span>
                </>}
        },
        {
            title: t('Product_group'),
            dataIndex: ['productGroup','name'],
            key: 'productGroup',
        },
        {
            title: t('Classification'),
            dataIndex: 'classification',
            key: 'classification',
        },
        {
            title: t('Origin'),
            dataIndex: 'origin',
            key: 'origin',
        },
        {
            title: t('Type_Of_Material'),
            dataIndex: 'typeOfMaterial',
            key: 'typeOfMaterial',
        },
        {
            title: t('Quality'),
            dataIndex: 'quality',
            key: 'quality',
        },
    ];

  

    if (loading) {
        return <Spin tip="Loading..." />;
    }

    return (
        <div>
                 
                    <Table
                        columns={columns}
                        dataSource={productList || []}
                        rowKey="warehouseId"
                        pagination={false}
                    />
        </div>
    );
};

export default SuppliersProductList;
