import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {RightOutlined} from "@ant-design/icons";
import * as AuthService from "../../Services/auth-service";
import {productGroupService} from "../../Services/productGroupService";
import {Affix, Button, Col, ConfigProvider, Divider, Form, Input, notification, Row, Space} from "antd";
import {User} from "../../types/User";
import {settingsService} from "../../Services/settingsService";
import LangSelect from "../../CommonComponents/LangSelect";
import {useTranslation} from "react-i18next";



const UserSettings: React.FC = () => {
    const {id} = useParams<{ id: string }>(); // Получаем ID товара из URL, если он есть
    const history = useHistory();

    
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    const [userData, setUserData] = useState<User>()


    // Загрузка данных поставщика для редактирования, если есть ID
    useEffect(() => {
            setLoading(true);
            settingsService.getMe()
                .then((data) => {
                    console.log("data:", data)
                    setUserData(data);
                    form.setFieldsValue(data);
                })
                .catch(() => {
                    notification.error({ message: 'Ошибка загрузки данных пользователя' });
                })
                .finally(() => setLoading(false));
    }, [form]);


    return <>
        <ConfigProvider
            theme={{
                components: {
                    Form: {
                        itemMarginBottom: 10
                    },
                },
            }}
        >
            <Form
                form={form}
                layout="horizontal"
                labelCol={{ flex: '180px' }}
                labelWrap={true}

                //onFinish={handleSubmit}
                initialValues={{ name: '', supplierCode: '', address: '', phoneNumber: '', email: '', contactPerson: '' }}
            >

                
                <Row gutter={24}>
                    
                    <Col span={8}>
                        <Divider orientation={"left"} orientationMargin="0">{t("Osnovni_podatki")}</Divider>

                        <Form.Item
                            label={t("Ime")}
                            name="firstName"
                            rules={[{ required: true, message: 'Введите код ' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item hidden={true} name="id">
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={t("Priimek")}
                            name="lastName"
                            rules={[{ required: true, message: 'Введите имя ' }]}
                        >
                            <Input />
                        </Form.Item>

                    </Col>
                    <Col span={7} offset={1}>
                        <Divider orientation={"left"} orientationMargin="0">{t("Contacts")}</Divider>
                        <Form.Item
                            label={t("E_pošta")}
                            name="email"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={t("Telefon")}
                            name="phoneNumber"
                        >
                            <Input />
                        </Form.Item>

                    </Col>
                    
                    

                </Row>
                <Row  gutter={24}>
                    <Col span={8}>
                        <Divider orientation={"left"} orientationMargin="0">{t("Credentials")}</Divider>

                        <Form.Item
                            label={t("Staro_geslo")}
                            name="oldPassword"
                            rules={[{  message: 'Введите код ' }]}
                        >
                            <Input />
                        </Form.Item>

  
                        <Form.Item
                            label={t("Novo_geslo")}
                            name="newPassword"
                            rules={[{  message: 'Введите имя ' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={t("Novo_geslo")}
                            name="newPasswordRep"
                            rules={[{  message: 'Введите имя ' }]}
                        >
                            <Input />
                        </Form.Item>



                    </Col>

                    <Col span={7} offset={1}>
                        <Divider orientation={"left"} orientationMargin="0">{t("Interface_language")}</Divider>
                        <Form.Item
                            label={t("Interface_language")}
                            name="language"
                        >
                            <LangSelect />
                        </Form.Item>

                    </Col>
                </Row>





                <Divider orientation={"left"} orientationMargin="0" style={{marginBottom: "60px"}}> </Divider>


                <Form.Item  style={{float: "left"}}>
                <Button type="primary" htmlType="submit" loading={loading}  className={"OrangeButton"}>
                    {t("Update_information")}
                </Button>
                </Form.Item>



                {/* 
                <Affix offsetBottom={0}>
                    <div className="affix-shadow-wrapper">
                        <Form.Item  style={{float: "right"}}>
                            <Space>

                                <Button type="primary" htmlType="submit" loading={loading}  className={"OrangeButton"}>
                                    {id ? 'Update group' : 'Create group'}
                                </Button>

                                <Button onClick={()=>history.push("/groups")}>Cancel</Button>
                            </Space>
                        </Form.Item>

                    </div>
                </Affix>
*/}

            </Form>
        </ConfigProvider>



    </>
    
}

export default UserSettings;

