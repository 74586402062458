// pages/ProductEdit.tsx
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import SupplierForm  from './Components/SupplierForm';
import { Supplier } from '../../types/Supplier';
import axios from 'axios';
import {message, Spin, Tabs, TabsProps} from 'antd';
import {productService} from "../../Services/productService";
import {
    RightOutlined,
    EditOutlined,
    SwapOutlined,
    TableOutlined,
    CodeSandboxOutlined,
    FileOutlined
} from '@ant-design/icons';
import {supplierService} from "../../Services/supplierService";
import SuppliersProductList from "./Components/SuppliersProductsList";
import DocumentMovements from "../Movements/DocumentMovements";
import {useTranslation} from "react-i18next";

const SupplierDetail: React.FC = () => {
    const { id } = useParams<{ id: string }>(); // Получаем ID товара из URL, если он есть
    const history = useHistory();
    const [supplier, setSupplier] = useState<Supplier | null>(null);
    const [loading, setLoading] = useState(false);

    const {t}=useTranslation();


    useEffect(() => {
        if (id) {
            setLoading(true);

            const data =  supplierService.getById(id)
                .then((response) => {
                    setSupplier(response);
                })
                .catch(() => {
                    message.error('Failed to load product');
                })
                .finally(() => {
                    setLoading(false);
                });
            
        }
    }, [id]);


    const onChange = (key: string) => {
        //console.log(key);
    };


    const operations = <>Extra Action</>;
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: t('Edit_supplier_data'),
            icon: <EditOutlined />,
            children: <SupplierForm id={id} />,
        },
        {
            key: '2',
            label: t("Products_supplied"),
            icon: <CodeSandboxOutlined />,
            children: <SuppliersProductList supplierId={id} />,
        },
        {
            key: '3',
            label: t('Documents'),
            icon: <FileOutlined />,
            children:  <DocumentMovements supplierId={id} showInDrawer={true}></DocumentMovements>  
        //<ProductForm  initialValues={product || undefined} productGroups={productGroups} onSave={handleSave}/>,
        },
    ];



    if (loading) {
        return <Spin />;
    }

    return (
        <div>
            <h1>
                <span
                    style={{color: "#999", cursor: "pointer", borderBottom: "1px dashed #999"}} onClick={()=> history.push("/settings/suppliers/")}
                >
                    {t("Suppliers")}
                </span>
                <span style={{color: "#999"}}>
                    &nbsp;<RightOutlined size={16} style={{fontSize:"18px"}} /> &nbsp;
                </span>
                {id ?  supplier?.name : t('Create_supplier')}
            </h1>
            {
                id?
                    <>
                        <Tabs /*tabBarExtraContent={operations}*/ defaultActiveKey="1" items={items} onChange={onChange} />
                    </>

                    : <SupplierForm/>
            }



        </div>
    );
};

export default SupplierDetail;
