// src/types/InventoryMovement.ts

import { Product } from './Product';
import {Warehouse} from "./Warehouse";
import {Project} from "./Project";
import {InventoryDocument} from "./InventoryDocument";

export enum InventoryMovementType {
    Purchase = 0,   // Поступление на склад
    Transfer = 1,   // Перемещение на проект
    Sale = 2,   // Возврат с проекта
    WriteOff = 3,            // Списание
}

export interface InventoryMovement {
    id?: string;                          // Уникальный идентификатор перемещения
    productId?: string;                   // Идентификатор товара
    product?: Product;                   // Связанный товар (может быть undefined, если не загружен)
    price?: number;                    // Стоимость перемещаемого товара
    quantity?: number;                    // Количество перемещаемого товара
    movementType?: InventoryMovementType; // Тип перемещения (enum)
    sourceWarehouseId?: string;            // ID склада, с которого происходит перемещение (опционально)
    destinationWarehouseId?: string;              // ID склада, на который происходит перемещение (опционально)
    projectId?: string;                  // ID проекта, для которого предназначен товар (опционально)
    issueDate?: string;                   // Дата выдачи товара
    plannedReturnDate?: string;          // Планируемая дата возврата (опционально)
    actualReturnDate?: string;           // Фактическая дата возврата (опционально)
    supplierId? : string;
    supplier? : any;
    positionInDocument?: number;
    description? : string;
}





export interface StockMovementDto {
    product?: Product;
    productId?: Product;
    movementDate: string; // ISO Date string
    sourceWarehouse?: Warehouse;
    destinationWarehouse?: Warehouse;
    initialQuantityOnSourceWarehouse?: number;
    finalQuantityOnSourceWarehouse?: number;
    initialQuantityOnDestinationWarehouse?: number;
    finalQuantityOnDestinationWarehouse?: number;
    price?: number;                    // Стоимость перемещаемого товара
    quantity: number;
    movementType: InventoryMovementType;
    project?: Project;
    initialQuantityOnProject?: number;
    finalQuantityOnProject?: number;
    plannedReturnDate?: string; // ISO Date string
    actualReturnDate?: string; // ISO Date string
    document?: InventoryDocument | null;
    supplierId? : string;
    supplier? : any;
    positionInDocument?: number;
    description? : string;

}



export interface ProductStock {
    warehouseStock: WarehouseStock[];
    projectStock: ProjectStock[];
}

export interface WarehouseStock {
    warehouseId: string;
    warehouseName: string;
    quantity: number;
}

export interface ProjectStock {
    projectId: string;
    projectName: string;
    quantity: number;
}
