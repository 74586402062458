import {Checkbox, Col, List, Row, Typography} from "antd";
import React, {useState} from "react";
import {ToDo, TodoObjectType} from "../../types/ToDo";
import {todoService} from "../../Services/todoService";
import {DeleteOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";


interface DisplayTodoProps {
    todo: ToDo,
    hidelink? : boolean
}

const DisplayTodo=({todo, hidelink} : DisplayTodoProps)=> {

    
    const [isDeleted, setIsDeleted] = useState(false)
    const [isDone, setIsDone] = useState(todo.isFinished)
    
    const history = useHistory();
    const onChange=async (e: any)=>{
        const response= await todoService.update(todo.id,
            {
                id: todo.id,
                title: todo.title,
                objectId: todo.objectId,
                objectType: todo.objectType,
                isFinished : e.target.checked
            });
        setIsDone(e.target.checked);
    }

    const hendleDelete=async (e: any)=>{
        const response= await todoService.delete(todo.id);
        setIsDeleted(true)
    }
  
    
    const makeLinkUrl=(objectType?: TodoObjectType)=>{
        
        if(objectType===TodoObjectType.InventoryDocument){
            return "stockmovement/"+todo.objectId+"/0"
        }
        if(objectType===TodoObjectType.Supplier){
            return "settings/suppliers/edit/"+todo.objectId
        }
        if(objectType===TodoObjectType.ProductGroup){
            return "groups/edit/"+todo.objectId
        }
        if(objectType===TodoObjectType.Product){
            return "products/"+todo.objectId
        }
        if(objectType===TodoObjectType.Warehouse){
            return "stocks/edit/"+todo.objectId
        }
        
        
        return "";
    }
    

    
    return <>{!isDeleted ?
        <List.Item key={todo.id}>
        <Row gutter={15} style={{width: "100%"}}>
            <Col span={20} style={{textDecoration: isDone? "line-through": "none"}}>
                {todo.title}
                {todo.objectId? 
                    <>
                    {!hidelink ?
                        <>
                        <br/>
                        <Typography.Link onClick={()=>{history.push(makeLinkUrl(todo.objectType))}} >Link</Typography.Link>
                        </>
                        : ""
                    }
                    </>
                    
                    :""}
            </Col>
            <Col span={2}>
                <Checkbox onChange={(e)=>onChange(e)} defaultChecked={todo.isFinished}  value={todo.isFinished}/>
            </Col>
            <Col span={2}>
                {isDone ? 
                <DeleteOutlined onClick={hendleDelete} /> 
                    :
                    ""}
                
                
            </Col>
        </Row>
    </List.Item> : ""}</>
}
export default DisplayTodo;