import axios from 'axios';
import {Warehouse, WarehouseWithQuantity} from '../types/Warehouse';
import {DATA_URL} from './auth-service'
import authHeader from "./auth-header";

const API_URL = DATA_URL+'warehouses';

export const warehouseService = {
    async getAll(): Promise<Warehouse[]> {
        const response = await axios.get<Warehouse[]>(API_URL, {headers: authHeader()});
        return response.data;
    },
    
    
    async getWarehouseWithQuantity(productId?: string, warehouseId? : string, hideEmpty? : boolean): Promise<WarehouseWithQuantity[]>{
        
        const response = await axios.get<WarehouseWithQuantity[]>(`${API_URL}/stock-table/`,
            {params:{
                    productId,
                    warehouseId,
                    hideEmpty
            }, headers: authHeader()}
            );
        return response.data;
    },

    async getById(id: string): Promise<Warehouse> {
        const response = await axios.get<Warehouse>(`${API_URL}/${id}`, {headers: authHeader()});
        return response.data;
    },

    async create(warehouse: Warehouse): Promise<Warehouse> {
        const response = await axios.post<Warehouse>(API_URL, warehouse, {headers: authHeader()});
        return response.data;
    },

    async update(id: string, warehouse: Warehouse): Promise<void> {
        await axios.put(`${API_URL}/${id}`, warehouse, {headers: authHeader()});
    },

    async delete(id: string): Promise<void> {
        await axios.delete(`${API_URL}/${id}`, {headers: authHeader()});
    },
};
