import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {RightOutlined} from "@ant-design/icons";
import * as AuthService from "../../Services/auth-service";
import {ProductGroup} from "../../types/ProductGroup";
import {supplierService} from "../../Services/supplierService";
import {Supplier} from "../../types/Supplier";
import {ColumnsType} from "antd/es/table";
import {Button, Col, Input, Row, Table} from "antd";
import {productGroupColumns} from "../ProductGroups/productGroupColumns";
import ProductSelect from "../../CommonComponents/ProductSelect";
import WarehouseSelect from "../../CommonComponents/WarehouseSelect";
import {useTranslation} from "react-i18next";



interface SuppliersProps {
    productId: string;
    warehouseId? : string;
    projectId? : string;
}

const SuppliersList: React.FC<SuppliersProps> = ({ productId}) => {
    const {id} = useParams<{ id: string }>(); // Получаем ID товара из URL, если он есть
    const history = useHistory();



    const [suppliers, setSuppliers] = useState<Supplier[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    
    const {t} = useTranslation();
    
    
    
    
    useEffect(() => {
        const fetchProductGroups = async () => {
            try {
                const data = await supplierService.getAll();
                console.log(data)
                setSuppliers(data);
            } catch (error) {
                console.error('Failed to fetch suppliers:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchProductGroups();
    }, []);

    const columns: ColumnsType<Supplier> = [

        {
            title: t('Code'),
            dataIndex: 'supplierCode',
            key: 'code',
        },
        {
            title: t('Naziv'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('Naslov'),
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: t('Kontaktna_oseba'),
            dataIndex: 'contactPerson',
            key: 'contactPerson',
        },
        {
            title: t("Telefon"),
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
        },
    ];


    const [selectedProductId, setSelectedProductId] = useState(productId)
 


    const handleProductSelect = (v:string)=>{
        setSelectedProductId(v);
    }
    

    const { Search } = Input;




    return <>
        <h1>
            {t("Suppliers")}
        </h1>
        <Row gutter={24}>

            <Col span={4}>
                {productId ?
                    ""
                    :
                    <ProductSelect defaultSelected={selectedProductId} afterSelect={(v)=>handleProductSelect(v)} />
                    //<Select style={{width: "100%"}} placeholder={"Select a product"} options={[{label: "fsfsdf", value: "1"}]} />
                }
            </Col>
            <Col span={4}>
                <Search
                    style={{width: "100%"}}
                    // addonAfter={"search"} 
                    placeholder={t("Išči")}/>
            </Col>
            <Col span={4}>
                <Button className={"OrangeButton"} onClick={()=>{history.push("/settings/suppliers/new")}}>{t("Add_new_supplier")}</Button>
            </Col>

        </Row>
        <Table
            columns={columns}
            dataSource={suppliers}
            rowKey="id"
            loading={loading}
            pagination={false}
            scroll={{ y: 'calc(63vh)'}}

            onRow={(record, rowIndex) => {
                return {
                    onClick: (event) => {history.push("/settings/suppliers/edit/"+record.id)},
                    // click row
                };
            }}
        />
    </>

}

export default SuppliersList;

