import {Product} from "./Product";

export enum WarehouseType {
    Stock = 'Stock',    // Обычный склад
    Project = 'Project' // Склад, связанный с проектом
}


export interface Warehouse {
    id: string;           // Уникальный идентификатор склада
    name: string;        // Название склада
    location?: string;   // Местоположение склада (опционально)
    warehouseType: WarehouseType;
    code?: string;
    contactPerson?: string;
    contactTel?: string;
    contactEmail?: string;
    capacity?: number;
    openHourFrom?: string;
    openHourTo?: string;
    gln?: string;
    
}


export interface WarehouseWithQuantity{
    productName? : string;
    product? : Product;
    stockByWarehouse? : {
        quantity? : number;
        warehouse: Warehouse,
    }[]
}
