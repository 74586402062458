import {Warehouse} from "./Warehouse";
import {Supplier} from "./Supplier";
import {InventoryMovement, StockMovementDto} from "./InventoryMovement";

export enum InventoryDocumentType {
    Prevzemnica = 0,   // Поступление на склад
    Prenosnica = 1,   // Перемещение на проект
    Prodaja=2,   // Документ о продаже товаров
    Odpis=3     // Документ о списании
}



export interface InventoryDocument {
    id?: string;
    documentNumber?: string; // Номер документа
    documentDate: string; // Дата документа

    documentType: InventoryDocumentType; // Тип документа (prevzemnica или prenosnica)

    sourceWarehouseId?: string; // Склад, с которого происходит перемещение
    sourceWarehouse?: Warehouse;

    destinationWarehouseId?: string; // Склад, на который происходит перемещение
    destinationWarehouse?: Warehouse;

    supplierId?: string;
    supplier?: Supplier;

    // Новые поля
    orderNumber?: string;            // Номер заказа
    orderDate?: string;              // Дата заказа
    invoiceNumber?: string;          // Номер счет-фактуры
    invoiceDate?: string;            // Дата счет-фактуры
    paymentInvoiceNumber?: string;   // Номер счета для оплаты
    paymentDate?: string;            // Дата платежа по счету для оплаты
    invoiceAmount?: number;          // Сумма счета
    tax22Amount?: number;            // Сумма налога 22%
    tax9_5Amount?: number;           // Сумма налога 9.5%
    description?: string;            // Описание
    
    items? : InventoryMovement[]
}
