import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import ProductSelect from "../../CommonComponents/ProductSelect";
import {Button, Col, Form, Input, InputNumber, Row, Select, Space} from "antd";
import {CloseCircleOutlined, SwapOutlined} from "@ant-design/icons";
import WarehouseSelect from "../../CommonComponents/WarehouseSelect";
import {InventoryMovement, InventoryMovementType} from "../../types/InventoryMovement";
import {Product} from "../../types/Product";
import {useTranslation} from "react-i18next";

interface IncomingDocPositionProps {
    rowkey? : string;
    quantity? : number;
    movement?: InventoryMovement;
    deletePos? : (i: number)=>void;
    afterChange? : (k?:string,m?:any)=>void;
    rerender? : boolean;
    //warehouseId? : string;
    //quantity? : number;
}

const IncomingDocPosition: React.FC<IncomingDocPositionProps> 
    = ({rowkey,   movement, afterChange, deletePos, rerender},) => {
    
    const[selectedProductId, setSelectedProductId ] = useState<string|undefined>(movement?.productId)
    const[selectedDestinationWarehouseId, setSelectedDestinationWarehouseId ] = useState<string|undefined>(movement?.destinationWarehouseId)

    
    const {t}=useTranslation();
    
    
    const history = useHistory();
    const [form] = Form.useForm();
    
    
    //console.log("incoming vals: ", rowkey, quantity, movement)

    
    useEffect(()=>{
        
        setSelectedProductId(movement?.productId)
        setSelectedDestinationWarehouseId(movement?.destinationWarehouseId)


        
        form.setFieldValue("quantity", movement?.quantity)
        form.setFieldValue("price", movement?.price)
        
        console.log("useEffect : ",movement)
    }, [rerender, movement])
    
     

    
    const handleDelete=(i: number)=>{
        if(deletePos){
            deletePos(parseInt(rowkey? rowkey : "-5"));
        }
    }
    
    
    const handleProductSelect=(id: string)=>{
        setSelectedProductId(id)
        handleFormChange(id, selectedDestinationWarehouseId)
    }


    const handleWarehouseSelect=(id: string)=>{
        setSelectedDestinationWarehouseId(id)
        handleFormChange(selectedProductId, id)
    }


    const handleFormChange = (productId?: string, warehouseId?: string) =>{
        console.log("quantit", form.getFieldValue("quantity"))
        
        if(afterChange){
            afterChange(rowkey, {
                    id: movement ? movement.id : undefined,
                    productId: productId ? productId.toString() : selectedProductId,
                    quantity: form.getFieldValue("quantity"),
                    price: form.getFieldValue("price"),
                    movementType: "0",
                    sourceWarehouseId: "0", //в рамках позиции склад-источник никогда не меняется. Он жестко прописывается из документа
                    destinationWarehouseId: warehouseId ? warehouseId.toString() : selectedDestinationWarehouseId,
                    projectId: "0",
                    issueDate: "0",
                    plannedReturnDate: undefined,
                    actualReturnDate: undefined,
                    positionInDocument: movement?.positionInDocument,
                    description: movement?.description
            })
        }
        
         
    }
    
    return <>
        <Form
            name={rowkey}
            form={form}
            onChange={(v)=>handleFormChange(selectedProductId, selectedDestinationWarehouseId)}
            
            layout={"vertical"}
        >
            <Row gutter={5}>
                <Col span={1}>
                    <Form.Item label="">
                        {movement?.positionInDocument}
                    </Form.Item>
                </Col>
               <Col span={movement?.movementType===0? 4 : 7}>
                   <Form.Item label="" name="productId">
                       <ProductSelect afterSelect={(v: string) => handleProductSelect(v)} defaultSelected={selectedProductId}  />
                   </Form.Item>
               </Col>
                {movement?.movementType===0?
                    <Col span={3}>
                        <Form.Item label="" name="warehouseId">
                            <WarehouseSelect afterSelect={(v: string) => handleWarehouseSelect(v)}  defaultSelected={selectedDestinationWarehouseId} />
                        </Form.Item>

                    </Col>

                    :
                    ""
                }
                <Col span={2}>
                    <Form.Item
                        label=""
                        name="quantity"
                    >
                        <InputNumber style={{width: "100%"}} placeholder={t("Količina")}  step={0.01} />
                    </Form.Item>
                    
                </Col>
                {/* 
                <Col span={2}>
                    <Form.Item
                        label=""
                        name="sourceWarehouseId"
                    >
                        <Select placeholder="UoM" options={[
                            {value:"1", label:"kg"}
                        ]} />
                    </Form.Item>
                </Col>
                */}
                <Col span={3}>
                    <Form.Item
                        label=""
                        name="Konto"
                    >
                        <Input placeholder={"Konto"} />
                    </Form.Item>
                </Col>
                <Col span={2}>
                    <Form.Item
                        label=""
                        name="price"
                        //rules={[{  message: 'Please enter the defect percentage' }]}
                        
                    >
                       
                        <InputNumber style={{width: "100%"}} placeholder={t("Price")} step={0.01} />
                       
                    </Form.Item>

                </Col>

                <Col span={3}>
                    <Form.Item
                        label=""
                        name="vrednost"
                    >
                        <Input placeholder={t("Quality")} />
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Form.Item
                        label=""
                        name="inputMethod"
                    >
                        <Space>
                            <Input placeholder={""} />
                            <CloseCircleOutlined style={{color: "red", cursor: "pointer"}} onClick={()=>handleDelete(0)}  />

                        </Space>
                    </Form.Item>
                </Col>

            </Row>

        </Form>
    </>

}

export default IncomingDocPosition