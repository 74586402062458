import {Card, Checkbox, Col, List, Row, Typography} from "antd";
import React, {useState} from "react";
import {Event,} from "../../types/Event";
import {eventService} from "../../Services/eventService";
import {DeleteOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import dayjs from "dayjs";


interface DisplayTodoProps {
    event: Event,
    hidelink? : boolean
}

const DisplayTodo=({event, hidelink} : DisplayTodoProps)=> {


    const [isDeleted, setIsDeleted] = useState(false)
   
    const history = useHistory();


    const hendleDelete=async (e: any)=>{
        const response= await eventService.delete(event.id);
        setIsDeleted(true)
    }


 

    return <>{!isDeleted ?
        <List.Item style={{display: "block"}}>
            <Card style={{width: "100%"}} 
                  title={dayjs(event.eventDate).format("DD.MM.YYYY")}
                  extra={ <DeleteOutlined onClick={hendleDelete} />}>

                {event.title}
            </Card>
        </List.Item>
        : "" }
        
 </>
}
export default DisplayTodo;