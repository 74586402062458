import React, {useState, useEffect} from "react"
import {supplierService} from "../Services/supplierService";
import {Select, Spin} from "antd";
import {Supplier} from "../types/Supplier";
import {useTranslation} from "react-i18next";

interface SupplierSelectProps {
    defaultSelected? : string
    afterSelect? : (v:string)=>void;
}

const SupplierSelect=({defaultSelected, afterSelect}: SupplierSelectProps)=>{


    const [suppliers, setSuppliers ]=useState<Supplier[]|undefined>()
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedValue, setSelectedValue] = useState(defaultSelected);
    
    const {t}=useTranslation();

    useEffect(() => {
        const fetchSuppliers = async () => {
            setLoading(true);
            try {
                const response = await supplierService.getAll();
                setSuppliers(response);
            } catch (error) {
                console.error('Ошибка при получении поставщиков:', error);
            } finally {
                setLoading(false);
            }
        };
        setSelectedValue(defaultSelected)
        fetchSuppliers();
    }, [defaultSelected]);

    const handleSelect=(value: string)=>{
        setSelectedValue(value)
        if(afterSelect){
            afterSelect(value)
        }
    }

    return <Spin spinning={loading}>
        <Select
            allowClear={true}
            showSearch={true}
            style={{width: "100%"}} placeholder={t("Select_a_supplier")}
            value={selectedValue}
            options={suppliers?.map((prod)=>({label: prod.name, value: prod.id, code: prod.supplierCode}))}


            filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())||
                (option?.code ?? '').toLowerCase().includes(input.toLowerCase())
            }



            optionRender={(option) => (<div>
                <span style={{fontSize: "10px", color: "#999"}}>{option.data.code}</span><br/>
                <span>{option.data.label}</span>
            </div>)}


            onChange={handleSelect}

        />
    </Spin>



}
export default SupplierSelect