// src/pages/ProductGroupListPage.tsx
import React, { useState, useEffect } from 'react';
import {useHistory, useParams} from "react-router-dom";
import {Button, Col, Input, Row, Table} from 'antd';
import { ProductGroup } from '../../types/ProductGroup';
import { productGroupService } from '../../Services/productGroupService';
import {RightOutlined} from "@ant-design/icons";
import ProductSelect from "../../CommonComponents/ProductSelect";
import {ColumnsType} from "antd/es/table";
import {useTranslation} from "react-i18next";

const ProductGroupListPage: React.FC = () => {
    const [productGroups, setProductGroups] = useState<ProductGroup[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    
    const [searchText, setSearchText] = useState("")
    const history = useHistory();

    const {t}=useTranslation();
    useEffect(() => {
        const fetchProductGroups = async () => {
            try {
                const data = await productGroupService.getAll();
                setProductGroups(data);
                console.log(data)
            } catch (error) {
                console.error('Failed to fetch product groups:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchProductGroups();
    }, []);



    const productGroupColumns: ColumnsType<ProductGroup> = [

        {
            title: t('Code'),
            dataIndex: 'code',
            key: 'code',
            filteredValue :[searchText],
            onFilter: (value, record) => {
                //console.log("value: ", value)
                return (
                    String(record.name).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.code).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.description).toLowerCase().includes(value.toString().toLowerCase())

                )
            },

        },

        {
            title: t('Naziv'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('Description'),
            dataIndex: 'description',
            key: 'description',
        },
    ];
    


    const { Search } = Input;


    return (
        <div>
            <h1>
                {t("Product_groups")}
            </h1>
            <Row gutter={24}>

                <Col span={4}>
                    <Search
                        style={{width: "100%"}}
                        onChange={(v)=>setSearchText(v.target.value)}
                        // addonAfter={"search"} 
                        placeholder={"Išči"}/>
                </Col>
                <Col span={4}>
                    <Button className={"OrangeButton"} onClick={()=>{history.push("/groups/new")}}>{t("Add_new_product_group")}</Button>
                </Col>

            </Row>

            <Table
                columns={productGroupColumns}
                dataSource={productGroups}
                rowKey="id"
                loading={loading}
                pagination={false}
                scroll={{y: 'calc(60vh)'}}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {history.push("/groups/edit/"+record.id)},
                        // click row
                    };
                }}

            />
        </div>
    );
};

export default ProductGroupListPage;
