import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Col, Input, message, notification, Row, Spin, Table} from 'antd';
import {ColumnsType} from 'antd/es/table';
import {Warehouse, WarehouseType, WarehouseWithQuantity} from "../../types/Warehouse";
import {warehouseService} from "../../Services/WarehouseService";
import ProductSelect from "../../CommonComponents/ProductSelect";
import WarehouseSelect from "../../CommonComponents/WarehouseSelect";
import {useHistory} from "react-router-dom";
import {EditOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {getCurrentUser} from "../../Services/auth-service";
import * as AuthService from "../../Services/auth-service";

interface ProductStockTableProps {
    productId?: string;
    warehouseId? : string;
    ishideEmpty? : boolean
}

const ProductStockTable: React.FC<ProductStockTableProps> = ({ productId
                                                                 , warehouseId, ishideEmpty
}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<WarehouseWithQuantity[]>([]);
    const [columns, setColumns] = useState<ColumnsType<any>>([]);


    const [selectedProductId, setSelectedProductId] = useState(productId)
    const [selectedWarehouseId, setSelectedWarehouseId] = useState(warehouseId)

    const [hideEmpty, setHideEmpty] = useState(ishideEmpty)

    
    const history = useHistory();
    
    const {t}=useTranslation();
    
    const user=getCurrentUser();
    if(!user){
        history.push("/login")
    }




    useEffect(() => {
        fetchData();
    }, [selectedProductId, selectedWarehouseId, hideEmpty]);

    const fetchData = async () => {
       
        setLoading(true);
        try {
            const result = await warehouseService.getWarehouseWithQuantity(selectedProductId, selectedWarehouseId, hideEmpty);
            setData(result);
            //console.log(result)
            generateColumns(result);
        } catch (error:any) {
            message.error('Ошибка при загрузке данных');
            if(error.response.status===401){
                AuthService.logout();
                history.push("login")
            }
        } finally {
            setLoading(false);
        }
    };

    const generateColumns = (data: WarehouseWithQuantity[]) => {
        const warehouseSet = new Set<Warehouse>();

        const warehouseU:  Warehouse[]=[];
        console.log("data: ", data)
        // Сбор уникальных складов для колонок
        data.forEach(item => {
            item.stockByWarehouse?.forEach(stock => {
                if(!warehouseU.some(w=>w.id===stock.warehouse.id)){
                    warehouseSet.add(stock.warehouse);
                    warehouseU.push(stock.warehouse);
                    
                }
            });
        });
        console.log("warehouseSet: ", warehouseSet)
        

        const dynamicColumns = Array.from(warehouseSet).map((wareh) => ({
            title: 
                <>
                    {warehouseId? "" :
                        <div
                            className={"tableHeaderLink"}
                            onClick={()=>history.push("/stocks/edit/"+wareh.id)}
                            style={{width: "100%", textAlign: "right", color: parseInt(wareh.warehouseType)===0? "#000": "#1449af"}}>
                            <EditOutlined className={"ticon"} />
                            {(wareh.code? wareh.code : "#-")+" "+wareh.name}
                        </div>
                    
                    }
                </>
 ,
            dataIndex: wareh.name,
            width: 200,
            key: wareh.name,
            render: (_: any, record: WarehouseWithQuantity) => {
                const stock = record.stockByWarehouse?.find(stock => stock.warehouse.name === wareh.name);
                return <div style={{width: "100%", textAlign: "right"}}>
                    {stock ? stock.quantity || <span style={{color: "#bbb"}}>0</span> : <span style={{color: "#bbb"}}>0</span>}
                </div>
            },
        }));

        setColumns([
            {
                title: t('Product'),
                dataIndex: 'productName',
                key: 'productName',
                width: 400,
                fixed: 'left',
                render: (text, record)=>{
                    return <div>
                        <span style={{color: "#999"}}>{record.product.code}</span>
                        {" "+text}<br/>
                        <span style={{color: "#999"}}>{record.product.description}</span>
                    </div>
                }
            },
            ...dynamicColumns,
            {
                title: '',
                key: 'dummy'
            }
        ]);
    };


    const handleProductSelect = (v:string)=>{
        
        setSelectedProductId(v);
        if(v){
            localStorage.setItem("warehouse_filter_productId", v);
        }
        else{
            localStorage.removeItem("warehouse_filter_productId")
        }
        
         
    }
    
    const handleHideEmpty=(v:any)=>{
        //console.log(v.target.checked);
        setHideEmpty(v.target.checked)
    }

    const handleWarehouseSelect=(v: string)=>{
        
        setSelectedWarehouseId(v)
        if(v){
            localStorage.setItem("warehouse_filter_warehouseId", v);
        }
        else{
            localStorage.removeItem("warehouse_filter_warehouseId")
        }
        
         
    }

    const { Search } = Input;

    return (
        <div>
            {warehouseId ?
                <Row>
                    <Checkbox
                        style={{marginTop: "5px"}}
                        onChange={(e)=>handleHideEmpty(e)}>Hide empty</Checkbox>
                </Row>
                
                : 
            <>
                <h1>{t("Warehouses")} <span style={{color: "#000"}}>{t("and_stock_levels")}</span> </h1>
                <Row gutter={24}>


                    <Col span={4}>


                        {productId ?
                            ""
                            :
                            <ProductSelect defaultSelected={selectedProductId} afterSelect={(v)=>handleProductSelect(v)} />
                        }
                    </Col>
                    <Col span={4}>
                        <WarehouseSelect  defaultSelected={selectedWarehouseId} afterSelect={(v)=>{handleWarehouseSelect(v)}} />
                    </Col>

                    <Col span={4}>
                        
                        <Checkbox
                            style={{marginTop: "5px"}}
                            onChange={(e)=>handleHideEmpty(e)}>Hide empty</Checkbox>
                        {/* 
                        <Search
                            style={{width: "100%"}}
                            placeholder={"Search text on page"}/>
                            */}
                    </Col>
                    <Col span={4}>
                        <Button className={"OrangeButton"} onClick={()=>{history.push("/stocks/create")}}>{t("Add_new_WarehouseProject")}</Button>
                    </Col>


                </Row>
                <Row>
                    <Col span={24}>
                        <div style={{color: "#999", height: "20px", margin: "15px 0px"}}>{t("To_see_warehouse")} <b>{t("column_header")}</b></div>
                    </Col>
                </Row>
            </>
            
            
            }

          
            <Row>
                <Col span={24}>
                    {loading ? <Spin /> :
                        <Table
                            dataSource={data}
                            columns={columns}
                            scroll={{ y: 'calc(53vh)',  x: warehouseId? 0 : 1300}}
                            rowKey="productName"  //stocks/edit/                 ;
                            pagination={false}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: (event) => {
                                        !warehouseId ? 
                                        notification.info(
                                            { 
                                                placement: "bottom",
                                                message: <>
                                                    {t("To_see_warehouse")} <b>{t("column_header")}</b> 
                                                </>})
                                    : console.log();
                                    },
                                    
                                };
                            }}



                        />
                    }
                    
                </Col>
            </Row>
        </div>
    );
};

export default ProductStockTable;
