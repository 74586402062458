export enum TodoObjectType {
    InventoryDocument = 0,
    InventoryMovement = 1,
    Organisation = 2,
    Product = 3,
    ProductGroup = 4,
    Supplier = 5,
    Warehouse = 6
}

export interface ToDo {
    id?: string;
    title?: string;
    objectId?: string;
    objectType?: TodoObjectType;
    isFinished? : boolean;
}