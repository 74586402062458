// src/services/productService.ts
import axios from 'axios';
import { Manufacturer } from '../types/Manufacturer';
import {DATA_URL} from './auth-service'
import authHeader from "./auth-header";

const API_URL = DATA_URL+'manufacturer';

export const manufacturerService = {
    // Получение всех продуктов
    async getAll(): Promise<Manufacturer[]> {
        const response = await axios.get<Manufacturer[]>(API_URL, {headers: authHeader()});
        return response.data;
    },



    // Получение продукта по ID
    async getById(id: string): Promise<Manufacturer> {
        const response = await axios.get<Manufacturer>(`${API_URL}/${id}`, {headers: authHeader()});
        return response.data;
    },

    // Создание нового продукта
    async create(manufacturer: Manufacturer): Promise<Manufacturer> {
        const response = await axios.post<Manufacturer>(API_URL, manufacturer, {headers: authHeader()});
        return response.data;
    },

    // Обновление существующего продукта
    async update(id: string, manufacturer: Manufacturer): Promise<void> {
        await axios.put(`${API_URL}/${id}`, manufacturer, {headers: authHeader()});
    },

    // Удаление продукта
    async delete(id?: string): Promise<void> {
        await axios.delete(`${API_URL}/${id}`, {headers: authHeader()});
    },



};
