import React, {useState, useEffect} from "react"
import {Divider} from "antd";


const Clock=()=>{
    
    var [date, setDate] = useState(new Date())
    
    useEffect(()=>{
        var timer = setInterval(()=>setDate(new Date()), 1000)
        return function cleabUp() {
            clearInterval(timer)
        }
        
    })
    return<>
        <div style={{minWidth: "100%"}}>
            <div style={{width: "210px", margin: "0 auto"}}>
                <div style={{fontSize: "56px", color: "#1449af", display: "inline-block"}}>{date.toLocaleTimeString().substring(0, 5)}</div>
                <div style={{ display: "inline-block", verticalAlign: "top", fontSize: "24px", color: "#999", padding: "8px"}}>{date.toLocaleTimeString().substring(5)}</div>
                <Divider/>
                <div style={{fontSize: "16px", color: "#e25e1e", display: "block", width: "200px", textAlign: "center"}}>{date.toDateString()}</div>
            </div>


        </div>




    </>
}

export default Clock
