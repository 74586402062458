import React, { useEffect, useState } from 'react';
import {Form, Input, Button, notification, Affix, Space, Divider, ConfigProvider, Dropdown, MenuProps} from 'antd';
import { productGroupService } from '../../Services/productGroupService'; // сервис для работы с API
import { useParams, useHistory } from 'react-router-dom';
import {RightOutlined} from "@ant-design/icons";
import {ProductGroup} from "../../types/ProductGroup";
import {useTranslation} from "react-i18next";
import AddTodo from "../../CommonComponents/ToDo/AddTodo";
import {TodoObjectType} from "../../types/ToDo";
import {Manufacturer} from "../../types/Manufacturer";
import {manufacturerService} from "../../Services/manufacturerService";
import {inventoryDocumentService} from "../../Services/inventoryDocumentService";
import * as AuthService from "../../Services/auth-service"; // Для получения параметров из URL и навигации


interface ManufacturerFormProps {
    embeddedCreate? : boolean;
    closeEmbeded? : (v?:any)=>void;

}

const ManufacturerForm: React.FC<ManufacturerFormProps> = ({embeddedCreate, closeEmbeded}) => {
    const [man_form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const { id } = useParams<{ id?: string }>(); // Получение ID поставщика из URL, если он есть
    const history = useHistory(); // Для навигации

    const [manufacturer, setManufacturer] = useState<Manufacturer>()

    const {t}=useTranslation();


    console.log("data:")

    // Загрузка данных поставщика для редактирования, если есть ID
    useEffect(() => {
        if (id&&!embeddedCreate) {
            setLoading(true);
            manufacturerService.getById(id)
                .then((data) => {
                    console.log("data:", data)
                    setManufacturer(data);
                    man_form.setFieldsValue(data);
                })
                .catch((error) => {
                    notification.error({ message: 'Ошибка загрузки данных' });
                    if(error.response.status===401){
                        AuthService.logout()
                        history.push("/login");
                        window.location.reload()
                        console.log("logged out")
                    }
                })
                .finally(() => setLoading(false));
        }
    }, [id, man_form]);

    // Обработка отправки формы
    const handleSubmit = async (values: any) => {

        const cls = {
            id: values.id? values.id : "",
            name: values.name,
            code: values.code,
            description: values.description?  values.description : "",
        }
        console.log("values to PUT", values)
        setLoading(true);
        try {
            if (id&& !embeddedCreate) {
                // Обновление существующей 
                await manufacturerService.update(id, cls);
                notification.success({ message: 'The manufacturer has been successfully updated!' });
            } else {
                // Создание новой классификации
                console.log("values CLS", cls)

                const newOne=await manufacturerService.create(values);
                if(closeEmbeded){
                    closeEmbeded(newOne)
                }

                notification.success({ message: 'The manufacturer has been successfully created!' });


            }
            if(!closeEmbeded) {
                history.push('/manufacturers/'); // Навигация обратно на список поставщиков
            }
        }
            //catch (error:any) {
            //     console.log(error)
            //     notification.error({ message: 'Ошибка сохранения поставщика', description: error.message });
            //} 
        finally {
            setLoading(false);
        }
    };


    const deleteItems: MenuProps['items'] = [
        { key: "0",  label: <span>{t("Delete")}</span> }];


    const handleDeleteOption: MenuProps['onClick'] = async (e) => {
        if(e.key==="0"){
            try {
                const response = await manufacturerService.delete(id)
                notification.success({ message: 'Classification deleted successfully!' });
                history.push("/manufacturers/")
            }
            catch (e) {
                notification.error({ message: 'Error deleting classification' });
            }
        }
    }


    const handleCancel=()=> {
        if (closeEmbeded) {
            closeEmbeded("")
        } else {
            history.push("/uoms")
        }
    }


    return (
        <div>


            <div>
                {
                    !embeddedCreate ?
                <h1>
                        <span style={{color: "#999" , cursor: "pointer", borderBottom: "1px dashed #999"}}

                              onClick={()=> history.push("/manufacturers/")}>
        
                    {t("Manufacturers")}</span>
                    <span style={{color: "#999"}}>
                    &nbsp;<RightOutlined size={16} style={{fontSize:"18px"}} /> &nbsp;
                </span>
                    {id ? manufacturer?.name : t('Add_new_manufacturer')}



                </h1> : <></>}
            </div>


            <ConfigProvider
                theme={{
                    components: {
                        Form: {
                            itemMarginBottom: 10
                        },
                    },
                }}
            >
                <Form
                    form={man_form}
                    name={"man_form"}
                    layout="horizontal"
                    labelCol={{ flex: '180px' }}
                    labelWrap={true}

                    onFinish={handleSubmit}
                    initialValues={{ name: '', supplierCode: '', address: '', phoneNumber: '', email: '', contactPerson: '' }}
                >


                    <Form.Item
                        label={t("Code")}
                        name="code"
                        rules={[{ required: true, message: 'Введите код ' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item hidden={true} name="id">
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={t("Naziv")}
                        name="name"
                        rules={[{ required: true, message: 'Введите имя ' }]}
                    >
                        <Input />
                    </Form.Item>


                    <Form.Item
                        label={t("Description")}
                        name="description"
                    >
                        <Input />
                    </Form.Item>

                    <Divider orientation={"left"} orientationMargin="0" style={{marginBottom: "60px"}}> </Divider>


                    <Affix offsetBottom={0}>
                        <div className="affix-shadow-wrapper">
                            <Form.Item  style={{float: "right"}}>
                                <Space>

                                    {/* 

        {id ?

            <AddTodo
                buttonText={"Add/check to-do"}
                objectId={id}
                objectType={TodoObjectType.ProductGroup}
                showList={true}
                afterAdd={(v)=>{
                    console.log()
                    //setRefreshTodoList(!refreshTodoList)
                }} />
            :
            ""
        }
        */}

                                    <Button type="primary" htmlType="submit" loading={loading}  className={"OrangeButton"}>

                                        {id && !embeddedCreate ? t('Update_manufacturer') : t('Create_manufacturer')}
                                       
                                       
                                    </Button>

                                    <Button onClick={handleCancel}>{t("Cancel")}</Button>

                                    {/* 
        <Dropdown menu={{'items': deleteItems, onClick: handleDeleteOption }}  >
            <Button >{t("Delete")}</Button>
        </Dropdown>
        */}


                                </Space>
                            </Form.Item>

                        </div>
                    </Affix>


                </Form>
            </ConfigProvider>
        </div>
    );
};

export default ManufacturerForm;
