import axios from 'axios';

import {DATA_URL} from './auth-service'
import {Product} from "../types/Product";
import {InventoryDocument} from "../types/InventoryDocument";
import authHeader from "./auth-header";

const API_URL = DATA_URL+'InventoryDocuments';

export const inventoryDocumentService ={
    // Получение всех InventoryDocument
    async getAll(docType? : number, warehouseId? :string, startDate?: string, endDate?: string, productId? : string, supplierId? : string): Promise<InventoryDocument[]> {

        console.log(authHeader())
        const response = await axios.get<InventoryDocument[]>(API_URL,
            {params:{
                    docType,
                    warehouseId,
                    startDate,
                    endDate,
                    productId,
                    supplierId
                },headers: authHeader()});
        return response.data;

    },

    // Получение InventoryDocument по ID
    async getById(id:string) : Promise<InventoryDocument> {
        const response = await axios.get<InventoryDocument>(`${API_URL}/${id}`, {headers: authHeader()});
        return response.data; 
    },

    // Создание нового InventoryDocument
    async create(document: InventoryDocument): Promise<InventoryDocument> {
        const response = await axios.post<InventoryDocument>(API_URL, document, {headers: authHeader()});
        return response.data;
    },

    // Обновление существующего InventoryDocument
    async update(id: string, InventoryDocument: InventoryDocument): Promise<void> {
        await axios.put(`${API_URL}/${id}`, InventoryDocument, {headers: authHeader()});
    },

    // Удаление InventoryDocument
    async delete(id?: string): Promise<void> {
        await axios.delete(`${API_URL}/${id}`, {headers: authHeader()});
    },
}


