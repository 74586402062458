import React, {useState, useEffect} from "react"
import {productService} from "../Services/productService";
import {Product} from "../types/Product";
import {Select, Spin} from "antd";
import {InventoryMovementService} from "../Services/inventoryMovementService";
import {useTranslation} from "react-i18next";

interface ProductSelectProps {
    defaultSelected? : string
    afterSelect? : (v:string)=>void;
}

const ProductSelect=({defaultSelected, afterSelect}: ProductSelectProps)=>{
    
    
    const [products, setProducts ]=useState<Product[]|undefined>()
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedValue, setSelectedValue] = useState(defaultSelected);
    const {t}=useTranslation();
    
    useEffect(() => {
        const fetchMovements = async () => {
            setLoading(true);
            try {
                const response = await productService.getAll();
                setProducts(response);
            } catch (error) {
                console.error('Ошибка при получении товаров:', error);
            } finally {
                setLoading(false);
            }
        };
        setSelectedValue(defaultSelected)
        fetchMovements();
    }, [defaultSelected]);
    
    const handleSelect=(value: string)=>{
        setSelectedValue(value)
        if(afterSelect){
            afterSelect(value)
        }
    }

    return <Spin spinning={loading}>
        <Select
            allowClear={true}
            showSearch={true}
            style={{width: "100%"}} placeholder={t("Select_a_product")}
            value={selectedValue}
            options={products?.map((prod)=>({label: prod.name, value: prod.id, code: prod.code}))}


            filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())||
                (option?.code ?? '').toLowerCase().includes(input.toLowerCase())
            }



            optionRender={(option) => (<div>
                <span style={{fontSize: "10px", color: "#999"}}>{option.data.code}</span><br/>
                <span>{option.data.label}</span>
            </div>)}
            
            
            onChange={handleSelect}
        
        />
    </Spin>
    
    
    
}
export default ProductSelect