// src/services/productService.ts
import axios from 'axios';
import { Supplier } from '../types/Supplier';
import {DATA_URL} from './auth-service'
import authHeader from "./auth-header";

const API_URL = DATA_URL+'suppliers';

export const supplierService = {
    // Получение всех поставщиков
    async getAll(): Promise<Supplier[]> {
        const response = await axios.get<Supplier[]>(API_URL, {headers: authHeader()});
        return response.data;
    },
    
    // Получение поставщика по ID
    async getById(id: string): Promise<Supplier> {
        const response = await axios.get<Supplier>(`${API_URL}/${id}`, {headers: authHeader()});
        return response.data;
    },

    // Создание нового поставщика
    async create(supplier: Supplier): Promise<Supplier> {
        const response = await axios.post<Supplier>(API_URL, supplier, {headers: authHeader()});
        return response.data;
    },

    // Обновление существующего поставщика
    async update(id: string, supplier: Supplier): Promise<void> {
        await axios.put(`${API_URL}/${id}`, supplier, {headers: authHeader()});
    },

    // Удаление поставщика
    async delete(id: string): Promise<void> {
        await axios.delete(`${API_URL}/${id}`, {headers: authHeader()});
    },
    
};
