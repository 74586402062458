import React, { useEffect, useState } from 'react';
import {Form, Input, Button, notification, Affix, Space, Divider, ConfigProvider, Dropdown, MenuProps} from 'antd';
import { productGroupService } from '../../Services/productGroupService'; // сервис для работы с API
import { useParams, useHistory } from 'react-router-dom';
import {RightOutlined} from "@ant-design/icons";
import {ProductGroup} from "../../types/ProductGroup";
import {useTranslation} from "react-i18next";
import AddTodo from "../../CommonComponents/ToDo/AddTodo";
import {TodoObjectType} from "../../types/ToDo";
import {Manufacturer} from "../../types/Manufacturer";
import {manufacturerService} from "../../Services/manufacturerService";
import {inventoryDocumentService} from "../../Services/inventoryDocumentService";
import {UnitOfMeasure} from "../../types/UnitOfMeasure";
import {uomService} from "../../Services/uomService";
import * as AuthService from "../../Services/auth-service"; // Для получения параметров из URL и навигации


interface UnitOfMeasureFormProps {
    //id?: string;
    embeddedCreate? : boolean;
    closeEmbeded? : (v?:any)=>void;
}

const UnitOfMeasureForm: React.FC<UnitOfMeasureFormProps> = ({embeddedCreate, closeEmbeded}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const { id } = useParams<{ id?: string }>(); // Получение ID поставщика из URL, если он есть
    const history = useHistory(); // Для навигации

    const [unit, setUnit] = useState<UnitOfMeasure>()

    const {t}=useTranslation();


    console.log("data:")

    // Загрузка данных поставщика для редактирования, если есть ID
    useEffect(() => {
        if (id&&!embeddedCreate) {
            setLoading(true);
            uomService.getById(id)
                .then((data) => {
                    console.log("data:", data)
                    setUnit(data);
                    form.setFieldsValue(data);
                })
                .catch((error) => {
                    console.log("uom erroe:", error)
                    notification.error({ message: 'Ошибка загрузки данных' });
                    if(error.response.status===401){
                        AuthService.logout()
                        history.push("/login");
                        window.location.reload()
                        console.log("logged out")
                    }
                })
                .finally(() => setLoading(false));
        }
    }, [id, form]);

    // Обработка отправки формы
    const handleSubmit = async (values: any) => {

        const cls = {
            id: values.id? values.id : "",
            name: values.name,
            code: values.code,
            description: values.description?  values.description : "",
        }
        console.log("values to PUT", values)
        setLoading(true);
        try {
            if (id&& !embeddedCreate) {
                // Обновление существующей 
                await uomService.update(id, cls);
                notification.success({ message: 'The unit of measure has been successfully updated!' });
            } else {
                // Создание новой 
                console.log("values CLS", cls)

                const newOne= await uomService.create(values);
                if(closeEmbeded){
                    closeEmbeded(newOne)
                }
                
                notification.success({ message: 'The unit of measure has been successfully created!' });

            }
            if(!closeEmbeded){
                history.push('/uoms/'); // Навигация обратно на список поставщиков
            }
        }
            //catch (error:any) {
            //     console.log(error)
            //     notification.error({ message: 'Ошибка сохранения поставщика', description: error.message });
            //} 
        finally {
            setLoading(false);
        }
    };


    const deleteItems: MenuProps['items'] = [
        { key: "0",  label: <span>{t("Delete")}</span> }];


    const handleDeleteOption: MenuProps['onClick'] = async (e) => {
        if(e.key==="0"){
            try {
                const response = await uomService.delete(id)
                notification.success({ message: 'Unit of measure deleted successfully!' });
                history.push("/uoms/")
            }
            catch (e) {
                notification.error({ message: 'Error deleting unit of measure' });
            }
        }
    }
    
    
    const handleCancel=()=> {
        if (closeEmbeded) {
            closeEmbeded("")
        } else {
            history.push("/uoms")
        }
    }


    return (
        <div>


            <div>
                {
                    !embeddedCreate ?
                        <h1>
                        <span style={{color: "#999" , cursor: "pointer", borderBottom: "1px dashed #999"}}

                              onClick={()=> history.push("/uoms/")}>
        
                    {t("UnitOfMeasures")}</span>
                            <span style={{color: "#999"}}>
                    &nbsp;<RightOutlined size={16} style={{fontSize:"18px"}} /> &nbsp;
                </span>
                            {id? unit?.name :  t('Add_new_uom')}



                        </h1>
                        : <></>
                }
                
            </div>


            <ConfigProvider
                theme={{
                    components: {
                        Form: {
                            itemMarginBottom: 10
                        },
                    },
                }}
            >
                <Form
                    form={form}
                    layout="horizontal"
                    labelCol={{ flex: '180px' }}
                    labelWrap={true}

                    onFinish={handleSubmit}
                    initialValues={{ name: '', supplierCode: '', address: '', phoneNumber: '', email: '', contactPerson: '' }}
                >


                    <Form.Item
                        label={t("Code")}
                        name="code"
//                        rules={[{ required: true, message: 'Введите код ' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item hidden={true} name="id">
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={t("Naziv")}
                        name="name"
                        rules={[{ required: true, message: 'Введите имя ' }]}
                    >
                        <Input />
                    </Form.Item>


                    <Form.Item
                        label={t("Description")}
                        name="description"
                    >
                        <Input />
                    </Form.Item>

                    <Divider orientation={"left"} orientationMargin="0" style={{marginBottom: "60px"}}> </Divider>


                    <Affix offsetBottom={0}>
                        <div className="affix-shadow-wrapper">
                            <Form.Item  style={{float: "right"}}>
                                <Space>

                                    {/* 

        {id ?

            <AddTodo
                buttonText={"Add/check to-do"}
                objectId={id}
                objectType={TodoObjectType.ProductGroup}
                showList={true}
                afterAdd={(v)=>{
                    console.log()
                    //setRefreshTodoList(!refreshTodoList)
                }} />
            :
            ""
        }
        */}

                                    <Button type="primary" htmlType="submit" loading={loading}  className={"OrangeButton"}>
                                        {id && !embeddedCreate ? t('Update_uom') : t('Create_uom')}
                                    </Button>

                                    <Button onClick={handleCancel}>{t("Cancel")}</Button>

                                    {/* 
        <Dropdown menu={{'items': deleteItems, onClick: handleDeleteOption }}  >
            <Button >{t("Delete")}</Button>
        </Dropdown>
                                    */}
        


                                </Space>
                            </Form.Item>

                        </div>
                    </Affix>


                </Form>
            </ConfigProvider>
        </div>
    );
};

export default UnitOfMeasureForm;
