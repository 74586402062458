import React, {useState, useEffect} from "react"
import {productService} from "../../Services/productService";
import {Product} from "../../types/Product";
import {Button, DatePicker, Drawer, Form, Input, notification, Select, Space, Spin} from "antd";
import {useTranslation} from "react-i18next";
import {ToDo, TodoObjectType} from "../../types/ToDo";
import {todoService} from "../../Services/todoService";
import {productGroupService} from "../../Services/productGroupService";
import {CalendarOutlined, ThunderboltOutlined} from "@ant-design/icons";

import {Event} from "../../types/Event"
import {eventService} from "../../Services/eventService";


interface EventProps {
    afterAdd? : (v:any)=>void;
//    buttonText? : string,
//    showList? : boolean
}

const AddEvent=({afterAdd}: EventProps)=>{


    const [events, setEvents ]=useState<Event[]|undefined>()
    const [loading, setLoading] = useState<boolean>(true);

    const [openDrawer, setopenDrawer] = useState(false)


    const [formV] = Form.useForm();





    const {t}=useTranslation();

    useEffect(() => {
        const fetchEvents = async () => {
            setLoading(true);
            try {

                const response = await eventService.getAll();
                setEvents(response);
            } catch (error) {
                console.error('Ошибка при получении событий:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchEvents();
    }, []);




    const onFinish= async (values: any)=>{
        await eventService.create(values);
        notification.success({ message: 'The event has been successfully created!' });
        setopenDrawer(false);
        if(afterAdd){
            afterAdd(values);
        }

    }

    const { TextArea } = Input;
    return <div style={{display: "inline-block"}}>
            <div onClick={()=>{setopenDrawer(true)}}>
                <CalendarOutlined style={{fontSize: "24px", color: "#265a96"}}/><br/>
                {t("New_event")}
            </div>

        


        <Drawer title={t("New_event")} placement="right"
                destroyOnClose={true}
                onClose={()=>setopenDrawer(false)}
                open={openDrawer}>
            
                <div>
                    <Form
                        preserve={false}
                        form={formV}
                        name="formV"
                        onFinish={onFinish}
                        autoComplete="off"
                        style={{ maxWidth: "600" }}

                    >

                        <Form.Item
                            name="title"
                            label={""}
                            //rules={[{ required: true }, { type: 'string' }]}
                        >
                            <TextArea rows={4} />
                        </Form.Item>
                        <Form.Item
                            name="eventDate"
                            label={""}
                            //rules={[{ required: true }, { type: 'string' }]}
                        >
                            <DatePicker format={"DD.MM.YYYY"} />
                        </Form.Item>

                        <Form.Item>
                            <Space>
                                <Button className={"OrangeButton"} htmlType="submit">
                                    {t("Ustvarite_nov_dogodek")}
                                </Button>
                                <Button className={"emptyButton"} onClick={()=>{setopenDrawer(false)}} >
                                    {t("Ne_shranite_sprememb")}
                                </Button>

                            </Space>
                        </Form.Item>


                    </Form>
                </div>
        </Drawer>


    </div>





}
export default AddEvent