// pages/ProductEdit.tsx
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import ProductForm  from './Components/ProductForm';
import { Product } from '../../types/Product';
import { ProductGroup } from '../../types/ProductGroup';
import axios from 'axios';
import {message, Spin, Tabs, TabsProps} from 'antd';
import {productGroupService} from "../../Services/productGroupService";
import {productService} from "../../Services/productService";
import {RightOutlined, EditOutlined, SwapOutlined, TableOutlined} from '@ant-design/icons';
import ProductMovements from "../../CommonComponents/InventoryMovements/ProductMovements";
import ProductsStock from "./Components/ProductStock";
import {useTranslation} from "react-i18next";
import {Warehouse} from "../../types/Warehouse";
import {warehouseService} from "../../Services/WarehouseService";
import {Classification} from "../../types/Classification";
import {TypeOfMaterial} from "../../types/TypeOfMaterial";
import {classificationService} from "../../Services/classificationService";
import {typeOfMaterialService} from "../../Services/typeOfMaterialService";

const ProductDetail: React.FC = () => {
    const { id } = useParams<{ id: string }>(); // Получаем ID товара из URL, если он есть
    const history = useHistory();
    const [product, setProduct] = useState<Product | null>(null);
    const [productGroups, setProductGroups] = useState<ProductGroup[]>([]);
    const [warehouses, setWarehouses] = useState<Warehouse[]>([]);


    const [classifications, setClassifications] = useState<Classification[]>([]);
    const [materials, setMaterials] = useState<TypeOfMaterial[]>([]);


    const [loading, setLoading] = useState(false);

    const {t}=useTranslation();
    useEffect(() => {
        const fetchProductGroups = async () => {
            try {
                const data = await productGroupService.getAll();
                setProductGroups(data);
            } catch (error) {
                console.log("error111: ", error)

                message.error('Failed to load product groups');
            }
        };

        const fetchWarehouses = async () => {
            try {
                const data = await warehouseService.getAll();
                setWarehouses(data);
            } catch (error) {
                console.log("error111: ", error)
                message.error('Failed to load warehouse');
            }
        };


        const fetchClassifications = async () => {
            try {
                const data = await classificationService.getAll();
                setClassifications(data);
            } catch (error) {
                console.log("error111: ", error)
                message.error('Failed to load product groups');
            }
        };

        const fetchMaterials = async () => {
            try {
                const data = await typeOfMaterialService.getAll();
                setMaterials(data);
            } catch (error) {
                console.log("error111: ", error)
                message.error('Failed to load product groups');
            }
        };
        
        

        fetchProductGroups();
        fetchWarehouses();
        fetchClassifications();
        fetchMaterials();
    }, []);

    useEffect(() => {
        if (id) {
            setLoading(true);

            const data =  productService.getById(id)
                .then((response) => {
                    console.log("fetched product data: ", product)
                    setProduct(response);
                })
                .catch(() => {
                    message.error('Failed to load product');
                })
                .finally(() => {
                    setLoading(false);
                });

            {/* 
            axios
                .get<Product>(`/api/products/${id}`)
                .then((response) => {
                    setProduct(response.data);
                })
                .catch(() => {
                    message.error('Failed to load product');
                })
                .finally(() => {
                    setLoading(false);
                });
                */}
        }
    }, [id]);

    const handleSave = async (product: Product) => {
        try {
            if (id) {
                console.log("product to update: ", product)
                await productService.update(id, product)
                message.success('Product updated successfully');
            } else {
                await productService.create(product)
                //await axios.post('/api/product', product);
                message.success('Product created successfully');
            }
            history.push('/products');
        } catch (error) {
            message.error('Failed to save product');
            console.log("error:", error);

        }
    };



    const onChange = (key: string) => {
        //console.log(key);
    };


    const operations = <>Extra Action</>;
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: t('Edit_product_data'),
            icon: <EditOutlined />,
            children: <ProductForm
                initialValues={product || undefined}
                productGroups={productGroups}
                warehouses={warehouses}
                classifications={classifications}
                typeOfMaterials={materials}
                onSave={handleSave}
            />,
        },
        {
            key: '2',
            icon: <TableOutlined />,
            label: t('Stock_availability'),
            children: <ProductsStock productId={product? product.id : ""} />,
        },
        {
            key: '3',
            label: t("Products_stock_movements"),
            icon: <SwapOutlined />,
            children: <ProductMovements productId={product? product.id : ""} />,
        },
    ];
    
    

    if (loading) {
        return <Spin />;
    }

    return (
        <div>
            <h1>
                <span style={{color: "#999", cursor: "pointer", borderBottom: "1px dashed #999"}} onClick={()=> history.push("/products")}>
                    {t("Izdelki")}
                </span>
                <span style={{color: "#999"}}>
                    &nbsp;<RightOutlined size={16} style={{fontSize:"18px"}} /> &nbsp;
                </span>
                {product ? product.name : t('Create_product')}
            </h1>
            {
                id? 
                    <>
                    <Tabs /*tabBarExtraContent={operations}*/ defaultActiveKey="1" items={items} onChange={onChange} />
                </>
                    
                    : <ProductForm
                    initialValues={product || undefined}
                    productGroups={productGroups}
                    warehouses={warehouses}
                    classifications={classifications}
                    typeOfMaterials={materials}
                    onSave={handleSave}
                />
            }
          
            
            
        </div>
    );
};

export default ProductDetail;
