
import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import * as AuthService from "../../Services/auth-service";
import Clock from "./Components/ClockComponent";
import {Button, Card, Checkbox, Col, List, Row, Tooltip} from "antd";
import {
    SwapOutlined,
    TagsOutlined,
    FileTextOutlined,
    CodeSandboxOutlined,
    PlusCircleOutlined,
    MinusCircleOutlined,
    FileOutlined,
    UploadOutlined,
    DownloadOutlined,
    ArrowRightOutlined,
    ThunderboltOutlined,
    CalendarOutlined,
    TableOutlined,
    TruckOutlined
} from '@ant-design/icons';
import {useTranslation} from "react-i18next";
import AddTodo from "../../CommonComponents/ToDo/AddTodo";
import TodosList from "../../CommonComponents/ToDo/TodosList";
import AddEvent from "../../CommonComponents/Events/AddEvent";
import EventsList from "../../CommonComponents/Events/EventsList";




const Dash = () => {
    const history = useHistory();
    const user = AuthService.getCurrentUser();
    
    if(!user){
        console.log(user)
        history.push("login")
    }

    const tt: any = localStorage.getItem("userlanguage")===null ? "" : localStorage.getItem("userlanguage");
    const {t} = useTranslation('translation', { lng: tt });



    if(!user){
        history.push("/login");
    }
    const [isModalOpen, setIsModalOpen] = useState(false);


    const news = [
        {
            date: "27.08.2024",
            title: 'Peter Nowak sent a request with a list of articles for his stockroom ',
        },
        {
            date: "29.08.2024",
            title: 'Return of articles from Master Chef project is planned',
        },
        {
            date: "01.09.2024",
            title: 'Return of articles from Zvezde plešejo project is planned',
        },
    ];



    const data = [
        {
            title: <span style={{cursor: "pointer"}} onClick={()=>history.push("/stockmovement/newdoc")}>
                {t("Create_new_arrival")}
            </span> ,
            icon: <PlusCircleOutlined style={{fontSize: "24px", color: "green"}} />,
            description: t("You_can_also_create_a_new_invoice_from_the_Documents_section")
        },
        {
            title: <span style={{cursor: "pointer"}} onClick={()=> {
                history.push("/stockmovement/newdoc/1");
                //window.location.reload();
            }}>
                {t("Add_new_movement")}
            </span> ,
            icon: <SwapOutlined style={{fontSize: "24px", color: "#7918e6"}} onClick={()=>setIsModalOpen(true)} />,
            description: t("Upload_invoice_scan"),
            href: '',
        },
        /*
        {
            title: <span style={{cursor: "pointer"}} onClick={()=>history.push("/stockmovement/newdoc")}>
                Create withdrawal/sale of of articles  
            </span> ,
            icon: <MinusCircleOutlined style={{fontSize: "24px", color: "#265a96"}} />,
            description: t("Upload_pdf_or_csv"),
            href: '',
        },
        
         */
    ];


    const otheractions = [
        {
            key: 0,
            title: <span style={{cursor: "pointer"}} onClick={()=>history.push("/products/new")}>
                {t("Create_product")}  
            </span> ,
            icon: <CodeSandboxOutlined style={{fontSize: "24px", color: "green"}} />,
            href: '/products/new'
        },
        {
            key: 1,
            title: <span style={{cursor: "pointer"}} onClick={()=>setIsModalOpen(true)}>
                 {t("Create_group")}  
            </span> ,
            icon: <TagsOutlined style={{fontSize: "24px", color: "#7918e6"}} onClick={()=>setIsModalOpen(true)} />,
            href: '/groups/new',
        },
        {
            key: 2,
            title: <span style={{cursor: "pointer"}} onClick={()=>history.push("/docs/createdoc")}>
                {t("New_event")} 
            </span> ,
            icon: <CalendarOutlined style={{fontSize: "24px", color: "#265a96"}} />,
            href: '',
        },
        {
            key: 3,
            title: <span style={{cursor: "pointer"}} onClick={()=>history.push("/docs/createdoc")}>
                 {t("Create_warehouse")}  
            </span> ,
            icon: <TableOutlined style={{fontSize: "24px", color: "green"}} />,
            href: 'stocks/create'
        },
        {
            key: 4,
            title: <span style={{cursor: "pointer"}} onClick={()=>setIsModalOpen(true)}>
                {t("Create_supplier")}  
            </span> ,
            icon: <TruckOutlined style={{fontSize: "24px", color: "#7918e6"}} onClick={()=>setIsModalOpen(true)} />,
            href: '/settings/suppliers/new',
        },
        {
            key: 5,
            title: <span style={{cursor: "pointer"}}>
                {t("New_todo")}  
            </span> ,
            icon: <AddTodo buttonText={"icon"} />, //<ThunderboltOutlined style={{fontSize: "24px", color: "#265a96"}} />,
            href: '',
        },
    ];



    const gridStyle: React.CSSProperties = {
        width: '33%',
        textAlign: 'center',
        cursor: "pointer"
    };



    const [colswidth, setColswidth]=useState<number[]>([6,12, 4])
    
    const [refreshTodoList, setRefreshTodoList]=useState(false);
    const [refreshEventsList, setRefreshEventsList]=useState(false);

    return<>
        <Row gutter={16}>
            <Col span={colswidth[0]}>
                <Row>
                    <Col span={24}>
                        <Clock />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Card
                            bordered={false}
                            style={{boxShadow: "none"}}
                            title={t("To_do")+":"}>
                            <div style={{ maxHeight: "50vh", overflowY: 'scroll'}}>
                                <TodosList refresh={refreshTodoList}/>
                            </div>
                          

                        </Card>
                        <div style={{float: "right"}}>
                            <AddTodo 
                                buttonText={"Add new to-do"}
                                afterAdd={(v)=>{
                                console.log()
                                setRefreshTodoList(!refreshTodoList)
                            }} />
                        </div>
    
                    </Col>
                </Row>
                
                
            </Col>
            <Col offset={1}  span={colswidth[1]}>
                <Row>
                    <Col span={24}>
                        <span style={{fontSize: "32px", color: "#1449af"}}>{t("Welcomed_by")} 
                            <div style={{display: "inline-block", marginTop: "-20px", 
                                width: "20px",
                                
                                position: "relative"}}>                                                       
                                <ArrowRightOutlined
                                style={{
                                    float: "right",
                                    top: "-30px",
                                    position: "absolute",
                                    left: "5px",
                                    fontSize: "16px",


                                }}

                            />
                                
                            </div>KvikStock</span>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <h3 style={{marginTop: "40px"}}>{t("What_do_you_want_to_do")}</h3>
       

                            <List
                                itemLayout="horizontal"
                                size={"small"}
                                bordered={false}
                                dataSource={data}
                                renderItem={(item, index) => (
                                    <List.Item>
                                        <List.Item.Meta
                                            avatar={item.icon}
                                            title={item.title}
                                            description={item.description}

                                        />
                                    </List.Item>
                                )}
                            />




                        <h3 style={{marginTop: "80px"}}>{t("Other_actions")}:</h3>

                        <Card bordered={false}>
                            {otheractions.map((act,i)=>(
                               
                                    <Card.Grid onClick={()=> {
                                        if(act.key!==5){
                                            history.push(act?.href ? act?.href.toString() : "/")
                                        }
                                       
                                    }}
                                        style={gridStyle}>

                                        <>
                                        {act.key===5 ?
                                            <AddTodo buttonText={"icon"} />
                                            :
                                            
                                            act.key==2?
                                                <AddEvent afterAdd={()=>{setRefreshEventsList(!refreshEventsList)}} />
                                                :
                                            
                                            <div>
                                                {act.icon}<br/>
                                                {act.title}
                                            </div>
                                        }
                                        </>
                                       
                                    </Card.Grid>
                               

                            
                            ))}
                        </Card>
                        




                    </Col>
                </Row>
            </Col>
            <Col span={colswidth[2]} offset={1}>
                <h3>{t("Latest_events")}:</h3>

                <div style={{ maxHeight: "70vh", overflowY: 'scroll'}}>
                <EventsList refresh={refreshEventsList} />
                </div>
                
            </Col>


        </Row>
     
    </>
}

export default Dash