// src/pages/ProductGroupListPage.tsx
import React, { useState, useEffect } from 'react';
import {useHistory} from "react-router-dom";
import ProductMovements from "../../CommonComponents/InventoryMovements/ProductMovements";
import {Button, Col, Dropdown, MenuProps, Row, Switch, Tabs, TabsProps} from "antd";
import {FileOutlined, SwapOutlined, TableOutlined} from "@ant-design/icons";
import DocumentMovements from "./DocumentMovements";
import {useTranslation} from "react-i18next";

const MovementListPage: React.FC = () => {
    const history=useHistory()

    const onChange=(e: any)=>{
        console.log(e)
        localStorage.setItem("movementListTab", e.toString());
    }
    const currTab = localStorage.getItem("movementListTab");


    const {t}=useTranslation();

    const items: TabsProps['items'] = [
        {
            key: '1',
            icon: <SwapOutlined/>,
            label: t('Movements_view'),
            children: <ProductMovements productId={undefined}/>,
        },
        {
            key: '2',
            label: t('Documents_view'),
            icon: <FileOutlined/>,
            children: <DocumentMovements productId={undefined}/>,
        },

    ];





    const saveItems: MenuProps['items'] = [
        { key: "0",  label: <span>{t("New_purchase")}</span> },
        { key: "1",  label: <span>{t("New_transfer")}</span> },
        { key: "2",  label: <span>{t("New_sale")}</span> },
        { key: "3",  label: <span>{t("New_writeoff")}</span> },
    ]
    const handleSaveOption: MenuProps['onClick'] = (e) => {
        if(e.key==="0"){
            history.push("/stockmovement/newdoc")
        }
        if(e.key==="1"){
            history.push("/stockmovement/newdoc/1")
        }
        if(e.key==="2"){
            history.push("/stockmovement/newdoc/2")
        }
        if(e.key==="3"){
            history.push("/stockmovement/newdoc/3")
        }
    }
    
    
    return (
        <div>
            <Row>
                
                <Col span={20}>
           
                       <h1>
                           {t("Stock_movements")}
                       </h1>
                </Col>

            <Col span={4}>
                <h1>

                    <Dropdown menu={{'items': saveItems, onClick: handleSaveOption }} >
                        <Button className={"OrangeButton"}>
                            {t("Add_new_document")}</Button>
                    </Dropdown>
                    </h1>
            </Col>

        </Row>


    <Tabs
                onChange={onChange}
                /*tabBarExtraContent={operations}*/ defaultActiveKey={currTab? currTab : undefined} items={items}  />
            {/* 
            <ProductMovements  productId={''} />
            */}
        </div>
    );
};

export default MovementListPage;
