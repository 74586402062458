import {InventoryMovementType} from "./types/InventoryMovement";
import { SwapRightOutlined, SwapLeftOutlined } from '@ant-design/icons';

export const movementTypeMap=( key: number ):string => {

    switch (key) {
        case 0:
            return "Purchase"; // Поступление на склад
        case 1:
            return "Transfer"; // Перемещение на проект
        case 2 :
            return "Sale"; // Продажа
        case 3 :
            return "Write_off"; // Списание
        default:
            return ""
    }
}



export const ToMoney= (num : number) =>{
    return num.toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")
}