import React, { useEffect, useState } from 'react';
import {Table, Typography, Spin, Row, Col} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useParams } from 'react-router-dom';
import { InventoryMovementService } from '../../../Services/inventoryMovementService';
import { ProductStock, WarehouseStock, ProjectStock } from '../../../types/InventoryMovement';
import {useTranslation} from "react-i18next";

const { Title } = Typography;


interface ProductStockProps {
    productId: string;
}

const ProductsStock: React.FC<ProductStockProps> = ({productId}) => {
   // const { productId } = useParams<{ productId: string }>();
    const [productStock, setProductStock] = useState<ProductStock | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    
    const{t}=useTranslation();

    useEffect(() => {
        if (productId) {
            InventoryMovementService.getProductStock(productId)
                .then((data) => {
                    setProductStock(data);
                })
                .finally(() => setLoading(false));
        }
    }, [productId]);

    const warehouseColumns: ColumnsType<WarehouseStock> = [
        {
            title: t('Warehouse_Name'),
            dataIndex: ['warehouse', 'name'] ,
            key: 'warehouseName',
        },
        {
            title: t("Količina"),
            dataIndex: 'quantity',
            key: 'quantity',
            render: (quantity: number) => `${quantity} units`,
        },
    ];

    const projectColumns: ColumnsType<ProjectStock> = [
        {
            title: t('Project_Name'),
            dataIndex: ['warehouse', 'name'],
            key: 'projectName',
        },
        {
            title: t('Količina'),
            dataIndex: 'quantity',
            key: 'quantity',
            render: (quantity: number) => `${quantity} units`,
        },
    ];

    if (loading) {
        return <Spin tip="Loading..." />;
    }

    return (
        <div>
            <Row gutter={24}>
                <Col span={12}>
                    <Title level={3} style={{fontWeight:"normal"}}>{t("Warehouses")}</Title>
                    <Table
                        columns={warehouseColumns}
                        dataSource={productStock?.warehouseStock || []}
                        rowKey="warehouseId"
                        pagination={false}
                    />
                </Col>
                <Col span={12}>
                    <Title level={3} style={{ fontWeight:"normal" }}>{t("Projects")}</Title>
                    <Table
                        columns={projectColumns}
                        dataSource={productStock?.projectStock || []}
                        rowKey="projectId"
                        pagination={false}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default ProductsStock;
