import React, {useEffect, useState} from 'react';
import {Button, Col, DatePicker, Input, Row, Spin, Table} from 'antd';
import {ColumnsType} from 'antd/es/table';
import dayjs from 'dayjs';
import {movementTypeMap} from "../../helpers"

import {InventoryMovementService} from '../../Services/inventoryMovementService';
import {InventoryMovementType, StockMovementDto} from "../../types/InventoryMovement";

import {DeleteOutlined, DollarOutlined} from '@ant-design/icons';
import ProductSelect from "../ProductSelect";
import WarehouseSelect from "../WarehouseSelect";
import {useHistory} from "react-router-dom";
import {logout} from "../../Services/auth-service";
import {useTranslation} from "react-i18next";


const getMovementIcon = (movementType: InventoryMovementType) => {
    switch (movementType) {
        case InventoryMovementType.Purchase:
            return  <span className={"betweenRight"}></span>;
        case InventoryMovementType.Transfer:
            return <span className={"betweenRight"}></span>;
        case InventoryMovementType.WriteOff:
            return <span className={"betweenRight"}></span>;
        case InventoryMovementType.Sale:
            return <span className={"betweenRight"}></span>;
        default:
            return null;
    }
}





const { RangePicker } = DatePicker;

interface InventoryMovement {
    id: string;
    movementDate: string;
    quantity: number;
    movementType: string;
    project: string;
    warehouse: string;
}

interface StockMovementData {
    initialQuantity: number;
    stockMovements: StockMovementDto[];
    finalQuantity: number;
}

interface ProductMovementsProps {
    productId?: string;
    warehouseId? : string;
    projectId? : string;
}

const ProductMovements: React.FC<ProductMovementsProps> = ({ productId, warehouseId, projectId }) => {
    const [movements, setMovements] = useState<StockMovementData | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [dates, setDates] = useState<[string, string] | undefined>(undefined);
    
    const [selectedProductId, setSelectedProductId] = useState(productId)
    const [selectedWarehouseId, setSelectedWarehouseId] = useState(warehouseId)
    const [selectedProjectId, setSelectedProjectId] = useState(projectId)
    
    const {t}=useTranslation();
    
    const history=useHistory();

    const columns: ColumnsType<StockMovementDto> = [
        {
            title: t('Movement_date'),
            dataIndex: 'movementDate',
            key: 'movementDate',
            width: 150,
            render: (text: string, record) => {
                return (<>
                    {dayjs(text).format('DD.MM.YYYY')}<br/>
                    {
                        <span style={{fontSize: "12px", color: "#999"}}>
                        {record.document ?
                            "# "+"PZ-"+record.document.documentNumber
                            : "-"}
                        </span>
                    }
                
                
                </>)
            },
        },
        productId===undefined? {
            title: t('product'),
            dataIndex: ['product', 'name'],
            key: 'product',
            ellipsis: true,
            //width: 200,
            render: (text, record)=>{
                return (
                    <div>
                        <span style={{fontSize: "12px", color: "#999"}}>{record.product?.code}</span><br/>
                        {record.product?.name}
                        {/* 
                        <br/><span style={{fontSize: "12px", color: "#999"}}>{record.product?.classification}</span>
                        */}
                    </div>
                )}


            }: {},
        {
            title: t('Type'),
            dataIndex: 'movementType',
            key: 'movementType',
            width: 100,
            render: (type) => t(movementTypeMap(type)) || 'Unknown',
        },
        {
            title: t('Količina'),
            dataIndex: 'quantity',
            key: 'quantity',
            width: 110,
            align: "right",
        },

        {
            title: ' ',
            dataIndex: 'sourceWarehouse',
            key: '1',
            align: 'right',
            width: 250,
            render: (text, record)=>{
                return (
                    <div>
                        {record.movementType===InventoryMovementType.Purchase ? 
                        <div style={{float: "right", color: "#999"}}>
                            {record.supplier ?
                                <>
                                    <span style={{fontSize: "12px", color: "#999" , textAlign: "right"}}>{t("Supplier")}:</span><br/>
                                    <span style={{float: "right", color: "#000", textAlign: "right"}}>
                            {record.supplier?.name}
                        </span>
                                </>
                               
                               
                                : t("Supplier")}
                        </div>
                        
                        :
                            <>
                            <Row>
                                <Col span={24}>
                                    <div style={{float: "right", fontWeight: "600", textAlign: "right"}}>
                                        {record.sourceWarehouse?.name}</div>
                                </Col>
                            </Row>
                            <Row style={{}}>
                            <Col span={12} style={{textAlign: "right", fontSize: "12px"}}>
                                {t("Initial_level")}: {record.initialQuantityOnSourceWarehouse}
                            </Col>
                            <Col span={12}  style={{textAlign: "right", fontSize: "12px"}}>
                                {t("Final_level")}: {record.finalQuantityOnSourceWarehouse}
                            </Col>
                            </Row>
                            </>

                        }
                        
                        
                    </div>)
            }
        },
        {
            title: '',
            dataIndex: 'movementType',
            key: '5',
            width: 50,
            render: (type) => getMovementIcon(type) || 'Unknown',
            
        },
        {
            title: ' ',
            dataIndex: 'movementDate',
            key: '6',
            width: 250,

            render: (text, record)=>{
                
                return (
                    
                        record.destinationWarehouse?
                            <div>
                                <Row>
                                    <Col span={24}>
                                        <div style={{fontWeight: "600"}}>{record.destinationWarehouse? record.destinationWarehouse?.name : ""}</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12} style={{textAlign: "left", fontSize: "12px"}}>
                                        {t("Initial_level")}: {record.initialQuantityOnDestinationWarehouse? record.initialQuantityOnDestinationWarehouse : 0}
                                    </Col>
                                    <Col span={12}  style={{textAlign: "left", fontSize: "12px"}}>
                                        {t("Final_level")}: {record.finalQuantityOnDestinationWarehouse}
                                    </Col>
                                </Row>

                            </div>
                                : record.movementType===InventoryMovementType.WriteOff? 
                            <><DeleteOutlined /></> : <DollarOutlined />
                    
)
            }
        },
        {
            title: '',
            dataIndex: 'description',
            key: 'description',
            ellipsis:true
        },


    ];

    useEffect(() => {
        const fetchMovements = async () => {
            setLoading(true);
            try {
                const response = await InventoryMovementService.getForProduct(
                    selectedProductId? selectedProductId : '',
                    dates ? dates[0] : undefined,
                    dates ? dates[1] : undefined,
                selectedWarehouseId? selectedWarehouseId : '',
                    selectedProjectId? selectedProjectId : '',
                );
                console.log("response: ", response)
                setMovements(response);
            } catch (error:any) {
                console.error('Ошибка при получении перемещений:', error.response.status);
                if(error.response.status===401){
                    logout();
                    history.push("/login")
                }
            } finally {
                setLoading(false);
            }
        };

        fetchMovements();
    }, [selectedProductId, dates, selectedWarehouseId, selectedProjectId]);


    
    const handleProductSelect = (v:string)=>{
        setSelectedProductId(v);
    }
    
    const handleWarehouseSelect=(v: string)=>{
        setSelectedWarehouseId(v)
    }

    const handleProjectSelect=(v: string)=>{
        setSelectedProjectId(v)
    }

    const { Search } = Input;
    return (
        <>
            <Row gutter={24}>
                <Col span={4}>
                    <RangePicker
                        placeholder={[t("Od_datum"), t("Do_datum")]}
                        onChange={(dates, dateStrings) => {
                            if (dateStrings[0] && dateStrings[1]) {
                                setDates([dateStrings[0], dateStrings[1]]);
                            } else {
                                setDates(undefined);
                            }
                        }}
                    />
                </Col>
                <Col span={4}>
                    {productId ?
                        ""
                        :
                        <ProductSelect defaultSelected={selectedProductId} afterSelect={(v)=>handleProductSelect(v)} />
                        //<Select style={{width: "100%"}} placeholder={"Select a product"} options={[{label: "fsfsdf", value: "1"}]} />
                    }
                </Col>
                <Col span={4}>
                    <WarehouseSelect  defaultSelected={selectedWarehouseId} afterSelect={(v)=>{handleWarehouseSelect(v)}} />
                </Col>
                <Col span={4}>
                    <WarehouseSelect  defaultSelected={selectedProjectId} afterSelect={(v)=>handleProjectSelect(v)} />
                </Col>
                <Col span={4}>
                    <Search
                        style={{width: "100%"}}
                   // addonAfter={"search"} 
                    placeholder={t("Išči")}/>
                </Col>
                <Col span={4}>
                    {/* 
                    <Button className={"OrangeButton"} onClick={()=>{history.push("/stockmovement/newdoc")}}>Add new movement</Button>
                      */}
                </Col>

            </Row>

            <Spin spinning={loading}>
                {movements && (
                    <>
                        {/* 
                        <div style={{ margin: '20px 0' }}>
                            <strong>Начальное количество:</strong> {movements.initialQuantity}
                        </div>
                        */}
                        
                        <Table
                            columns={columns}
                            dataSource={movements.stockMovements}
                            rowKey="id"
                            pagination={false}
                            scroll={{ y: 'calc(53vh)' }}

                        />
                        {/*
                        <div style={{ margin: '20px 0' }}>
                            <strong>Конечное количество:</strong> {movements.finalQuantity}
                        </div>
                        */}
                    </>
                )}
            </Spin>
        </>
    );
};

export default ProductMovements;
