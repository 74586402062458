// src/services/productGroupService.ts
import axios from 'axios';

import {DATA_URL} from './auth-service'
import authHeader from "./auth-header";
import {User} from "../types/User";

const API_URL = DATA_URL+'settings';

export const settingsService = {
    // Получение пользователем данных о себе
    async getMe(): Promise<User> {
        const response = await axios.get<User>(API_URL, {headers: authHeader()});
        return response.data;
    },

    /*
    // Получение группы продуктов по ID
    async getById(id: string): Promise<ProductGroup> {
        const response = await axios.get<ProductGroup>(`${API_URL}/${id}`, {headers: authHeader()});
        return response.data;
    },

    // Создание новой группы продуктов
    async create(productGroup: ProductGroup): Promise<ProductGroup> {
        const response = await axios.post<ProductGroup>(API_URL, productGroup, {headers: authHeader()});
        return response.data;
    },

    // Обновление существующей группы продуктов
    async update(id: string, productGroup: ProductGroup): Promise<void> {
        await axios.put(`${API_URL}/${id}`, productGroup, {headers: authHeader()});
    },

    // Удаление группы продуктов
    async delete(id: string): Promise<void> {
        await axios.delete(`${API_URL}/${id}`, {headers: authHeader()});
    }
    
     */
};
