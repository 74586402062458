import {Badge, Button, Col, DatePicker, Input, Popover, Row, Select, Space, Spin, Table, Tabs, TabsProps} from "antd";
import {DeleteOutlined, DollarOutlined, EditOutlined, SwapOutlined, TableOutlined} from "@ant-design/icons";
import ProductsStock from "../Products/Components/ProductStock";
import ProductMovements from "../../CommonComponents/InventoryMovements/ProductMovements";
import ProductForm from "../Products/Components/ProductForm";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {InventoryDocument, InventoryDocumentType} from "../../types/InventoryDocument";
import {InventoryMovementService} from "../../Services/inventoryMovementService";
import {inventoryDocumentService} from "../../Services/inventoryDocumentService";
import {ColumnsType} from "antd/es/table";
import {InventoryMovementType, StockMovementDto} from "../../types/InventoryMovement";
import dayjs from "dayjs";
import ProductSelect from "../../CommonComponents/ProductSelect";
import WarehouseSelect from "../../CommonComponents/WarehouseSelect";
import {movementTypeMap, ToMoney} from "../../helpers";
import SupplierSelect from "../../CommonComponents/SupplierSelect";
import {useTranslation} from "react-i18next";



const { RangePicker } = DatePicker;

interface DocumnetMovementsProps {
    productId?: string;
    warehouseId? : string;
    projectId? : string;
    supplierId? : string;
    showInDrawer? : boolean;
}
const DocumentMovements: React.FC<DocumnetMovementsProps> = ({ productId, warehouseId, supplierId,showInDrawer }) => {

    const [documents, setDocuments] = useState<InventoryDocument[] | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [dates, setDates] = useState<[string, string] | undefined>(undefined);

    const [selectedProductId, setSelectedProductId] = useState(productId)
    const [selectedWarehouseId, setSelectedWarehouseId] = useState(warehouseId)
    
    const [selectedDocType, setSelectedDocType]=useState<number | undefined>(undefined)
    const [selectedSupplierId, setSelectedSupplierId]=useState(supplierId)

    const history=useHistory();
    
    const {t}=useTranslation();





    useEffect(() => {

        if(!supplierId && !productId){
            const fsup=localStorage.getItem("filter_supplierId");
            const fprod=localStorage.getItem("filter_productId");
            const fware=localStorage.getItem("filter_warehouseId");
            const fdtype=localStorage.getItem("filter_doctype");
            
            console.log("fdtype: ", fdtype)
            
            setSelectedSupplierId(fsup ? fsup : undefined)
            setSelectedProductId(fprod? fprod :undefined)
            setSelectedWarehouseId(fware ? fware : undefined);
            //setSelectedDocType(fdtype ? parseInt(fdtype) : undefined)


            //filter_productId   filter_warehouseId filter_doctype
        }


        const fetchMovements = async () => {
            setLoading(true);
            try {
                const response = await inventoryDocumentService.getAll(selectedDocType, selectedWarehouseId,
                dates ? dates[0] : undefined,
                    dates ? dates[1] : undefined,
                    selectedProductId,
                    selectedSupplierId
                );
                console.log(" DocumentMovements response: ", response)
                setDocuments(response);
            } catch (error) {
                console.error('Ошибка при получении перемещений:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchMovements();
    }, [selectedProductId, dates, selectedWarehouseId, selectedDocType, selectedSupplierId]);


    const columns: ColumnsType<InventoryDocument> = [
        {
            title: t('movementDate'),
            dataIndex: 'documentNumber',
            key: 'documentNumber',
            width: 120,
            render: (text: string, record) => {
                return (<>
                    {record.documentNumber ?
                        <>
                            {record.documentType === 0 ?
                                <span style={{color: "#1449af"}}>PZ-{record.documentNumber}</span>
                                :
                                <span style={{color: "#e25e1e"}}>MP-{record.documentNumber}</span>
                            }
                            
                        </>
                        : "-"}
                    <br/>
                    {
                        <span style={{fontSize: "12px", color: "#999"}}>
                        {dayjs(record.documentDate).format('DD.MM.YYYY')}
                        </span>
                    }


                </>)
            },
        },
        {
            title: t('Type'),
            dataIndex: 'documentType',
            width: 200,
            ellipsis: true,
            key: 'documentType',
            render: (text: string, record) => {
                return (<>
                    {record.documentType ===0 ? <span>{t(movementTypeMap(record.documentType))}</span>
                        
                        : <span>{t(movementTypeMap(record.documentType))}</span>}
                    <br/>
                    <span style={{color: "#999"}}>
                        <Popover placement={"topLeft"} content={<div style={{maxWidth: "180px"}}>{record.description}</div>}>
                            
                        {record.description} &nbsp;
                                
                            </Popover>
                    </span>
                </>)
            },
        },
        {
            title: 'From',
            ellipsis: true,
            align: 'right',
            width: 250,
            dataIndex: 'supplierId',
            key: 'supplierId',
            render: (text: string, record) => {
                return (<>
                    {record.supplierId?
                        <>
                        <span style={{fontSize: "12px", color: "#999" , textAlign: "right"}}>{t("Supplier")}:</span><br/>
                        <span style={{float: "right", color: "#000", textAlign: "right"}}>
                            {record.supplier?.name}
                        </span>
                        </>

                        : record.sourceWarehouseId? 
                            <span style={{textAlign: "right"}}><b>{record.sourceWarehouse?.name}</b></span> : ""}
                </>)
            },
        },
        {
            title: ' ',
            width:30,
            dataIndex: 'sourceWarehouseId',
            key: 'sourceWarehouseId',
            render: (text: string, record) => {
                return (<><span className={"betweenRight"}></span></>)
            }
        },
        {
            title: 'To',
            ellipsis: true,
            width: 250,
            dataIndex: 'destinationWarehouseId',
            key: 'destinationWarehouseId',
            render: (text: string, record) => {
                return (<b>
                    { record.destinationWarehouseId? <span>{record.destinationWarehouse?.name}</span> 


                        : record.documentType===InventoryDocumentType.Odpis?
                        <><DeleteOutlined /></> : <DollarOutlined />
                    
                    }
                </b>)
            },
        },
        
        ///invoiceAmount
        {
            title: ' ',
            //align: 'right',
            dataIndex: 'invoiceAmount',
            key: 'invoiceAmount',
            width: 250,
            render: (text, record)=>{
                return (<>
                    <Row>
                        <Col span={12} style={{fontSize: "12px", color: "#999", paddingRight: "5px", textAlign: "left"}}>
                            {t("Document1")}<br/>{t("amount1")}:
                        </Col>
                        <Col span={12} style={{ textAlign: "right"}}>
                            {ToMoney(record.invoiceAmount? record.invoiceAmount : 0)}
                        </Col>
                        </Row>
                    </>)}
                        
                    
                    
                    
        },
        {
            title: ' ',
            dataIndex: 'items',
            align: 'right',
            key: 'items',
            render: (text: string, record) => {
                return (<>
                    { record.items? <Space>
                         <div style={{fontSize: "12px", color: "#999", paddingRight: "10px", textAlign: "right"}}>
                             {t("Number1")} <br/>{t("of_positions1")}: 
                         </div>
                        {record.items?.length}</Space> : ""}
                </>)
            },
        },
        ]

    
    const handleSupplierSelect=(v: string)=>{
        setSelectedSupplierId(v)
        if(v){
            localStorage.setItem("filter_supplierId", v);
        }
        else{
            localStorage.removeItem("filter_supplierId")
        }
    }

    const handleProductSelect = (v:string)=>{
        setSelectedProductId(v);
        if(v){
            localStorage.setItem("filter_productId", v);
        }
        else{
            localStorage.removeItem("filter_productId")
        }
    }

    const handleWarehouseSelect=(v: string)=>{
        setSelectedWarehouseId(v)
        if(v){
            localStorage.setItem("filter_warehouseId", v);
        }
        else{
            localStorage.removeItem("filter_warehouseId")
        }
    }

    const handleSelectedDocType=(v:any)=>{
        console.log("v: ", v)
        
        setSelectedDocType(v)
        if(v){
            localStorage.setItem("filter_doctype", v.toString());
        }
        else{
            localStorage.removeItem("filter_doctype")
        }
    }


    const { Search } = Input;
    return<>
        <Row gutter={24}>
            <Col span={5}>
                <RangePicker 
                    format={"DD.MM.YYYY"}
                    onChange={(dates, dateStrings) => {
                        if (dates && dates[0] && dates[1]) {
                            setDates([dates[0].toISOString(), dates[1].toISOString()])
                            //setDates([dateStrings[0], dateStrings[1]]);
                        } else {
                            setDates(undefined);
                        }
                    }}
                />
            </Col>
            <Col span={3}>
                <Select
                        style={{width: "100%"}} 
                        placeholder={t("Type")} 
                        allowClear={true}
                        onChange={handleSelectedDocType}
                        options={[
                    {value:0, label:t("Purchase")},
                    {value:1, label:t("Transfer")},
                            {value:2, label:t("Sale")},
                            {value:3, label:t("Write_off")}
                ]} />
            </Col>
           
            <Col span={4}>
                
                
                {productId ?
                    ""
                    :
                    
                   // <div style={{width: "100%"}} >
                   //     <div style={{display: "inline-block", width: "98%"}}>
                            <ProductSelect defaultSelected={selectedProductId} afterSelect={(v)=>handleProductSelect(v)} />
                   //     </div>
                   //     <Badge dot color={"e25e1e"} offset={[-5,-5]} style={{display: selectedProductId? "inline-block": "none"}}> &nbsp; </Badge>
                   // </div>
                   
                   
                        }
                </Col>
            <Col span={4}>
                <WarehouseSelect  defaultSelected={selectedWarehouseId} afterSelect={(v)=>{handleWarehouseSelect(v)}} />
            </Col>
            {!supplierId ?
                <Col span={4}>
                    <SupplierSelect defaultSelected={selectedSupplierId} afterSelect={(v)=>handleSupplierSelect(v)} />
                </Col>
                :""
            }
            <Col span={!supplierId ? 4 : 8}>
                <Search
                    style={{width: "100%"}}
                    // addonAfter={"search"} 
                    placeholder={t("Išči")}/>
            </Col>
            <Col span={4}>
                {/* 
                <Button className={"OrangeButton"} onClick={()=>{history.push("/stockmovement/newdoc")}}>Add new document</Button>
            */}
            </Col>

        </Row>


        <Spin spinning={loading}>
            {documents && (
                <>
                    <Table
                        columns={columns}
                        dataSource={documents}
                        rowKey="id"
                        pagination={false}
                        scroll={{ y: 'calc(53vh)' }}
                        onRow={(record, rowIndex) => {
                            return {
                                
                                    
                                onClick: (event) => {
                                    showInDrawer?
                                        console.log("open drawer")
                                    :
                                        history.push("/stockmovement/"+record.id+"/"+record.documentType)
                                },
                                // click row
                            };
                        }}
                        /*
                        summary={(pageData) => {
                            let totalIncome = 0;
                            let totalExpense = 0;
                            pageData.forEach(({ invoiceAmount }) => {
                                totalIncome = totalIncome+(invoiceAmount? invoiceAmount : 0);
                            });
                            return (
                                <Table.Summary fixed>
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell index={0} >
                                            <div style={{width: "300px", }}>
                                                Incomes: {ToMoney(totalIncome)}
                                            </div>


                                        </Table.Summary.Cell>
                                       
                                    </Table.Summary.Row>
                                </Table.Summary>
                            )}}
                            
                         */
                    />
                </>
            )}
        </Spin>

    </>
}

export default DocumentMovements
